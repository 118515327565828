<template>
	<b-badge :class="color">{{
		$t(`enums.feedbackPriority.${priority}`)
	}}</b-badge>
</template>

<script>
import { FeedbackPriority } from "@/enums/feedback.enum";

export default {
	name: "feedbackPriorityBadge",
	props: {
		priority: {
			type: String,
			default: "NONE",
		},
	},

	computed: {
		color() {
			switch (this.priority) {
				case FeedbackPriority.LOWEST:
				case FeedbackPriority.LOW:
					return "bg-success";
				case FeedbackPriority.MID:
					return "bg-warning";
				case FeedbackPriority.HIGH:
				case FeedbackPriority.HIGHEST:
					return "bg-danger";
				case FeedbackPriority.CRITICAL:
					return "bg-dark";
				default:
					return "bg-secondary";
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<b-nav-item-dropdown
		:text="$t('navbar.admin.dropdown')"
		menu-class="dropdown-menu-end right-0"
	>
		<b-dropdown-item @click="navigator('AdminHome')">
			{{ $t("navbar.admin.adminHome") }}
		</b-dropdown-item>
		<b-dropdown-divider />
		<b-dropdown-item @click="navigator('AdminUsers')">
			{{ $t("admin.home.nav.users") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminBooks')">
			{{ $t("admin.home.nav.books") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminTags')">
			{{ $t("admin.home.nav.tags") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminGenres')">
			{{ $t("admin.home.nav.genres") }}
		</b-dropdown-item>
		<b-dropdown-divider />
		<b-dropdown-item @click="navigator('AdminSettings')">
			{{ $t("admin.home.nav.settings") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminReactivations')">
			{{ $t("admin.home.nav.reactivations") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminMail')">
			{{ $t("admin.home.nav.mail") }}
		</b-dropdown-item>
		<b-dropdown-item @click="navigator('AdminFeedbackOverview')">
			{{ $t("admin.home.nav.feedback") }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
export default {
	name: "adminDropdown",
	methods: {
		navigator: function (page) {
			this.$router.push({ name: page }).catch(() => {});
		},
	},
};
</script>

<style scoped></style>

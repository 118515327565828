<template>
	<div class="json-viewer">
		<b-container class="py-3">
			<error-message :error="error" />
			<b-form>
				<b-form-file
					v-model="jsonFile"
					accept="application/json"
					:browse-text="$t('JSONViewer.upload.browse')"
					:placeholder="$t('JSONViewer.upload.placeholder')"
					:drop-placeholder="$t('JSONViewer.upload.drop-placeholder')"
					:no-drop-placeholder="$t('JSONViewer.upload.no-drop-placeholder')"
					@input="input"
				/>
			</b-form>

			<Transition name="fade" mode="out-in">
				<div class="loader mt-3" v-if="loading">
					<div class="user">
						<b-skeleton width="30%" height="35px" />
						<b-skeleton width="70%" />
						<b-skeleton width="50%" />
						<b-skeleton width="40%" />
						<b-skeleton width="50%" />
						<b-skeleton width="40%" />
						<b-skeleton width="60%" />
						<b-skeleton width="60%" />
						<b-skeleton width="70%" />
						<b-skeleton width="50%" />
						<b-skeleton width="60%" />
						<b-skeleton width="80%" />
						<b-skeleton width="70%" />
						<b-skeleton width="50%" />
						<b-skeleton width="30%" />
						<b-skeleton width="50%" />
						<b-skeleton width="55%" />
						<b-skeleton width="55%" />

						<div class="books mt-4">
							<b-skeleton width="30%" height="25px" />
							<div
								class="book mb-3"
								v-for="i of [0, 1]"
								:key="'book-skeleton-' + i"
							>
								<b-skeleton width="70%" />
								<b-skeleton width="50%" />
								<b-skeleton width="50%" />
								<b-skeleton width="50%" />
								<b-skeleton width="50%" />
								<b-skeleton width="60%" />
								<b-skeleton width="70%" />
								<b-skeleton width="80%" />
								<b-skeleton width="40%" />

								<div class="tags mt-2">
									<b-skeleton width="30%" height="20px"></b-skeleton>

									<div
										class="tag mb-2 ms-3"
										v-for="j of [0, 2]"
										:key="'tag-skeleton-' + i + '-' + j"
									>
										<b-skeleton width="70%" />
										<b-skeleton width="60%" />
									</div>
								</div>

								<div class="chapters mt-2">
									<b-skeleton width="30%" height="20px" />

									<div
										class="chapter mb-2 ms-3"
										v-for="j of [0, 1]"
										:key="'tag-skeleton-' + i + '-' + j"
									>
										<b-skeleton width="70%" />
										<b-skeleton width="50%" />
										<b-skeleton width="80%" />
										<b-skeleton width="60%" />
										<b-skeleton width="40%" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data mt-3" v-else-if="!loading && fileContents">
					<div class="user" v-if="Object.hasOwn(fileContents, 'user')">
						<h2>{{ $t("JSONViewer.user.title") }}</h2>
						<p v-if="Object.hasOwn(fileContents.user, 'id')" class="mb-0">
							{{ $t("JSONViewer.user.id", { data: fileContents.user.id }) }}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'name')" class="mb-0">
							{{ $t("JSONViewer.user.name", { data: fileContents.user.name }) }}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'username')" class="mb-0">
							{{
								$t("JSONViewer.user.username", {
									data: fileContents.user.username,
								})
							}}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'email')" class="mb-0">
							{{
								$t("JSONViewer.user.email", { data: fileContents.user.email })
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'failedLogins')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.failedLogins", {
									data: fileContents.user.failedLogins,
								})
							}}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'isActive')" class="mb-0">
							{{
								$t("JSONViewer.user.isActive", {
									data: fileContents.user.isActive,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'disabledBy')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.disabledBy", {
									data: fileContents.user.disabledBy || "NULL",
								})
							}}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'role')" class="mb-0">
							{{ $t("JSONViewer.user.role", { data: fileContents.user.role }) }}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'roles')" class="mb-0">
							{{
								$t("JSONViewer.user.roles", { data: fileContents.user.roles })
							}}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'language')" class="mb-0">
							{{
								$t("JSONViewer.user.language", {
									data: fileContents.user.language,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'profilePicture')"
							class="mb-0"
							v-html="
								$t('JSONViewer.user.profilePicture', {
									data: fileContents.user.profilePicture,
								})
							"
						/>
						<p v-if="Object.hasOwn(fileContents.user, 'website')" class="mb-0">
							{{
								$t("JSONViewer.user.website", {
									data: fileContents.user.website,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'biography')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.biography", {
									data: fileContents.user.biography,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'communityEmail')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.communityEmail", {
									data: fileContents.user.communityEmail,
								})
							}}
						</p>
						<p v-if="Object.hasOwn(fileContents.user, 'location')" class="mb-0">
							{{
								$t("JSONViewer.user.location", {
									data: fileContents.user.location,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'debutYear')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.debutYear", {
									data: fileContents.user.debutYear,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'changePassword')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.changePassword", {
									data: fileContents.user.changePassword,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'lastLogin')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.lastLogin", {
									data: fileContents.user.lastLogin,
								})
							}}
						</p>
						<p
							v-if="Object.hasOwn(fileContents.user, 'createdAt')"
							class="mb-0"
						>
							{{
								$t("JSONViewer.user.createdAt", {
									data: fileContents.user.createdAt,
								})
							}}
						</p>

						<div
							class="books mt-4"
							v-if="Object.hasOwn(fileContents.user, 'books')"
						>
							<h3 class="h4">{{ $t("JSONViewer.books.title") }}</h3>
							<div
								class="book mb-5 ms-5"
								v-for="book of fileContents.user.books"
								:key="book.id"
							>
								<p v-if="Object.hasOwn(book, 'id')" class="mb-0">
									{{ $t("JSONViewer.books.id", { data: book.id }) }}
								</p>
								<p v-if="Object.hasOwn(book, 'title')" class="mb-0">
									{{ $t("JSONViewer.books.book-title", { data: book.title }) }}
								</p>
								<p v-if="Object.hasOwn(book, 'featured')" class="mb-0">
									{{ $t("JSONViewer.books.featured", { data: book.featured }) }}
								</p>
								<p v-if="Object.hasOwn(book, 'continuous')" class="mb-0">
									{{
										$t("JSONViewer.books.continuous", { data: book.continuous })
									}}
								</p>
								<p v-if="Object.hasOwn(book, 'public')" class="mb-0">
									{{ $t("JSONViewer.books.public", { data: book.public }) }}
								</p>
								<p v-if="Object.hasOwn(book, 'language')" class="mb-0">
									{{ $t("JSONViewer.books.language", { data: book.language }) }}
								</p>
								<p
									v-if="Object.hasOwn(book, 'coverPath')"
									class="mb-0"
									v-html="
										$t('JSONViewer.books.coverPath', { data: book.coverPath })
									"
								/>
								<p v-if="Object.hasOwn(book, 'description')" class="mb-0">
									{{
										$t("JSONViewer.books.description", {
											data:
												book.description.length > 100
													? book.description.substring(0, 100) + "[shortened]"
													: book.description,
										})
									}}
								</p>
								<p v-if="Object.hasOwn(book, 'genre')" class="mb-0">
									{{ $t("JSONViewer.books.genre", { data: book.genre }) }}
								</p>
								<p v-if="Object.hasOwn(book, 'createdAt')" class="mb-0">
									{{
										$t("JSONViewer.books.createdAt", { data: book.createdAt })
									}}
								</p>

								<div class="tags mt-2" v-if="Object.hasOwn(book, 'tags')">
									<h4 class="h6">{{ $t("JSONViewer.books.tags.title") }}</h4>

									<div
										class="tag mb-2 ms-3"
										v-for="tag of book.tags"
										:key="book.id + '+' + tag.id"
									>
										<p v-if="Object.hasOwn(tag, 'id')" class="mb-0">
											{{ $t("JSONViewer.books.tags.id", { data: tag.id }) }}
										</p>
										<p v-if="Object.hasOwn(tag, 'name')" class="mb-0">
											{{ $t("JSONViewer.books.tags.name", { data: tag.name }) }}
										</p>
									</div>
								</div>

								<div
									class="chapters mt-2"
									v-if="Object.hasOwn(book, 'chapters')"
								>
									<h4 class="h6">
										{{ $t("JSONViewer.books.chapters.title") }}
									</h4>

									<div
										class="chapter mb-2 ms-5"
										v-for="chapter of book.chapters"
										:key="book.id + '+' + chapter.id"
									>
										<p v-if="Object.hasOwn(chapter, 'id')" class="mb-0">
											{{
												$t("JSONViewer.books.chapters.id", { data: chapter.id })
											}}
										</p>
										<p v-if="Object.hasOwn(chapter, 'name')" class="mb-0">
											{{
												$t("JSONViewer.books.chapters.name", {
													data: chapter.name,
												})
											}}
										</p>
										<p v-if="Object.hasOwn(chapter, 'text')" class="mb-0">
											{{
												$t("JSONViewer.books.chapters.text", {
													data:
														chapter.text.length > 100
															? chapter.text.substring(0, 250) + "[shortened]"
															: chapter.text,
												})
											}}
										</p>
										<p v-if="Object.hasOwn(chapter, 'summary')" class="mb-0">
											{{
												$t("JSONViewer.books.chapters.summary", {
													data:
														chapter.summary.length > 100
															? chapter.summary.substring(0, 100) +
															  "[shortened]"
															: chapter.summary,
												})
											}}
										</p>
										<p v-if="Object.hasOwn(chapter, 'public')" class="mb-0">
											{{
												$t("JSONViewer.books.chapters.public", {
													data: chapter.public,
												})
											}}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="sessions"
						v-if="
							Object.hasOwn(fileContents, 'sessions') &&
							fileContents.sessions.length > 0
						"
					>
						<h2>{{ $t("JSONViewer.sessions.title") }}</h2>
						<div class="ms-5">
							<div
								class="mb-5"
								v-for="session of fileContents.sessions"
								:key="session.id"
							>
								<p v-if="Object.hasOwn(session, 'id')" class="mb-0">
									{{
										$t("JSONViewer.sessions.id", {
											data: session.id,
										})
									}}
								</p>
								<p v-if="Object.hasOwn(session, 'userId')" class="mb-0">
									{{
										$t("JSONViewer.sessions.userId", {
											data: session.userId,
										})
									}}
								</p>
								<p class="mb-0">
									{{ $t("JSONViewer.sessions.token") }}
								</p>
								<p v-if="Object.hasOwn(session, 'createdAt')" class="mb-0">
									{{
										$t("JSONViewer.sessions.createdAt", {
											data: session.createdAt,
										})
									}}
								</p>
							</div>
						</div>
					</div>
					<div
						class="reactivations mb-5"
						v-if="
							Object.hasOwn(fileContents, 'reactivations') ||
							fileContents.reactivations.length > 0
						"
					>
						<h2>{{ $t("JSONViewer.reactivations.title") }}</h2>
						<div class="ms-5">
							<div
								class="mb-5"
								v-for="reactivation of fileContents.reactivations"
								:key="reactivation.id"
							>
								<p v-if="Object.hasOwn(reactivation, 'id')" class="mb-0">
									{{
										$t("JSONViewer.reactivations.id", {
											data: reactivation.id,
										})
									}}
								</p>
								<p v-if="Object.hasOwn(reactivation, 'userId')" class="mb-0">
									{{
										$t("JSONViewer.reactivations.userId", {
											data: reactivation.userId,
										})
									}}
								</p>
								<p class="mb-0">
									{{ $t("JSONViewer.reactivations.token") }}
								</p>
								<p v-if="Object.hasOwn(reactivation, 'createdAt')" class="mb-0">
									{{
										$t("JSONViewer.reactivations.createdAt", {
											data: reactivation.createdAt,
										})
									}}
								</p>
							</div>
						</div>
					</div>
					<div class="json">
						<h2>{{ $t("JSONViewer.raw", { name: jsonFile.name }) }}</h2>
						<pre>{{ fileContents }}</pre>
					</div>
				</div>
				<div class="mt-3 text-center" v-else-if="!loading && !fileContents">
					<p>{{ $t("JSONViewer.start") }}</p>
				</div>
			</Transition>
		</b-container>
	</div>
</template>

<script>
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "JSONViewer",
	components: { ErrorMessage },

	data() {
		return {
			jsonFile: null,
			fileContents: null,
			loading: false,
			error: null,
		};
	},

	methods: {
		input(file) {
			this.loading = true;

			file
				.text()
				.then(contents => {
					try {
						this.fileContents = JSON.parse(String(contents));
					} catch (e) {
						this.error = e;
						this.jsonFile = null;
						this.fileContents = null;
					}
				})
				.catch(err => {
					this.error = err;
					this.jsonFile = null;
					this.fileContents = null;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss">
$input-height-border: 2px !default;
$input-border-width: 1px !default;

$input-line-height: 1.5 !default;
$input-line-height-sm: 1.5 !default;
$input-line-height-lg: 1.5 !default;

$input-padding-y: 0.375rem !default;
$input-padding-y-sm: 0.25rem !default;
$input-padding-y-lg: 0.5rem !default;

$input-padding-x: 0.75rem !default;
$input-padding-x-sm: 0.5rem !default;
$input-padding-x-lg: 1rem !default;

$input-border-radius: 0.25rem !default;
$input-border-radius-lg: 0.3rem !default;
$input-border-radius-sm: 0.2rem !default;

$input-focus-border-color: #007bff !default;
$input-border-color: #ced4da !default;
$input-color: #495057 !default;

$input-bg: #fff !default;
$input-group-addon-bg: #e9ecef !default;

$input-focus-box-shadow: 0 0 0 0.2rem rgba($input-border-color, 0.25) !default;
$input-box-shadow: inset 0 1px 1px rgba(#000, 0.075) !default;

$input-disabled-bg: #e9ecef !default;

$input-font-family: null !default;

$input-font-weight: 400 !default;

$input-height-inner: calc(
	$input-line-height * 1em + $input-padding-y * 2
) !default;
$input-height-inner-half: calc(
	$input-line-height * 0.5em + $input-padding-y
) !default;
$input-height-inner-quarter: calc(
	$input-line-height * 0.25em + $input-padding-y * 0.5
) !default;

$input-height: calc(
	$input-line-height * 1em + calc($input-padding-y * 2 + $input-height-border)
) !default;
$input-height-sm: calc(
	$input-line-height-sm * 1em +
		calc($input-padding-y-sm * 2 + $input-height-border)
) !default;
$input-height-lg: calc(
	$input-line-height-lg * 1em +
		calc($input-padding-y-lg * 2 + $input-height-border)
) !default;

$custom-file-height: $input-height !default;
$custom-file-height-inner: $input-height-inner !default;
$custom-file-focus-border-color: lighten(
	$input-focus-border-color,
	25%
) !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;

$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
	en: "Browse",
	de: "Durchsuchen",
	fr: "Naviguer sur",
	jp: "ブラウズ",
) !default;

// File
//
// Custom file input.

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: $custom-file-height;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: $custom-file-height;
	margin: 0;
	overflow: hidden;
	opacity: 0;

	&:focus ~ .custom-file-label {
		border-color: $custom-file-focus-border-color;
		box-shadow: $custom-file-focus-box-shadow;
	}

	// Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
	&[disabled] ~ .custom-file-label,
	&:disabled ~ .custom-file-label {
		background-color: $custom-file-disabled-bg;
	}

	@each $lang, $value in $custom-file-text {
		&:lang(#{$lang}) ~ .custom-file-label::after {
			content: $value;
		}
	}

	~ .custom-file-label[data-browse]::after {
		content: attr(data-browse);
	}
}

.custom-file-label {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: $custom-file-height;
	padding: $custom-file-padding-y $custom-file-padding-x;
	overflow: hidden;
	font-family: $custom-file-font-family;
	font-weight: $custom-file-font-weight;
	line-height: $custom-file-line-height;
	color: $custom-file-color;
	background-color: $custom-file-bg;
	border: $custom-file-border-width solid $custom-file-border-color;
	border-radius: $custom-file-border-radius;
	box-shadow: $custom-file-box-shadow;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: $custom-file-height-inner;
		padding: $custom-file-padding-y $custom-file-padding-x;
		line-height: $custom-file-line-height;
		color: $custom-file-button-color;
		content: "Browse";
		background: $custom-file-button-bg;
		border-left: inherit;
		border-radius: 0 $custom-file-border-radius $custom-file-border-radius 0;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>

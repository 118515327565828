<template>
	<div class="genres">
		<card>
			<div v-if="!loading">
				<h1 class="mb-3">{{ $t("admin.genres.list.title") }}</h1>
				<list-genre
					v-for="genre of this.genres"
					:key="genre.id"
					:genre="genre"
					@click="genreDetails(genre.id)"
					class="mt-3 cursor-pointer"
				/>
			</div>
		</card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import { api } from "@/lib/api";
import ListGenre from "@/components/listGenre.vue";

export default {
	name: "AdminTags",
	components: { ListGenre, Card },
	data() {
		return {
			loading: true,
			error: null,
			genres: [],
		};
	},

	methods: {
		getGenres() {
			this.loading = true;

			api("genres", "GET")
				.then(data => {
					this.genres = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		genreDetails(id) {
			this.$router.push({ name: "AdminGenreDetails", params: { id: id } });
		},
	},

	created() {
		this.getGenres();
	},
};
</script>

<style scoped lang="scss"></style>

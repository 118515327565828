<template>
	<div class="user" v-if="!loading">
		<div
			class="banner mb-3 d-flex align-items-baseline"
			:style="'--image: url(' + pickBanner() + ')'"
		>
			<div class="banner-container py-4">
				<b-container
					class="d-flex gap-3 position-relative flex-column flex-md-row"
				>
					<profile-picture
						:profile-picture="user.profilePicture"
						:name="user.name"
						class="flex-shrink-0 shadow position-absolute align-self-center align-self-sm-auto"
					/>
					<div
						class="spacer flex-grow-0 flex-shrink-0"
						:style="`--width: ${spacerWidth}px`"
					/>
					<div class="flex-grow-1">
						<div class="d-flex gap-2 justify-content-between">
							<h1 class="d-flex align-items-center mb-0">
								{{ user.name }}&nbsp;
								<span class="text-muted fs-4"> @{{ user.username }} </span
								>&nbsp;
								<author-badge v-if="isAuthor" class="d-inline fs-6" />
							</h1>
							<b-button
								v-if="profileEditable"
								pill
								v-b-tooltip="$t('profile.user.edit')"
								variant="link"
								@click="edit"
								class="edit-button text-decoration-none text-muted"
							>
								<b-icon icon="pencil" />
							</b-button>
						</div>
						<markdown-output
							:markdown="user.biography"
							v-if="user.biography"
							class="mb-3"
						></markdown-output>
						<div class="d-flex flex-column gap-md-2 gap-4 text-muted">
							<div class="d-md-flex gap-2 gap-md-4 flex-wrap">
								<div v-if="user.debutYear">
									<span>
										<b-icon icon="book" />&nbsp;{{ user.debutYear }}
									</span>
								</div>
								<div v-if="user.communityEmail">
									<b-link
										class="link-secondary text-decoration-none"
										:href="'mailto:' + user.communityEmail"
										target="_blank"
									>
										<b-icon icon="envelope" />&nbsp;{{ user.communityEmail }}
									</b-link>
								</div>
								<div v-if="user.location">
									<span>
										<b-icon icon="geo-alt" />&nbsp;{{ user.location }}
									</span>
								</div>
								<div v-if="user.role">
									<span>
										<b-icon icon="person" />&nbsp;{{
											$t("profile.user.role." + user.role)
										}}
									</span>
								</div>
								<div v-if="user.language">
									<span>
										<b-icon icon="chat" />&nbsp;<country-flag
											:country="user.language"
										/>
									</span>
								</div>
							</div>
							<div class="socials d-md-flex gap-2 gap-md-4 flex-wrap">
								<div v-if="user.website">
									<b-link
										class="link-secondary text-decoration-none"
										:href="user.website"
										target="_blank"
									>
										<b-icon icon="globe" />&nbsp;{{ parseUrl(user.website) }}
									</b-link>
								</div>
							</div>
						</div>
					</div>
				</b-container>
			</div>
		</div>

		<div class="featured-books mb-5" v-if="hasFeaturedBooks">
			<BContainer>
				<h2 class="mb-3">{{ $t("profile.user.featuredBooks") }}</h2>
				<div
					class="d-flex px-4 gap-3 flex-wrap justify-content-center justify-content-md-start"
				>
					<book-preview
						v-for="book of featuredBooks()"
						:key="book.id"
						:book="book"
						@click="navToBook(book.id)"
					/>
				</div>
			</BContainer>
		</div>

		<div class="books" v-if="hasBooks">
			<BContainer>
				<h2 class="mb-3">{{ $t("profile.user.allBooks") }}</h2>
				<div
					class="d-flex px-4 gap-3 flex-wrap justify-content-center justify-content-md-start"
				>
					<book-preview
						v-for="book of user.books"
						:book="book"
						:key="book.id"
						@click="navToBook(book.id)"
					/>
				</div>
			</BContainer>
		</div>

		<div class="no-books text-center" v-if="!hasBooks && !hasFeaturedBooks">
			<BContainer>
				<p class="text-muted fst-italic">{{ $t("profile.user.noBooks") }}</p>
			</BContainer>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import BookPreview from "@/components/bookPreview";
import ProfilePicture from "@/components/ProfilePicture.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";
import AuthorBadge from "@/components/authorBadge.vue";
import { UserRole } from "@/enums/user.roles";
import CountryFlag from "@/components/countryFlag.vue";
import { parseUrl } from "@/lib/parseUrl";

export default {
	name: "UserProfile",
	components: {
		CountryFlag,
		AuthorBadge,
		MarkdownOutput,
		ProfilePicture,
		BookPreview,
	},
	data() {
		return {
			parseUrl,
			id: this.$route.params.id,
			user: {},
			loading: true,
			head: {
				title: {
					inner: "Nutzerprofil",
				},
			},
			banners: [
				"https://cdn.pixabay.com/photo/2020/05/16/16/43/book-5178205_960_720.jpg",
				"https://cdn.pixabay.com/photo/2016/01/20/11/54/book-wall-1151405_960_720.jpg",
				"https://cdn.pixabay.com/photo/2022/11/03/13/07/book-7567437_960_720.jpg",
				"https://cdn.pixabay.com/photo/2015/12/04/09/13/leaves-1076307_960_720.jpg",
			],
			spacerWidth: "0px",
		};
	},

	head() {
		return this.head;
	},

	methods: {
		getUser: function () {
			api("users/" + this.id)
				.then(data => {
					this.user = data;
					this.head.title.inner = this.$t("profile.user.pageTitle", {
						name: data.name,
						username: data.username,
					});
					this.$emit("updateHead");
					this.loading = false;
				})
				.catch(() => {});
		},

		pickBanner: function () {
			return this.banners[Math.floor(Math.random() * this.banners.length)];
		},

		featuredBooks: function () {
			return this.user.books.filter(book => book.featured);
		},

		navToBook(id) {
			this.$router.push({ name: "BookDetails", params: { id: id } });
		},

		edit: function () {
			if (this.user.id !== this.$store.getters.userId) return;
			this.$router.push({ name: "EditProfile" });
		},
	},

	computed: {
		hasFeaturedBooks: function () {
			if (!this.hasBooks) return false;
			return this.user.books.some(book => book.featured);
		},

		hasBooks: function () {
			return this.user.books.length > 0;
		},

		isAuthor: function () {
			return this.user.roles.includes(UserRole.AUTHOR);
		},

		profileEditable: function () {
			return this.user.id === this.$store.getters.userId;
		},
	},

	watch: {
		id() {
			this.getUser();
		},
	},

	created() {
		this.$watch(
			() => this.$route.params,
			toParams => {
				this.id = toParams.id;
			}
		);
		this.getUser();
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.banner {
	background: var(--image);
	background-size: cover;
	background-position: center;
}

.banner-container {
	background: lighten($gray, 6.5%);
	width: 100%;
	height: auto;
	margin-top: 200px;

	.profile-picture {
		top: -110px;
		width: 200px;
		border: 6px #eaeaea solid;
	}

	.spacer {
		width: 200px;
		height: 90px;
	}
}

.edit-button {
	height: fit-content;
	width: fit-content;
	aspect-ratio: 1/1 !important;
}
</style>

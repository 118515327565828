import Vue from "vue";
import VueRouter from "vue-router";

import {
	pageTitleRouterHandler,
	protectedRouterHandler,
} from "@/maps/route.map";

import Home from "@/views/Home.vue";
import BookDetails from "@/views/Books/BookDetails.vue";
import BookRead from "@/views/Books/BookRead.vue";
import EditProfile from "@/views/Profile/EditProfile.vue";
import UserProfile from "@/views/Profile/UserProfile.vue";
import Login from "@/views/Login/Login.vue";
import RequestReset from "@/views/Login/RequestReset.vue";
import ResetPassword from "@/views/Login/ResetPassword.vue";
import Reactivate from "@/views/Login/Reactivate.vue";
import TagList from "@/views/Tags/TagList.vue";
import TagDetails from "@/views/Tags/TagDetails.vue";
import AdminHome from "@/views/Admin/AdminHome.vue";
import AdminOverview from "@/views/Admin/AdminOverview.vue";
import AdminUsers from "@/views/Admin/Users/AdminUsers.vue";
import AdminUserDetail from "@/views/Admin/Users/AdminUserDetail.vue";
import AdminBooks from "@/views/Admin/Books/AdminBooks.vue";
import AdminSettings from "@/views/Admin/AdminSettings.vue";
import NewBook from "@/views/Author/NewBook.vue";
import MyBooks from "@/views/Author/MyBooks.vue";
import NotFound from "@/views/Errors/NotFound.vue";
import EditBook from "@/views/Author/EditBook.vue";
import AdminReactivations from "@/views/Admin/Reactivations/AdminReactivations.vue";
import JSONViewer from "@/views/JSONViewer.vue";
import SecurityCenter from "@/views/Profile/SecurityCenter.vue";
import EditChapter from "@/views/Author/EditChapter.vue";
import NewChapter from "@/views/Author/NewChapter.vue";
import AdminBookDetail from "@/views/Admin/Books/AdminBookDetail.vue";
import ChangePassword from "@/views/Login/ChangePassword.vue";
import Signup from "@/views/Login/Signup.vue";
import SearchPage from "@/views/Search/SearchPage.vue";
import AdminMail from "@/views/Admin/AdminMail.vue";
import NewFeedback from "@/views/Feedback/NewFeedback.vue";
import MyFeedback from "@/views/Feedback/MyFeedback.vue";
import MyFeedbackDetail from "@/views/Feedback/MyFeedbackDetail.vue";
import AdminFeedbackHome from "@/views/Admin/Feedback/AdminFeedbackHome.vue";
import AdminFeedbackFeature from "@/views/Admin/Feedback/AdminFeedbackFeature.vue";
import AdminFeedbackOverview from "@/views/Admin/Feedback/AdminFeedbackOverview.vue";
import AdminFeedbackDetail from "@/views/Admin/Feedback/AdminFeedbackDetail.vue";
import AdminFeedbackFeatureDetail from "@/views/Admin/Feedback/AdminFeedbackFeatureDetail.vue";
import FAQ from "@/views/FAQ.vue";
import GenreList from "@/views/Genre/GenreList.vue";
import GenreDetails from "@/views/Genre/GenreDetails.vue";
import AdminTags from "@/views/Admin/Tags/AdminTags.vue";
import AdminTagDetails from "@/views/Admin/Tags/AdminTagDetails.vue";
import AdminGenres from "@/views/Admin/Genres/AdminGenres.vue";
import AdminGenreDetails from "@/views/Admin/Genres/AdminGenreDetails.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/search",
		name: "Search",
		component: SearchPage,
	},
	{
		path: "/book/:id",
		name: "BookDetails",
		component: BookDetails,
	},
	{
		path: "/book/:bookId/read/:chapterId",
		name: "BookRead",
		component: BookRead,
	},
	{
		path: "/user/edit",
		name: "EditProfile",
		component: EditProfile,
	},
	{
		path: "/user/security-center",
		name: "SecurityCenter",
		component: SecurityCenter,
	},
	{
		path: "/user/:id",
		name: "UserProfile",
		component: UserProfile,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/signup",
		name: "Signup",
		component: Signup,
	},
	{
		path: "/login/change-password",
		name: "ChangePassword",
		component: ChangePassword,
	},
	{
		path: "/request-password-reset",
		name: "RequestPasswordReset",
		component: RequestReset,
	},
	{
		path: "/reset-password/:token",
		name: "ResetPassword",
		component: ResetPassword,
	},
	{
		path: "/reactivate/:token",
		name: "Reactivate",
		component: Reactivate,
	},
	{
		path: "/json",
		name: "JSONViewer",
		component: JSONViewer,
	},
	{
		path: "/feedback",
		name: "FeedbackForm",
		component: NewFeedback,
	},
	{
		path: "/feedback/my",
		name: "MyFeedback",
		component: MyFeedback,
	},
	{
		path: "/feedback/:id",
		name: "MyFeedbackDetail",
		component: MyFeedbackDetail,
	},
	{
		path: "/faq",
		name: "Faq",
		component: FAQ,
	},
	{
		path: "/tags/",
		name: "TagList",
		component: TagList,
	},
	{
		path: "/tags/:id",
		name: "TagDetails",
		component: TagDetails,
	},
	{
		path: "/genres/",
		name: "GenreList",
		component: GenreList,
	},
	{
		path: "/genres/:id",
		name: "GenreDetails",
		component: GenreDetails,
	},
	{
		path: "/admin/",
		component: AdminHome,
		children: [
			{
				path: "",
				name: "AdminHome",
				component: AdminOverview,
			},
			{
				path: "users",
				name: "AdminUsers",
				component: AdminUsers,
			},
			{
				path: "users/:id",
				name: "AdminUsersDetail",
				component: AdminUserDetail,
			},
			{
				path: "books",
				name: "AdminBooks",
				component: AdminBooks,
			},
			{
				path: "books/:id",
				name: "AdminBookDetail",
				component: AdminBookDetail,
			},
			{
				path: "tags",
				name: "AdminTags",
				component: AdminTags,
			},
			{
				path: "tags/:id",
				name: "AdminTagDetails",
				component: AdminTagDetails,
			},
			{
				path: "genres",
				name: "AdminGenres",
				component: AdminGenres,
			},
			{
				path: "genres/:id",
				name: "AdminGenreDetails",
				component: AdminGenreDetails,
			},
			{
				path: "settings",
				name: "AdminSettings",
				component: AdminSettings,
			},
			{
				path: "reactivations",
				name: "AdminReactivations",
				component: AdminReactivations,
			},
			{
				path: "mail",
				name: "AdminMail",
				component: AdminMail,
			},
			{
				path: "feedback",
				component: AdminFeedbackHome,
				children: [
					{
						path: "/",
						name: "AdminFeedbackOverview",
						component: AdminFeedbackOverview,
					},
					{
						path: "features",
						name: "AdminFeedbackFeature",
						component: AdminFeedbackFeature,
					},
					{
						path: "features/:id",
						name: "AdminFeedbackFeatureDetail",
						component: AdminFeedbackFeatureDetail,
					},
					{
						path: ":id",
						name: "AdminFeedbackDetail",
						component: AdminFeedbackDetail,
					},
				],
			},
		],
	},
	{
		path: "/author/new-book",
		name: "NewBook",
		component: NewBook,
	},
	{
		path: "/author/my-books",
		name: "MyBooks",
		component: MyBooks,
	},
	{
		path: "/author/edit-book/:id",
		name: "EditBook",
		component: EditBook,
	},
	{
		path: "/author/edit-book/:id/new-chapter",
		name: "NewChapter",
		component: NewChapter,
	},
	{
		path: "/author/edit-book/:bookId/edit-chapter/:chapterId",
		name: "EditChapter",
		component: EditChapter,
	},
	{
		path: "*",
		name: "404 Seite nicht gefunden",
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
	scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach(pageTitleRouterHandler);
router.beforeEach(protectedRouterHandler);
router.afterEach(() => {
	window.scroll(window.scrollX, 0);
});

export default router;

<template>
	<b-nav-item-dropdown
		:text="$t('navbar.loggedIn.dropdown')"
		menu-class="dropdown-menu-end right-0"
	>
		<b-dropdown-item @click="profile"
			>{{ $t("navbar.loggedIn.profile") }}
		</b-dropdown-item>
		<b-dropdown-item @click="secCenter">
			{{ $t("navbar.loggedIn.secCenter") }}
		</b-dropdown-item>
		<b-dropdown-divider />
		<b-dropdown-item @click="logout"
			>{{ $t("navbar.loggedIn.logout") }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
import localStorage from "@/lib/localStorage";
import { api } from "@/lib/api";

export default {
	name: "loggedInDropdown",
	methods: {
		profile: function () {
			this.$router
				.push({
					name: "UserProfile",
					params: { id: this.$store.getters.userId },
				})
				.catch(() => {});
		},

		async logout() {
			try {
				if (!(await api("auth/logout", "GET", true)).loggedOut)
					return this.$toast.error(this.$t("navbar.loggedIn.logoutFailed"), {
						position: "top-right",
					});
			} catch (e) {
				console.error(e);
				return this.$toast.error(this.$t("navbar.loggedIn.logoutFailed"), {
					position: "top-right",
				});
			}
			await this.$store.dispatch("clearUser");
			localStorage.removeItem("accessToken");
		},

		secCenter() {
			this.$router.push({ name: "SecurityCenter" }).catch(() => {});
		},
	},
};
</script>

<style scoped></style>

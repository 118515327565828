<template>
	<div
		class="user d-flex justify-content-center align-items-center flex-column flex-sm-row gap-1"
	>
		<div class="d-flex w-100 flex-grow-1">
			<profile-picture
				class="profile-picture me-3"
				:name="user.name"
				:profile-picture="user.profilePicture"
			/>
			<div class="flex-grow-1">
				<p class="m-0">{{ user.name }}</p>
				<p class="m-0 text-muted fst-italic">
					@{{ user.username.toLowerCase() }}
				</p>
			</div>
		</div>
		<div class="w-100 w-sm-auto">
			<b-button
				variant="primary"
				class="mb-1 d-block w-100 w-sm-auto"
				@click="$emit('details', user.id)"
			>
				<b-icon icon="pencil-square" />&nbsp;{{ $t("listUser.details") }}
			</b-button>
			<b-button
				variant="outline-danger"
				class="d-block w-100 w-sm-auto"
				v-if="$store.getters.userId !== user.id"
				@click="$emit('delete', user.id)"
				:disabled="loading"
			>
				<b-icon icon="trash" />&nbsp;{{ $t("listUser.delete") }}
			</b-button>
		</div>
	</div>
</template>

<script>
import ProfilePicture from "@/components/ProfilePicture.vue";

export default {
	name: "listUser",
	components: { ProfilePicture },
	props: {
		user: {
			type: Object,
			required: true,
			default: () => {
				return {
					id: "0000-0000-0000-0000",
					name: "Max Mustermann",
					username: "MaxMustermann",
					profilePicture: "https://placehold.co/150",
				};
			},
		},
		loading: {
			type: Boolean,
		},
	},
};
</script>

<style scoped lang="scss">
.profile-picture {
	height: 60px;
	border: 3px #eaeaea solid;
}

@media (min-width: 576px) {
	.w-sm-auto {
		width: max-content !important;
	}
}
</style>

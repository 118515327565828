<template>
	<img
		class="flag rounded"
		:alt="$t('countryFlag.altText', { country: country.toUpperCase() })"
		:src="require(`@/assets/flags/${country}.flag.svg`)"
		draggable="false"
	/>
</template>

<script>
export default {
	name: "countryFlag",
	props: {
		country: {
			type: String,
			required: true,
			default: "de",
		},
	},
};
</script>

<style scoped lang="scss">
.flag {
	height: 1rem;
	width: auto;
}
</style>

<template>
	<b-badge :class="color">{{ $t(`enums.feedbackType.${type}`) }}</b-badge>
</template>

<script>
import { FeedbackType } from "@/enums/feedback.enum";

export default {
	name: "feedbackTypeBadge",
	props: {
		type: {
			type: String,
			default: "NONE",
		},
	},

	computed: {
		color() {
			switch (this.type) {
				case FeedbackType.BUG:
					return "bg-warning";
				case FeedbackType.SECURITY:
					return "bg-danger";
				case FeedbackType.IMPROVEMENT:
					return "bg-success";
				default:
					return "bg-secondary";
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="settings">
		<Card v-if="initialLoad">
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.settings.title") }}</h1>
				<b-skeleton type="button" />
			</div>
			<fieldset
				v-for="i of range(0, 4)"
				:key="'setting-skeleton-' + i"
				class="d-flex mb-3 align-items-center"
			>
				<b-skeleton class="col-3 me-2 text-end" />
				<b-skeleton type="input" class="col-8" />
				<b-skeleton type="button" class="col-1 ms-2" />
			</fieldset>

			<b-skeleton type="button" />
		</Card>

		<Card v-else-if="!initialLoad">
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.settings.title") }}</h1>
				<b-button
					size="sm"
					variant="outline-secondary"
					@click="showNewSettingModal(true)"
					:disabled="loading"
				>
					<b-icon icon="plus-circle" />
					{{ $t("admin.settings.newSetting.button") }}
				</b-button>
			</div>
			<error-message :error="error" v-if="!showNewModal" />
			<b-form v-if="settings.length > 0">
				<fieldset
					v-for="setting of settings"
					:key="setting.id"
					class="d-flex mb-3 align-items-center row w-100"
				>
					<label
						class="col-12 col-md-3 text-md-end mb-2 mb-md-0"
						:for="'setting-' + setting.key"
						v-b-tooltip="setting.description"
						>{{ setting.key }}:</label
					>

					<div class="col-10 col-md-7">
						<input
							type="checkbox"
							class="form-check-input"
							:id="'setting-' + setting.key"
							v-if="setting.type === SettingType.BOOLEAN"
							v-model="setting.value"
						/>

						<b-form-input
							:id="'setting-' + setting.key"
							type="number"
							step="1"
							v-else-if="setting.type === SettingType.INTEGER"
							v-model="setting.value"
						/>

						<b-form-input
							:id="'setting-' + setting.key"
							type="number"
							step="0.01"
							v-else-if="setting.type === SettingType.FLOAT"
							v-model="setting.value"
						/>

						<b-form-input
							:id="'setting-' + setting.key"
							type="text"
							v-else
							v-model="setting.value"
						/>
					</div>

					<b-button
						variant="outline-danger"
						class="col-2 col-md-2"
						@click="deleteSetting(setting.id)"
						:disabled="loading"
					>
						<b-icon icon="trash" />
					</b-button>
				</fieldset>

				<b-button variant="primary" @click="saveSettings" :disabled="loading"
					>{{ $t("admin.settings.save") }}
				</b-button>
			</b-form>
		</Card>

		<b-modal v-model="showNewModal">
			<template #modal-header="{ close }">
				<h5 class="modal-title">{{ $t("admin.settings.newSetting.title") }}</h5>
				<b-button variant="close" @click="close"></b-button>
			</template>

			<error-message :error="error" v-if="showNewModal" />

			<b-input
				v-model="newSetting.key"
				:placeholder="$t('admin.settings.newSetting.key')"
				class="mb-3"
				:formatter="keyFormatter"
				:state="keyValid"
			/>
			<b-form-select
				class="form-select mb-3"
				v-model="newSetting.type"
				:options="typeOptions"
				@change="typeChange"
			/>

			<div class="mb-3">
				<div class="form-check" v-if="newSetting.type === SettingType.BOOLEAN">
					<label for="newSetting-initialValue-checkbox">{{
						$t("admin.settings.newSetting.initialValue")
					}}</label>
					<input
						type="checkbox"
						class="form-check-input"
						v-model="newSetting.value"
						id="newSetting-initialValue-checkbox"
					/>
				</div>
				<b-form-input
					type="number"
					step="1"
					v-else-if="newSetting.type === SettingType.INTEGER"
					v-model="newSetting.value"
					:placeholder="$t('admin.settings.newSetting.initialValue')"
					:state="valueValid"
				/>
				<b-form-input
					type="number"
					step="0.01"
					v-else-if="newSetting.type === SettingType.FLOAT"
					v-model="newSetting.value"
					:placeholder="$t('admin.settings.newSetting.initialValue')"
					:state="valueValid"
				/>
				<b-form-input
					type="text"
					v-else
					v-model="newSetting.value"
					:disabled="newSetting.type === null"
					:placeholder="$t('admin.settings.newSetting.initialValue')"
					:state="valueValid"
				/>
			</div>

			<div class="form-check mb-3">
				<label for="newSetting-isPublic-checkbox">{{
					$t("admin.settings.newSetting.public")
				}}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="newSetting.isPublic"
					id="newSetting-isPublic-checkbox"
				/>
			</div>

			<b-form-input
				type="text"
				v-model="newSetting.description"
				:placeholder="$t('admin.settings.newSetting.description')"
			/>

			<template #modal-footer>
				<b-button
					variant="secondary"
					@click="showNewSettingModal(false)"
					:disabled="loading"
					>{{ $t("admin.settings.newSetting.abort") }}
				</b-button>
				<b-button
					variant="primary"
					@click="createNewSetting"
					:disabled="loading || !formValid"
					>{{ $t("admin.settings.newSetting.save") }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import { api } from "@/lib/api";
import { SettingType } from "@/enums/settingType.enum";
import { range } from "@/lib/range";
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "AdminSettings",
	components: { ErrorMessage, Card },
	data() {
		return {
			SettingType: SettingType,
			range: range,
			settings: [],
			error: null,
			initialLoad: true,
			loading: false,
			showNewModal: false,
			typeOptions: [
				{
					value: null,
					text: this.$t("admin.settings.newSetting.type.0"),
					disabled: true,
				},
				{
					value: SettingType.STRING,
					text: this.$t("admin.settings.newSetting.type.1"),
				},
				{
					value: SettingType.INTEGER,
					text: this.$t("admin.settings.newSetting.type.2"),
				},
				{
					value: SettingType.FLOAT,
					text: this.$t("admin.settings.newSetting.type.3"),
				},
				{
					value: SettingType.BOOLEAN,
					text: this.$t("admin.settings.newSetting.type.4"),
				},
			],
			newSetting: {
				key: null,
				type: null,
				value: null,
				isPublic: false,
				description: null,
			},
		};
	},

	methods: {
		getSettings: function () {
			api("utils/settings", "GET", true)
				.then(data => {
					this.initialLoad = false;
					this.settings = data;
				})
				.catch(err => {
					this.initialLoad = false;
					this.error = err;
				});
		},

		saveSettings: function () {
			this.loading = true;
			api("utils/settings", "PATCH", true, this.settings)
				.then(data => {
					this.loading = false;
					this.settings = data;
				})
				.catch(err => {
					this.loading = false;
					this.error = err;
				});
		},

		showNewSettingModal: function (show) {
			this.showNewModal = show;

			if (!show) {
				this.newSetting = {
					key: null,
					type: null,
					value: null,
					isPublic: false,
					description: null,
				};
			}
		},

		createNewSetting: function () {
			if (!this.formValid) return;
			this.loading = true;
			this.newSetting.value = String(this.newSetting.value);
			api("utils/settings", "POST", true, this.newSetting)
				.then(data => {
					this.settings = data;
					this.loading = false;
					this.showNewSettingModal(false);
				})
				.catch(err => {
					this.loading = false;
					this.error = err;
				});
		},

		keyFormatter: function (value) {
			value = value.toLowerCase().replaceAll(" ", "_");
			value = value.replaceAll(/[^a-zA-Z_]+/gm, "");
			return value;
		},

		typeChange: function (event) {
			if (event === "BOOLEAN") this.newSetting.value = true;
			else this.newSetting.value = null;
		},

		deleteSetting: function (id) {
			this.loading = true;
			api("utils/settings/" + id, "DELETE", true)
				.then(data => {
					this.settings = data;
					this.loading = false;
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
				});
		},
	},

	computed: {
		keyValid() {
			if (this.newSetting.key === null) return null;
			for (const setting of this.settings) {
				if (setting.key === this.newSetting.key) return false;
			}
			return true;
		},

		valueValid() {
			if (this.newSetting.value === null) return null;
			if (typeof this.newSetting.value !== "boolean") {
				if (this.newSetting.value.length < 1) return false;
			}
			return true;
		},

		formValid() {
			return (
				this.valueValid &&
				this.keyValid &&
				this.newSetting.type !== null &&
				this.newSetting.value !== null
			);
		},
	},

	created() {
		this.getSettings();
	},
};
</script>

<style scoped lang="scss"></style>

export function accessTokenPayload(accessToken) {
	if (accessToken !== null) {
		try {
			const decoded = atob(accessToken.split(".")[1]);

			return JSON.parse(decoded);
		} catch {
			return null;
		}
	} else {
		return null;
	}
}

export function isAccessTokenValid(accessToken) {
	if (accessToken === null) {
		return false;
	} else {
		const payload = accessTokenPayload(accessToken);

		if (Object.hasOwn(payload, "exp")) {
			const now = Date.now() / 1000;
			return payload.exp >= now;
		}

		return false;
	}
}

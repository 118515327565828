<template>
	<div class="tags">
		<card>
			<div v-if="!loading">
				<h1 class="mb-3">{{ $t("admin.tags.list.title") }}</h1>
				<list-tag
					v-for="tag of this.tags"
					:key="tag.id"
					:tag="tag"
					@click="tagDetails(tag.id)"
					class="mt-3 cursor-pointer"
				/>
			</div>
		</card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import ListTag from "@/components/listTag.vue";
import { api } from "@/lib/api";

export default {
	name: "AdminTags",
	components: { ListTag, Card },
	data() {
		return {
			loading: true,
			error: null,
			tags: [],
		};
	},

	methods: {
		getTags() {
			this.loading = true;

			api("tags", "GET")
				.then(data => {
					this.tags = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		tagDetails(id) {
			this.$router.push({ name: "AdminTagDetails", params: { id: id } });
		},
	},

	created() {
		this.getTags();
	},
};
</script>

<style scoped lang="scss"></style>

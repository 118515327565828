<template>
	<div class="tags container" v-if="!loading">
		<list-tag
			v-for="tag of this.tags"
			:key="tag.id"
			:tag="tag"
			@click="navigate(tag.id)"
			class="mt-3 cursor-pointer"
		/>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ListTag from "@/components/listTag.vue";

export default {
	name: "TagList",
	components: { ListTag },
	data() {
		return {
			loading: true,
			error: null,
			tags: [],
		};
	},

	methods: {
		getTags() {
			this.loading = true;

			api("tags", "GET")
				.then(data => {
					this.tags = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		navigate(id) {
			this.$router.push({ name: "TagDetails", params: { id: id } });
		},
	},

	created() {
		this.getTags();
	},
};
</script>

<style scoped></style>

<template>
	<div class="feedback">
		<b-container class="mt-4">
			<error-message :error="error" />
			<b-form v-on:submit.prevent="sendFeedback">
				<b-form-select
					class="form-select mb-2"
					:options="features"
					text-field="name"
					value-field="id"
					v-model="feedback.featureId"
				>
					<template #first>
						<b-form-select-option :value="null" disabled>
							{{ $t("feedback.form.feature") }}
						</b-form-select-option>
					</template>
				</b-form-select>
				<b-form-input
					class="mb-2"
					v-model="feedback.title"
					:placeholder="$t('feedback.form.titlePlaceholder')"
				/>
				<b-form-select
					class="form-select mb-2"
					v-model="feedback.type"
					:options="types"
				>
					<template #first>
						<b-form-select-option :value="null" disabled>
							{{ $t("enums.feedbackType.NONE") }}
						</b-form-select-option>
					</template>
				</b-form-select>
				<b-form-select
					class="form-select mb-2"
					v-model="feedback.priority"
					:options="priorities"
				>
					<template #first>
						<b-form-select-option :value="null" disabled>
							{{ $t("enums.feedbackPriority.NONE") }}
						</b-form-select-option>
					</template>
				</b-form-select>
				<b-form-textarea
					v-model="feedback.feedbackText"
					rows="10"
					:placeholder="$t('feedback.form.textArea')"
					class="mb-4"
				/>

				<div class="form-check" v-if="!!$store.getters.userId">
					<input
						class="form-check-input"
						type="checkbox"
						value=""
						id="flexCheckDefault"
						v-model="linkAccount"
					/>
					<label class="form-check-label" for="flexCheckDefault">
						{{ $t("feedback.form.linkAccount") }}
					</label>
				</div>
				<b-form-input
					class="mb-3"
					v-model="feedback.email"
					type="email"
					v-if="!linkAccount || !$store.getters.userId"
					:placeholder="$t('feedback.form.email')"
				/>
				<b-button type="submit" variant="primary" :disabled="sending">
					{{ $t("feedback.form.send") }}
				</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ErrorMessage from "@/components/errorMessage.vue";
import { FeedbackPriority, FeedbackType } from "@/enums/feedback.enum";

export default {
	name: "NewFeedback",
	components: { ErrorMessage },
	data() {
		return {
			features: [],
			error: null,
			sending: false,
			linkAccount: !!this.$store.getters.userId,
			priorities: [
				{
					value: FeedbackPriority.LOWEST,
					text: this.$t("enums.feedbackPriority.LOWEST"),
				},
				{
					value: FeedbackPriority.LOW,
					text: this.$t("enums.feedbackPriority.LOW"),
				},
				{
					value: FeedbackPriority.MID,
					text: this.$t("enums.feedbackPriority.MID"),
				},
				{
					value: FeedbackPriority.HIGH,
					text: this.$t("enums.feedbackPriority.HIGH"),
				},
				{
					value: FeedbackPriority.HIGHEST,
					text: this.$t("enums.feedbackPriority.HIGHEST"),
				},
				{
					value: FeedbackPriority.CRITICAL,
					text: this.$t("enums.feedbackPriority.CRITICAL"),
				},
			],
			types: [
				{ value: FeedbackType.BUG, text: this.$t("enums.feedbackType.BUG") },
				{
					value: FeedbackType.IMPROVEMENT,
					text: this.$t("enums.feedbackType.IMPROVEMENT"),
				},
				{
					value: FeedbackType.SECURITY,
					text: this.$t("enums.feedbackType.SECURITY"),
				},
			],
			feedback: {
				featureId: null,
				title: null,
				userId: this.$store.getters.userId ? this.$store.getters.userId : null,
				email: null,
				type: null,
				priority: null,
				feedbackText: null,
				version: this.$store.state.currentVersion,
			},
		};
	},

	methods: {
		getFeatures() {
			api("feedback/features", "GET")
				.then(data => {
					this.features = data;
				})
				.catch(err => {
					this.error = err;
				});
		},

		sendFeedback() {
			this.sending = true;
			api("feedback", "POST", false, this.feedback)
				.catch(err => {
					this.error = err;
				})
				.finally(() => (this.sending = false));
		},
	},

	watch: {
		linkAccount() {
			if (this.linkAccount) this.feedback.userId = this.$store.getters.userId;
			else this.feedback.userId = null;
		},
		"feedback.email"() {
			if (
				typeof this.feedback.email === "string" &&
				this.feedback.email.length <= 0
			)
				this.feedback.email = null;
		},
	},

	created() {
		this.getFeatures();
	},
};
</script>

<style scoped lang="scss"></style>

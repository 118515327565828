<template>
	<card>
		<h1>{{ $t("admin.feedback.title") }}</h1>
		<b-nav tabs>
			<!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
			<b-nav-item to="/admin/feedback" exact exact-active-class="active">
				{{ $t("admin.feedback.nav.feedback") }}
			</b-nav-item>
			<b-nav-item
				to="/admin/feedback/features"
				exact
				exact-active-class="active"
			>
				{{ $t("admin.feedback.nav.features") }}
			</b-nav-item>
		</b-nav>
		<router-view></router-view>
	</card>
</template>

<script>
import Card from "@/components/card.vue";

export default {
	name: "AdminFeedbackHome",
	components: { Card },
};
</script>

<style scoped lang="scss"></style>

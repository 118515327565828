<template>
	<div class="feedback-detail">
		<error-message :error="error" />
		<div class="mt-3" v-if="feedback.id">
			<h3>{{ feedback.title }}</h3>
			<p class="border rounded-2 p-3 mb-4">{{ feedback.feedbackText }}</p>

			<h5>{{ $t("admin.feedback.feedback.detail.status.title") }}</h5>
			<div class="mb-3">
				<label for="feedback-type" class="form-label">
					{{ $t("enums.feedbackType.NONE") }}
				</label>
				<select
					class="form-select"
					v-model="feedback.type"
					id="feedback-type"
					:disabled="loading"
				>
					<option :value="FeedbackType.BUG">
						{{ $t("enums.feedbackType.BUG") }}
					</option>
					<option :value="FeedbackType.IMPROVEMENT">
						{{ $t("enums.feedbackType.IMPROVEMENT") }}
					</option>
					<option :value="FeedbackType.SECURITY">
						{{ $t("enums.feedbackType.SECURITY") }}
					</option>
				</select>
			</div>

			<div class="mb-3">
				<label for="feedback-priority" class="form-label">
					{{ $t("enums.feedbackPriority.NONE") }}
				</label>
				<select
					class="form-select"
					v-model="feedback.priority"
					id="feedback-priority"
					:disabled="loading"
				>
					<option :value="FeedbackPriority.LOWEST">
						{{ $t("enums.feedbackPriority.LOWEST") }}
					</option>
					<option :value="FeedbackPriority.LOW">
						{{ $t("enums.feedbackPriority.LOW") }}
					</option>
					<option :value="FeedbackPriority.MID">
						{{ $t("enums.feedbackPriority.MID") }}
					</option>
					<option :value="FeedbackPriority.HIGH">
						{{ $t("enums.feedbackPriority.HIGH") }}
					</option>
					<option :value="FeedbackPriority.HIGHEST">
						{{ $t("enums.feedbackPriority.HIGHEST") }}
					</option>
					<option :value="FeedbackPriority.CRITICAL">
						{{ $t("enums.feedbackPriority.CRITICAL") }}
					</option>
				</select>
			</div>

			<div class="mb-4">
				<label for="feedback-status" class="form-label">
					{{ $t("enums.feedbackStatus.NONE") }}
				</label>
				<select
					class="form-select"
					v-model="feedback.status"
					id="feedback-status"
					:disabled="loading"
				>
					<option :value="FeedbackStatus.RECEIVED">
						{{ $t("enums.feedbackStatus.RECEIVED") }}
					</option>
					<option :value="FeedbackStatus.REVIEWED">
						{{ $t("enums.feedbackStatus.REVIEWED") }}
					</option>
					<option :value="FeedbackStatus.IN_WORK">
						{{ $t("enums.feedbackStatus.IN_WORK") }}
					</option>
					<option :value="FeedbackStatus.PENDING">
						{{ $t("enums.feedbackStatus.PENDING") }}
					</option>
					<option :value="FeedbackStatus.RESOLVED">
						{{ $t("enums.feedbackStatus.RESOLVED") }}
					</option>
					<option :value="FeedbackStatus.REJECTED">
						{{ $t("enums.feedbackStatus.REJECTED") }}
					</option>
				</select>
			</div>

			<h5>{{ $t("admin.feedback.feedback.detail.versions.title") }}</h5>
			<p class="mb-0">
				{{
					$t("admin.feedback.feedback.detail.versions.reported", {
						version: feedback.version,
					})
				}}
			</p>
			<p class="mb-4" v-if="feedback.resolvedVersion">
				{{
					$t("admin.feedback.feedback.detail.versions.resolved", {
						version: feedback.resolvedVersion,
					})
				}}
			</p>
			<div v-if="!feedback.resolvedVersion" class="mb-3">
				<p class="mb-0 d-inline-block">
					{{ $t("admin.feedback.feedback.detail.versions.resolvedNA") }}
				</p>
				<div class="input-group ms-2 d-inline-flex w-auto">
					<input
						type="text"
						class="form-control"
						:placeholder="
							$t(
								'admin.feedback.feedback.detail.versions.resolvedVersionPlaceholder'
							)
						"
						aria-label="Resolved version"
						v-model="resolvedVersion"
						:disabled="feedback.resolvedVersion || loading"
					/>
				</div>
			</div>

			<h5>{{ $t("admin.feedback.feedback.detail.feature.title") }}</h5>
			<p class="mb-4">
				{{
					$t("admin.feedback.feedback.detail.feature.name", {
						name: feedback.feature.name,
					})
				}}
			</p>

			<h5>{{ $t("admin.feedback.feedback.detail.reporter.title") }}</h5>
			<div
				class="d-flex gap-4 cursor-pointer reviewer mb-3"
				@click="visitReviewer"
				v-if="feedback.reported"
			>
				<profile-picture
					:profile-picture="feedback.reported.profilePicture"
					:name="feedback.reported.name"
				/>
				<div class="d-flex flex-column justify-content-center">
					<p class="mb-0">
						{{ feedback.reported.name }}
						<span class="text-muted">@{{ feedback.reported.username }}</span>
					</p>
					<p class="mb-0">
						{{ $t("admin.feedback.feedback.detail.reporter.language") }}
						<country-flag :country="feedback.reported.language" />
					</p>
					<p class="mb-0">
						{{ $t("admin.feedback.feedback.detail.reporter.email") }}
						<a @click.stop :href="`mailto:${feedback.reported.email}`">{{
							feedback.reported.email
						}}</a>
					</p>
				</div>
			</div>
			<div v-else-if="feedback.email">
				<p>
					{{ $t("admin.feedback.feedback.detail.reporter.email") }}
					<a :href="`mailto:${feedback.email}`">{{ feedback.email }}</a>
				</p>
			</div>
			<div v-else>
				<p class="text-muted fst-italic">
					{{ $t("admin.feedback.feedback.detail.reporter.noContact") }}
				</p>
			</div>

			<h5>{{ $t("admin.feedback.feedback.detail.reviewer.title") }}</h5>
			<p class="fst-italic text-muted" v-if="!feedback.assigned">
				{{ $t("admin.feedback.feedback.detail.reviewer.notAssigned") }}
			</p>
			<div
				class="d-flex gap-4 cursor-pointer reviewer mb-5"
				@click="visitReviewer"
				v-if="feedback.assigned"
			>
				<profile-picture
					:profile-picture="feedback.assigned.profilePicture"
					:name="feedback.assigned.name"
				/>
				<div class="d-flex flex-column justify-content-center">
					<p class="mb-0">
						{{ feedback.assigned.name }}
						<span class="text-muted">@{{ feedback.assigned.username }}</span>
					</p>
					<p class="mb-0">
						{{ $t("admin.feedback.feedback.detail.reviewer.language") }}
						<country-flag :country="feedback.assigned.language" />
					</p>
					<p class="mb-0">
						{{ $t("admin.feedback.feedback.detail.reviewer.roles") }}
						<span v-if="feedback.assigned.roles.includes(UserRole.DEVELOPER)">
							{{ $t("enums.userRole.DEVELOPER") }}
						</span>
						<span v-if="feedback.assigned.roles.includes(UserRole.ADMIN)">
							{{ $t("enums.userRole.ADMIN") }}
						</span>
					</p>
				</div>
			</div>
			<b-button
				@click="save"
				:disabled="loading"
				variant="primary"
				class="me-2"
			>
				{{ $t("admin.feedback.feedback.detail.save") }}
			</b-button>
			<b-button
				@click="deleteFeedback"
				:disabled="loading"
				variant="outline-danger"
				class="me-2"
			>
				{{ $t("admin.feedback.feedback.detail.delete") }}
			</b-button>
			<b-button
				@click.stop="assignToMe"
				:disabled="
					(feedback.assigned &&
						feedback.assigned.id === $store.getters.userId) ||
					loading
				"
				variant="outline-secondary"
			>
				{{ $t("admin.feedback.feedback.detail.assign") }}
			</b-button>
		</div>
	</div>
</template>

<script>
import CountryFlag from "@/components/countryFlag.vue";
import ProfilePicture from "@/components/ProfilePicture.vue";
import { api } from "@/lib/api";
import { UserRole } from "@/enums/user.roles";
import {
	FeedbackPriority,
	FeedbackStatus,
	FeedbackType,
} from "@/enums/feedback.enum";
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "AdminFeedbackDetail",
	components: {
		ErrorMessage,
		ProfilePicture,
		CountryFlag,
	},

	data() {
		return {
			id: this.$route.params.id,
			error: null,
			loading: false,
			resolvedVersion: null,
			feedback: {},
		};
	},

	methods: {
		getFeedback() {
			api("feedback/" + this.id, "GET", true)
				.then(data => {
					this.feedback = data;
					this.resolvedVersion = this.version(data.resolvedVersion);
				})
				.catch(err => {
					this.error = err;
				});
		},
		version(fbv) {
			if (!fbv) return null;
			return fbv.match(/^[0-9]+\.[0-9]+\.[0-9]+/)[0];
		},
		visitReviewer() {
			this.$router.push({
				name: "UserProfile",
				params: { id: `@${this.feedback.assigned.username}` },
			});
		},
		save() {
			this.loading = true;
			if (this.resolvedVersion === null || this.resolvedVersion.length <= 0)
				this.feedback.resolvedVersion = null;
			else this.feedback.resolvedVersion = `${this.resolvedVersion}`;

			api("feedback/" + this.id, "PATCH", true, {
				type: this.feedback.type,
				priority: this.feedback.priority,
				status: this.feedback.status,
				resolvedVersion: this.feedback.resolvedVersion,
			})
				.then(() => {
					this.getFeedback();
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		deleteFeedback() {
			this.loading = true;
			api("feedback/" + this.id, "DELETE", true)
				.then(() => {
					this.$router.push({ name: "AdminFeedbackOverview" });
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async assignToMe() {
			this.loading = true;
			api(
				`feedback/${this.id}/assign/${this.$store.getters.userId}`,
				"PATCH",
				true
			)
				.then(data => {
					this.feedback = data;
					this.resolvedVersion = this.version(data.resolvedVersion);
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		FeedbackStatus() {
			return FeedbackStatus;
		},
		FeedbackPriority() {
			return FeedbackPriority;
		},
		FeedbackType() {
			return FeedbackType;
		},
		UserRole() {
			return UserRole;
		},
	},

	watch: {
		id() {
			this.getMyFeedback();
		},

		"feedback.status"() {
			if (this.feedback.status !== FeedbackStatus.RESOLVED) return;
			this.resolvedVersion = this.version(this.$store.state.currentVersion);
		},
	},

	created() {
		this.getFeedback();
	},
};
</script>

<style scoped lang="scss">
.profile-picture {
	height: 100px;
	border: 3px #eaeaea solid;
}

.reviewer {
	width: fit-content;
}
</style>

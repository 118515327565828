<template>
	<div
		class="tag p-3 d-flex border rounded gap-3 align-items-center"
		@click="$emit('click')"
	>
		<div>
			<p class="mb-0">{{ tag.name }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchTag",
	props: {
		tag: {
			type: Object,
			default: () => ({
				id: "",
				name: "",
			}),
		},
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="mail">
		<card>
			<h1 class="mb-3">{{ $t("admin.mail.title") }}</h1>
			<error-message :error="error" />

			<b-form-input
				v-model="emailData.subject"
				type="text"
				:placeholder="$t('admin.mail.subject')"
				class="mb-3"
			/>
			<b-form-select
				:options="groups"
				class="form-select"
				v-model="emailData.group"
			>
				<template #first>
					<b-form-select-option value="NONE" disabled>
						{{ $t("admin.mail.receiverGroup") }}
					</b-form-select-option>
				</template>
			</b-form-select>

			<div class="message-box my-5">
				<p class="mb-3">{{ $t("admin.mail.greeting") }}</p>
				<b-form-textarea
					:placeholder="$t('admin.mail.message')"
					class="mb-0"
					rows="10"
					v-model="emailData.message"
				/>
				<div class="mb-1 text-muted">
					{{ $t("admin.mail.placeholder") }}
					<span
						v-for="placeholder of placeholders"
						:key="placeholder"
						class="fw-bold"
						>{{ placeholder }},
					</span>
				</div>
				<div class="mb-1 text-muted">
					{{ $t("admin.mail.quickLink") }}
					<span class="fw-bold">[L={url}]</span>
				</div>
				<div class="mb-1 text-muted">
					{{ $t("admin.mail.link") }}
					<span class="fw-bold">[{title}]({url})</span>
				</div>
				<div class="mb-1 text-muted">
					{{ $t("admin.mail.escape") }}
					<span class="fw-bold">\{character}</span>
				</div>
				<div class="mb-3 text-muted">
					{{ $t("admin.mail.combine") }}
				</div>
				<p class="mb-1">{{ $t("admin.mail.sender.0") }}</p>
				<b-form-input
					v-model="emailData.senderName"
					:disabled="!emailData.isIndividual"
					:placeholder="$t('admin.mail.sender.2')"
				/>
			</div>

			<div class="form-check mb-3">
				<input
					class="form-check-input"
					type="checkbox"
					id="personal-message"
					v-model="emailData.isIndividual"
				/>
				<label class="form-check-label" for="personal-message">
					{{ $t("admin.mail.personalMail") }}
				</label>
			</div>
			<b-button
				:variant="buttonVariant"
				:disabled="buttonDisabled"
				@click="sendMail"
				>{{ $t("admin.mail.send") }}</b-button
			>
		</card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import { api } from "@/lib/api";
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "AdminMail",
	components: { ErrorMessage, Card },
	data() {
		return {
			error: null,
			groups: [],
			placeholders: [],
			emailData: {
				subject: null,
				group: "NONE",
				message: null,
				senderName: this.$t("admin.mail.sender.1"),
				isIndividual: false,
			},
			buttonDisabled: false,
			buttonVariant: "primary",
		};
	},

	methods: {
		getGroups() {
			api("mail/groups", "GET", true)
				.then(data => {
					this.groups = data;
				})
				.catch(this.$error);
		},

		getPlaceholders() {
			api("mail/placeholders", "GET", true)
				.then(data => {
					this.placeholders = data;
				})
				.catch(this.$error);
		},

		sendMail() {
			this.buttonDisabled = true;
			this.buttonVariant = "primary";
			this.error = null;
			api("mail/send", "POST", true, this.emailData)
				.then(() => {
					this.emailData = {
						subject: null,
						group: "NONE",
						senderName: null,
						isIndividual: false,
					};
					this.buttonVariant = "primary";
					this.$toast.success(this.$t("admin.mail.successfulSent").toString(), {
						position: "top-right",
					});
				})
				.catch(err => {
					this.error = err;
					this.buttonVariant = "outline-danger";
				})
				.finally(() => {
					this.buttonDisabled = false;
				});
		},
	},

	watch: {
		"emailData.isIndividual"() {
			if (!this.emailData.isIndividual)
				this.emailData.senderName = this.$t("admin.mail.sender.1");
			else this.emailData.senderName = this.$store.state.user.name;
		},
	},

	created() {
		this.getPlaceholders();
		this.getGroups();
	},
};
</script>

<style scoped lang="scss"></style>

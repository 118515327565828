<template>
	<b-badge :class="color">{{ $t(`enums.feedbackStatus.${status}`) }}</b-badge>
</template>

<script>
import { FeedbackStatus } from "@/enums/feedback.enum";

export default {
	name: "feedbackStatusBadge",
	props: {
		status: {
			type: String,
			default: "RECEIVED",
		},
	},

	computed: {
		color() {
			switch (this.status) {
				case FeedbackStatus.RECEIVED:
					return "bg-secondary";
				case FeedbackStatus.REVIEWED:
					return "bg-info";
				case FeedbackStatus.PENDING:
					return "bg-warning";
				case FeedbackStatus.IN_WORK:
					return "bg-primary";
				case FeedbackStatus.RESOLVED:
					return "bg-success";
				case FeedbackStatus.REJECTED:
					return "bg-danger";
				default:
					return "bg-secondary";
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="my-feedback">
		<b-container class="mt-3">
			<div
				class="feedback border rounded-2 p-3 cursor-pointer d-flex align-items-center gap-2 mb-3"
				v-for="fb of feedback"
				:key="fb.id"
				@click="feedbackDetail(fb.id)"
			>
				<div class="flex-grow-1">
					<h4>
						{{ fb.title
						}}<span class="text-muted" v-if="fb.feature && fb.feature.name">
							&nbsp;-&nbsp;{{ fb.feature.name }}
						</span>
					</h4>
					<p class="text-muted fst-italic mb-0">
						{{
							$t("feedback.myFeedback.version", {
								version: fb.version,
							})
						}}
					</p>
					<p class="text-muted fst-italic mb-0" v-if="fb.resolvedVersion">
						<span class="fw-bold">{{
							$t("feedback.myFeedback.resolvedIn")
						}}</span>
						{{
							$t("feedback.myFeedback.version", {
								version: fb.resolvedVersion,
							})
						}}
					</p>
				</div>
				<div class="d-flex flex-column gap-2">
					<feedback-type-badge :type="fb.type" />
					<feedback-priority-badge :priority="fb.priority" />
					<feedback-status-badge :status="fb.status" />
				</div>
				<b-icon icon="chevron-right" />
			</div>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import FeedbackPriorityBadge from "@/components/feedbackPriorityBadge.vue";
import FeedbackStatusBadge from "@/components/feedbackStatusBadge.vue";
import FeedbackTypeBadge from "@/components/feedbackTypeBadge.vue";

export default {
	name: "MyFeedback",
	components: { FeedbackTypeBadge, FeedbackStatusBadge, FeedbackPriorityBadge },
	data() {
		return {
			feedback: [],
		};
	},

	methods: {
		getMyFeedback() {
			api("feedback/my", "GET", true).then(data => {
				this.feedback = data;
			});
		},
		version(fbv) {
			return fbv.match(/^[0-9]+\.[0-9]+\.[0-9]+/)[0];
		},
		feedbackDetail(id) {
			this.$router.push({ name: "MyFeedbackDetail", params: { id: id } });
		},
	},

	created() {
		this.getMyFeedback();
	},
};
</script>

<style scoped lang="scss"></style>

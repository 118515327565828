<template>
	<b-container class="mt-4 mb-3">
		<b-input
			v-model="query"
			debounce="250"
			:placeholder="$t('search.inputPlaceholder')"
		/>

		<div v-if="loading && isResultsEmpty" class="text-center mt-3">
			<p>{{ $t("search.loading") }}</p>
		</div>

		<div v-else-if="isResultsEmpty && !isQueryEmpty" class="text-center mt-3">
			<p>
				{{ $t("search.emptyResult", { query: query }) }}
			</p>
		</div>

		<div class="results mt-4" v-else-if="!isQueryEmpty">
			<div
				class="users-container"
				v-if="results.users && results.users.length > 0"
			>
				<h2>{{ $t("search.users") }}</h2>
				<div class="users">
					<search-user
						@click="toUser(user.id)"
						v-for="user of results.users"
						:key="user.id"
						:user="user"
						class="mt-3 cursor-pointer"
					/>
				</div>
			</div>

			<div
				class="books-container mt-5"
				v-if="results.books && results.books.length > 0"
			>
				<h2>{{ $t("search.books") }}</h2>
				<div class="books">
					<search-book
						@click="toBook(book.id)"
						v-for="book of results.books"
						:key="book.id"
						:book="book"
						class="mt-3 cursor-pointer"
					/>
				</div>
			</div>

			<div
				class="tags-container mt-5"
				v-if="results.tags && results.tags.length > 0"
			>
				<h2>{{ $t("search.tags") }}</h2>
				<div class="tags">
					<search-tag
						@click="toTag(tag.id)"
						v-for="tag of results.tags"
						:key="tag.id"
						:tag="tag"
						class="mt-3 cursor-pointer"
					/>
				</div>
			</div>

			<div
				class="genres-container mt-5"
				v-if="results.genres && results.genres.length > 0"
			>
				<h2>{{ $t("search.genres") }}</h2>
				<div class="genres">
					<search-genre
						@click="toGenre(genre.id)"
						v-for="genre of results.genres"
						:key="genre.id"
						:genre="genre"
						class="mt-3 cursor-pointer"
					/>
				</div>
			</div>
		</div>

		<div class="text-center mt-3" v-else>
			<p>{{ $t("search.emptyQuery") }}</p>
		</div>
	</b-container>
</template>

<script>
import { api } from "@/lib/api";
import SearchUser from "@/components/search/SearchUser.vue";
import SearchBook from "@/components/search/SearchBook.vue";
import SearchTag from "@/components/search/SearchTag.vue";
import SearchGenre from "@/components/search/SearchGenre.vue";

export default {
	name: "SearchPage",
	components: { SearchGenre, SearchTag, SearchBook, SearchUser },
	data() {
		return {
			query: this.$route.query.q || "",
			loading: false,
			error: null,
			results: {},
		};
	},

	methods: {
		search() {
			if (this.isQueryEmpty) return;

			this.loading = true;
			this.error = null;

			api(`search?q=${this.query}`, "GET")
				.then(data => {
					this.results = data;
					this.$log(data);
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		toUser(id) {
			this.$router
				.push({ name: "UserProfile", params: { id: id } })
				.catch(() => {});
		},

		toBook(id) {
			this.$router
				.push({ name: "BookDetails", params: { id: id } })
				.catch(() => {});
		},

		toTag(id) {
			this.$router
				.push({ name: "TagDetails", params: { id: id } })
				.catch(() => {});
		},

		toGenre(id) {
			this.$router
				.push({ name: "GenreDetails", params: { id: id } })
				.catch(() => {});
		},
	},

	computed: {
		isQueryEmpty() {
			if (this.query === null || this.query === undefined) return true;
			if (/^\s*$/gm.test(this.query)) return true;
			return false;
		},

		isResultsEmpty() {
			if (this.results.books && this.results.books.length > 0) return false;
			if (this.results.users && this.results.users.length > 0) return false;
			if (this.results.tags && this.results.tags.length > 0) return false;
			if (this.results.genres && this.results.genres.length > 0) return false;
			return true;
		},
	},

	watch: {
		query() {
			this.search();
			if (this.isQueryEmpty) this.results = {};
		},
	},

	created() {
		this.search();
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="new-book">
		<b-row class="container my-3 mx-auto">
			<b-col cols="12" md="3" class="mb-3 mb-md-0">
				<book-cover :src="newBook.coverPath" />
			</b-col>
			<b-col cols="12" md="9">
				<b-alert show v-if="error">{{ error.message }}</b-alert>
				<b-form v-on:submit.prevent="saveBook">
					<b-form-input
						class="mb-2"
						v-model="newBook.title"
						:placeholder="$t('author.new.bookTitle')"
					/>
					<b-form-select
						class="form-select mb-2"
						v-model="newBook.language"
						:options="languageOptions"
					/>
					<b-form-group
						class="mb-2"
						:description="$t('author.new.coverpath.1')"
					>
						<b-form-input
							type="text"
							v-model="coverPath"
							:placeholder="$t('author.new.coverpath.0')"
							@change="loadCover"
						/>
					</b-form-group>
					<wysiwyg-editor
						v-model="newBook.description"
						class="mb-2"
						:placeholder="$t('author.new.description.placeholder')"
					/>
					<div class="mb-2">
						<vue-select
							:disabled="loadingGenres"
							:options="genres"
							:getOptionKey="option => option.name"
							label="name"
							taggable
							v-model="newBook.genre"
							v-on:option:selecting="saveNewGenre"
							placeholder="Genre"
						/>
					</div>
					<div class="mb-2">
						<vue-select
							:disabled="loadingTags"
							multiple
							:options="tags"
							:getOptionKey="option => option.name"
							label="name"
							taggable
							v-model="newBook.tags"
							v-on:option:selecting="saveNewTag"
							placeholder="Tags"
						/>
					</div>
					<div class="form-check form-switch mb-2">
						<label for="continuous">{{ $t("author.new.continuous") }}</label>
						<input
							type="checkbox"
							class="form-check-input"
							v-model="newBook.continuous"
							id="continuous"
						/>
					</div>
					<div class="form-check form-switch mb-2">
						<label for="public">{{ $t("author.new.public") }}</label>
						<input
							type="checkbox"
							class="form-check-input"
							v-model="newBook.public"
							id="public"
						/>
					</div>
					<b-button
						:disabled="loading || loadingTags"
						variant="primary"
						type="submit"
						>{{ $t("author.new.save") }}
					</b-button>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Language } from "@/enums/language.enum";
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";

export default {
	name: "NewBook",
	components: { BookCover, WysiwygEditor },
	data() {
		return {
			Language: Language,
			newBook: {
				title: null,
				language: Language.GERMAN,
				coverPath: null,
				description: this.$t("author.new.description.default"),
				genre: null,
				tags: [],
				continuous: true,
				public: false,
			},
			languageOptions: [
				{ value: Language.GERMAN, text: "Deutsch" },
				{ value: Language.ENGLISH, text: "English" },
				{ value: Language.FRENCH, text: "Français" },
				{ value: Language.JAPANESE, text: "日本語" },
			],
			tags: [],
			genres: [],
			loading: false,
			loadingTags: true,
			loadingGenres: true,
			error: null,
			coverPath: null,
		};
	},

	methods: {
		saveBook: function () {
			if (this.loadingTags) return;
			if (this.loadingGenres) return;

			this.loading = true;
			this.error = null;
			this.loadCover();
			api("author/books", "POST", true, {
				...this.newBook,
				tags: this.newBook.tags.map(t => ({ id: t.id })),
				genre:
					this.newBook.genre && this.newBook.genre.id
						? { id: this.newBook.genre.id }
						: null,
			})
				.then(data => {
					this.loading = false;
					this.$router.push({ name: "EditBook", params: { id: data.id } });
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
				});
		},

		loadCover() {
			this.newBook.coverPath = String(this.coverPath);
		},

		getTags() {
			this.loadingTags = true;
			api("tags", "GET")
				.then(data => {
					this.tags = data;
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},

		getGenres() {
			this.loadingGenres = true;
			api("genres", "GET")
				.then(data => {
					this.genres = data;
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewGenre(genre) {
			this.$log(genre);
			if (genre.id) return;

			const genreIndex = this.genres.findIndex(t => t.name === genre.name);
			if (genreIndex > -1) {
				this.$set(this.newBook, "genre", this.genres[genreIndex]);
				return this.$log(this.newBook.genre);
			}

			this.loadingGenres = true;
			api("genres", "POST", true, {
				name: typeof genre === "object" && genre.name ? genre.name : genre,
			})
				.then(data => {
					this.$set(this.newBook, "genre", data);
					this.genres.push(data);
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewTag(tag) {
			if (tag.id) return;

			const tagIndex = this.tags.findIndex(t => t.name === tag.name);
			if (tagIndex > -1) {
				this.$set(
					this.newBook.tags,
					this.newBook.tags.findIndex(t => t.name === tag.name),
					this.tags[tagIndex]
				);
				return this.$log(this.newBook.tags);
			}

			this.loadingTags = true;
			api("tags", "POST", true, {
				name: typeof tag === "object" && tag.name ? tag.name : tag,
			})
				.then(data => {
					this.$set(
						this.newBook.tags,
						this.newBook.tags.findIndex(t => t.name === tag.name),
						data
					);
					this.tags.push(data);
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},
	},

	computed: {
		coverPathValid() {
			if (!this.newBook.coverPath) return null;
			else
				return !!(
					this.newBook.coverPath.match(this.$store.state.websiteRegex) &&
					this.newBook.coverPath.match(this.$store.state.websiteRegex)[0] ===
						this.newBook.coverPath
				);
		},
	},

	created() {
		this.getTags();
		this.getGenres();
	},
};
</script>

<style scoped lang="scss"></style>

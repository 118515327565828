<template>
	<div class="author-badge">
		<b-icon icon="book" />
		<span class="d-none d-sm-inline">&nbsp;{{ $t("authorBadge.label") }}</span>
	</div>
</template>

<script>
export default {
	name: "authorBadge",
};
</script>

<style scoped lang="scss">
$color: #91a223;

.author-badge {
	padding: 5px;
	color: $color;
	border: 1px solid $color;
	width: fit-content;
	height: fit-content;
	border-radius: 999999999px;
}
</style>

<template>
	<div class="edit-chapter my-3" v-if="!initialLoad">
		<b-container>
			<error-message :error="error"></error-message>
			<b-form v-on:submit.prevent="updateChapter">
				<b-form-group
					class="mb-2"
					:description="
						$t('author.edit.chapterDetail.chapterTitle.notice', {
							name: chapter.name,
						})
					"
				>
					<b-form-input
						:disabled="loading"
						v-model="chapter.name"
						:placeholder="
							$t('author.edit.chapterDetail.chapterTitle.placeholder')
						"
					/>
				</b-form-group>
				<b-form-textarea
					:disabled="loading"
					class="mb-4"
					v-model="chapter.summary"
					rows="5"
					:placeholder="$t('author.edit.chapterDetail.summary')"
				/>
				<wysiwyg-editor
					class="mb-4"
					v-model="chapter.text"
					:disabled="loading"
				/>
				<div class="form-check form-switch mb-2">
					<label for="public">{{
						$t("author.edit.chapterDetail.public")
					}}</label>
					<input
						:disabled="loading"
						type="checkbox"
						class="form-check-input"
						v-model="chapter.public"
						id="public"
					/>
				</div>
				<b-button variant="primary" @click="updateChapter" :disabled="loading">
					<Transition mode="out-in" name="fade-in">
						<p class="d-inline-block mb-0" :key="savedText">
							{{ savedText }}
						</p>
					</Transition>
				</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import ErrorMessage from "@/components/errorMessage.vue";
import { api } from "@/lib/api";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";

export default {
	name: "EditChapter",
	components: { WysiwygEditor, ErrorMessage },
	data() {
		return {
			error: null,
			initialLoad: true,
			loading: false,
			bookId: this.$route.params.bookId,
			chapterId: this.$route.params.chapterId,
			chapter: {},
			savedText: this.$t("author.edit.chapterDetail.save.save"),
			timeout: null,
			localeWatcher: null,
		};
	},

	methods: {
		getChapter() {
			api(`author/books/${this.bookId}/chapters/${this.chapterId}`, "GET", true)
				.then(data => {
					this.chapter = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.initialLoad = false;
				});
		},

		updateChapter() {
			if (this.loading) return;

			this.loading = true;
			const body = {
				name: this.chapter.name,
				text: this.chapter.text,
				summary: this.chapter.summary,
				public: this.chapter.public,
			};

			api(
				`author/books/${this.bookId}/chapters/${this.chapterId}`,
				"PATCH",
				true,
				body
			)
				.then(data => {
					this.chapter = data;
					this.savedText = this.$t("author.edit.chapterDetail.save.saved");
					this.timeout = setTimeout(() => {
						this.savedText = this.$t("author.edit.chapterDetail.save.save");
					}, 2500);
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	created() {
		this.$watch(
			() => this.$route.params,
			toParams => {
				this.bookId = toParams.bookId;
				this.chapterId = toParams.chapterId;
				this.getChapter();
			}
		);

		this.getChapter();
		this.localeWatcher = this.$watch(this.$i18n.locale, () => {
			this.savedText = this.$t("author.edit.chapterDetail.save.save");
		});
	},

	beforeDestroy() {
		clearTimeout(this.timeout);
		this.localeWatcher();
	},
};
</script>

<style scoped lang="scss">
.fade-in-enter-active {
	transition: opacity 300ms ease-in-out;
}

.fade-in-leave-active {
	transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
}
</style>

<template>
	<div
		class="book p-3 d-flex border rounded gap-3 align-items-center"
		@click="$emit('click')"
	>
		<book-cover :src="book.coverPath" style="height: 100px" />
		<div>
			<p class="mb-0">{{ book.title }}</p>
			<p class="mb-0 text-muted">
				{{ book.author.name }}
				<span class="fst-italic">@{{ book.author.username }}</span>
			</p>
		</div>
	</div>
</template>

<script>
import BookCover from "@/components/bookCover.vue";

export default {
	name: "SearchBook",
	components: { BookCover },
	props: {
		book: {
			type: Object,
			default: () => ({
				author: {
					id: "",
					name: "",
					username: "",
				},
				continuous: false,
				coverPath: null,
				featured: false,
				language: "de",
				public: true,
				title: "",
			}),
		},
	},
};
</script>

<style scoped lang="scss"></style>

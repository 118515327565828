<template>
	<div class="my-feedback-detail">
		<b-container class="mt-3" v-if="feedback.id">
			<h3>{{ feedback.title }}</h3>
			<p class="border rounded-2 p-3 mb-4">{{ feedback.feedbackText }}</p>

			<h5>{{ $t("feedback.detail.status.title") }}</h5>
			<p class="mb-0">
				{{ $t("feedback.detail.status.type") }}
				<feedback-type-badge :type="feedback.type" />
			</p>
			<p class="mb-0">
				{{ $t("feedback.detail.status.priority") }}
				<feedback-priority-badge :priority="feedback.priority" />
			</p>
			<p class="mb-4">
				{{ $t("feedback.detail.status.status") }}
				<feedback-status-badge :status="feedback.status" />
			</p>

			<h5>{{ $t("feedback.detail.versions.title") }}</h5>
			<p class="mb-0">
				{{
					$t("feedback.detail.versions.reported", {
						version: feedback.version,
					})
				}}
			</p>
			<p class="mb-4" v-if="feedback.resolvedVersion">
				{{
					$t("feedback.detail.versions.resolved", {
						version: feedback.resolvedVersion,
					})
				}}
			</p>
			<p class="mb-4" v-else>{{ $t("feedback.detail.versions.resolvedNA") }}</p>

			<h5>{{ $t("feedback.detail.feature.title") }}</h5>
			<p class="mb-4">
				{{
					$t("feedback.detail.feature.name", { name: feedback.feature.name })
				}}
			</p>

			<h5>{{ $t("feedback.detail.reviewer.title") }}</h5>
			<p class="fst-italic text-muted" v-if="!feedback.assigned">
				{{ $t("feedback.detail.reviewer.notAssigned") }}
			</p>
			<div
				class="d-flex gap-4 cursor-pointer reviewer"
				@click="visitReviewer"
				v-if="feedback.assigned"
			>
				<profile-picture
					:profile-picture="feedback.assigned.profilePicture"
					:name="feedback.assigned.name"
				/>
				<div class="d-flex flex-column justify-content-center">
					<p class="mb-0">
						{{ feedback.assigned.name }}
						<span class="text-muted">@{{ feedback.assigned.username }}</span>
					</p>
					<p class="mb-0">
						{{ $t("feedback.detail.reviewer.language") }}
						<country-flag :country="feedback.assigned.language" />
					</p>
					<p class="mb-0">
						{{ $t("feedback.detail.reviewer.roles") }}
						<span v-if="feedback.assigned.roles.includes(UserRole.DEVELOPER)">
							{{ $t("enums.userRole.DEVELOPER") }}
						</span>
						<span v-if="feedback.assigned.roles.includes(UserRole.ADMIN)">
							{{ $t("enums.userRole.ADMIN") }}
						</span>
					</p>
				</div>
			</div>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import FeedbackPriorityBadge from "@/components/feedbackPriorityBadge.vue";
import FeedbackStatusBadge from "@/components/feedbackStatusBadge.vue";
import FeedbackTypeBadge from "@/components/feedbackTypeBadge.vue";
import ProfilePicture from "@/components/ProfilePicture.vue";
import CountryFlag from "@/components/countryFlag.vue";
import { UserRole } from "@/enums/user.roles";

export default {
	name: "MyFeedbackDetail",
	components: {
		CountryFlag,
		ProfilePicture,
		FeedbackTypeBadge,
		FeedbackStatusBadge,
		FeedbackPriorityBadge,
	},
	data() {
		return {
			id: this.$route.params.id,
			feedback: {},
		};
	},

	methods: {
		getMyFeedback() {
			api("feedback/my/" + this.id, "GET", true).then(data => {
				this.feedback = data;
			});
		},
		version(fbv) {
			return fbv.match(/^[0-9]+\.[0-9]+\.[0-9]+/)[0];
		},
		visitReviewer() {
			this.$router.push({
				name: "UserProfile",
				params: { id: `@${this.feedback.assigned.username}` },
			});
		},
	},

	computed: {
		UserRole() {
			return UserRole;
		},
	},

	watch: {
		id() {
			this.getMyFeedback();
		},
	},

	created() {
		this.getMyFeedback();
	},
};
</script>

<style scoped lang="scss">
.profile-picture {
	height: 100px;
	border: 3px #eaeaea solid;
}
</style>

import { render, staticRenderFns } from "./SecurityCenter.vue?vue&type=template&id=4ea08c3d&scoped=true&"
import script from "./SecurityCenter.vue?vue&type=script&lang=js&"
export * from "./SecurityCenter.vue?vue&type=script&lang=js&"
import style0 from "./SecurityCenter.vue?vue&type=style&index=0&id=4ea08c3d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea08c3d",
  null
  
)

export default component.exports
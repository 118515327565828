<template>
	<div class="feedback-features pt-3">
		<error-message :error="error" />
		<p
			v-if="features.length <= 0 && !initialLoading"
			class="text-muted fst-italic"
		>
			{{ $t("admin.feedback.feature.overview.noFeature") }}
		</p>
		<div class="d-flex justify-content-end">
			<b-button
				variant="outline-secondary"
				@click="showNewFeature = !showNewFeature"
				:disabled="loading"
			>
				<b-icon icon="plus-circle" />
				{{ $t("admin.feedback.feature.overview.newFeature") }}
			</b-button>
		</div>
		<div class="features">
			<div
				class="feedback-feature border rounded-2 p-3 cursor-pointer d-flex align-items-center gap-2 my-3"
				v-for="feature of features"
				:key="feature.id"
				@click="featureDetail(feature.id)"
			>
				<div class="flex-grow-1">
					<h4 class="mb-0">{{ feature.name }}</h4>
					<p class="text-muted fst-italic mb-0">
						{{
							$tc(
								"admin.feedback.feature.overview.openFeedbackCount",
								feature.openFeedbackCount
							)
						}}
					</p>
				</div>
				<b-icon icon="chevron-right" />
			</div>
		</div>

		<b-modal
			v-model="showNewFeature"
			hide-header-close
			:no-close-on-backdrop="loading"
			:no-close-on-esc="loading"
			:title="$t('admin.feedback.feature.overview.newModal.title')"
		>
			<template>
				<b-form @submit="createNewFeature">
					<b-input
						:placeholder="
							$t('admin.feedback.feature.overview.newModal.namePlaceholder')
						"
						v-model="featureName"
					/>
				</b-form>
			</template>

			<template #modal-footer>
				<b-button
					variant="outline-secondary"
					@click="abortNew"
					:disabled="loading"
				>
					{{ $t("admin.feedback.feature.overview.newModal.cancel") }}
				</b-button>
				<b-button
					variant="primary"
					@click="createNewFeature"
					:disabled="loading"
				>
					{{ $t("admin.feedback.feature.overview.newModal.add") }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import ErrorMessage from "@/components/errorMessage.vue";
import { api } from "@/lib/api";

export default {
	name: "AdminFeedbackFeature",
	components: { ErrorMessage },
	data() {
		return {
			error: null,
			features: [],
			featureName: null,
			loading: false,
			initialLoading: true,
			showNewFeature: false,
		};
	},

	methods: {
		getFeatures() {
			api("feedback/features", "GET", true)
				.then(data => {
					this.features = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.initialLoading = false;
				});
		},

		featureDetail(id) {
			this.$router.push({
				name: "AdminFeedbackFeatureDetail",
				params: { id: id },
			});
		},

		abortNew() {
			this.featureName = null;
			this.showNewFeature = false;
		},

		createNewFeature() {
			if (this.featureName === null || this.featureName.length <= 0) return;
			this.loading = true;
			api("feedback/features", "POST", true, {
				name: this.featureName,
			})
				.then(() => {
					this.getFeatures();
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.showNewFeature = false;
					this.loading = false;
				});
		},
	},

	created() {
		this.getFeatures();
	},
};
</script>

<style scoped lang="scss"></style>

import { render, staticRenderFns } from "./EditBook.vue?vue&type=template&id=a7d9313c&scoped=true&"
import script from "./EditBook.vue?vue&type=script&lang=js&"
export * from "./EditBook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7d9313c",
  null
  
)

export default component.exports
<template>
	<div class="book-loading">
		<div class="d-flex align-items-center">
			<b-skeleton class="me-3 shadow cover" />
			<div class="w-100">
				<b-skeleton width="50%" />
				<b-skeleton width="60%" />
			</div>
		</div>
		<div>
			<b-skeleton type="button" class="mb-1" />
			<b-skeleton type="button" />
		</div>
	</div>
</template>

<script>
export default {
	name: "listBookSkeleton",
};
</script>

<style scoped lang="scss">
.book-loading {
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.cover {
		height: 100px;
		width: 70px;
	}

	& > div:nth-child(1) {
		min-width: 400px;
	}
}
</style>

import { accessTokenPayload, isAccessTokenValid } from "@/lib/accessToken";
import { UserRole } from "@/enums/user.roles";
import localStorage from "@/lib/localStorage";

const routeMap = {
	Home: "Home",
	BookDetails: "Buch",
	BookRead: "Lesen",
	EditProfile: {
		name: "Profil bearbeiten",
		protected: true,
	},
	SecurityCenter: {
		name: "Security Center",
		protected: true,
	},
	UserProfile: "Nutzerprofil",
	JSONViewer: "JSON Viewer",
	Login: "Login",
	ChangePassword: {
		name: "Change Password",
		protected: true,
	},
	RequestPasswordReset: "Anfrage zum Password-Zurücksetzen",
	ResetPassword: "Passwort zurücksetzen",
	Reactivate: "Reaktivierung",
	TagList: "Tag Liste",
	TagOverview: "Tag Übersicht",
	TagRanking: "Tag Ranking",
	AdminHome: {
		name: "Home - Admin",
		protected: true,
		roles: [UserRole.ADMIN],
	},
	AdminUsers: {
		name: "Nutzerübersicht - Admin",
		protected: true,
		roles: [UserRole.ADMIN],
	},
	AdminUsersDetail: {
		name: "Nutzerdetails - Admin",
		protected: true,
		roles: [UserRole.ADMIN],
	},
	AdminBooks: {
		name: "Buchübersicht - Admin",
		protected: true,
		roles: [UserRole.ADMIN],
	},
	AdminSettings: {
		name: "Einstellungen - Admin",
		protected: true,
		roles: [UserRole.ADMIN],
	},
	NewBook: {
		name: "Neues Buch",
		protected: true,
		roles: [UserRole.AUTHOR, UserRole.ADMIN],
	},
	MyBooks: {
		name: "Meine Bücher",
		protected: true,
		roles: [UserRole.AUTHOR, UserRole.ADMIN],
	},
	NotFound: "404 Seite nicht gefunden",
};

function pageTitleRouterHandler(to, from, next) {
	if (typeof routeMap[to.name] === "object")
		document.title = `${routeMap[to.name].name} | ${
			process.env.VUE_APP_BASE_TITLE
		}`;
	else if (routeMap[to.name])
		document.title = `${routeMap[to.name]} | ${process.env.VUE_APP_BASE_TITLE}`;
	else document.title = `${to.name} | ${process.env.VUE_APP_BASE_TITLE}`;
	next();
}

function protectedRouterHandler(to, from, next) {
	if (!isProtectedRoute(to.name)) return next();
	if (!localStorage.getItem("accessToken"))
		return next({ name: "Login", query: { to: to.path } });
	if (!isAccessTokenValid(localStorage.getItem("accessToken")))
		return next({ name: "Login", query: { to: to.path } });
	if (!canActivate(to)) return next({ name: "Home", query: { to: to.path } });
	next();
}

function canActivate(to) {
	if (typeof routeMap[to.name] !== "object") return false;
	if (!Object.hasOwn(routeMap[to.name], "roles")) return true;

	const payload = accessTokenPayload(localStorage.getItem("accessToken"));
	const routeRoles = routeMap[to.name].roles;

	for (const role of payload.roles) {
		if (routeRoles.includes(role)) return true;
	}

	return false;
}

function isProtectedRoute(routeName) {
	if (typeof routeMap[routeName] === "object")
		return routeMap[routeName].protected;
	else return false;
}

export {
	routeMap,
	pageTitleRouterHandler,
	isProtectedRoute,
	protectedRouterHandler,
};

<template>
	<div class="profile-picture">
		<b-img-lazy
			:src="this.finalSrc"
			draggable="false"
			alt="User profile picture"
			v-if="!loading && showImg"
		/>
		<b-skeleton-img height="200px" width="200px" v-else />
	</div>
</template>

<script>
export default {
	name: "ProfilePicture",
	props: {
		name: {
			type: String,
			default: "",
		},
		profilePicture: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			finalSrc: null,
			loading: true,
			showImg: false,
			preloadImg: null,
			fallbackIndex: 0,
			colors: [
				["#f90", "#f0f0f0"],
				["#890", "#f0f0f0"],
				["#064", "#f0f0f0"],
				["#660027", "#f0f0f0"],
				["#050086", "#f0f0f0"],
			],
		};
	},

	methods: {
		loadImg() {
			this.loading = true;
			this.showImg = false;
			this.preloadImg = new Image();
			this.preloadImg.addEventListener("load", this.loadSuccess);
			this.preloadImg.addEventListener("error", this.loadFailed);
			this.preloadImg.src = this.profilePicture || this.fallback;
		},
		loadSuccess(e) {
			this.loading = false;
			this.showImg = true;
			this.finalSrc = this.profilePicture || this.fallback;
			this.$emit("load", e);
		},
		loadFailed(data) {
			this.loading = false;
			this.showImg = false;
			this.$error(data);
		},

		initials: function () {
			let fragments = this.name.split(" ");
			let initials = "";
			for (let i = 0; i < fragments.length; i++) {
				if (i > 1) break;
				initials = initials + fragments[i].split("")[0];
			}
			return initials;
		},
	},

	computed: {
		fallback() {
			return `https://placehold.co/150/${this.colors[
				this.fallbackIndex
			][0].replace("#", "")}/${this.colors[this.fallbackIndex][1].replace(
				"#",
				""
			)}?text=${this.initials()}`;
		},
	},

	watch: {
		profilePicture() {
			this.loadImg();
		},
		fallbackText() {
			this.loadImg();
		},
		fallbackBgColor() {
			this.loadImg();
		},
		fallbackFgColor() {
			this.loadImg();
		},
	},

	created() {
		this.fallbackIndex = Math.floor(Math.random() * this.colors.length);
		if (this.profilePicture || this.fallback) {
			this.loadImg();
		}
	},

	beforeDestroy() {
		if (this.preloadImg) {
			this.preloadImg.removeEventListener("load", this.loadSuccess);
			this.preloadImg.removeEventListener("error", this.loadFailed);
		}
	},
};
</script>

<style lang="scss">
.profile-picture {
	box-sizing: border-box;
	aspect-ratio: 1/1 !important;
	height: auto;
	width: auto;
	border-radius: 50%;
	overflow: hidden;

	&:hover img {
		transform: scale(1.1);
	}

	img {
		width: 100%;
		transition: all 0.3s ease-in-out;
		aspect-ratio: 1 / 1 !important;
		object-position: center;
		object-fit: cover;
	}
}
</style>

<template>
	<div class="new-chapter my-3">
		<b-container>
			<error-message :error="error"></error-message>
			<b-form v-on:submit.prevent="saveChapter">
				<b-form-group
					class="mb-2"
					:description="
						$t('author.new.chapter.chapterTitle.notice', {
							name: chapter.name,
						})
					"
				>
					<b-form-input
						:disabled="loading"
						v-model="chapter.name"
						:placeholder="$t('author.new.chapter.chapterTitle.placeholder')"
					/>
				</b-form-group>
				<b-form-textarea
					:disabled="loading"
					class="mb-4"
					v-model="chapter.summary"
					rows="5"
					:placeholder="$t('author.new.chapter.summary')"
				/>
				<wysiwyg-editor
					class="mb-4"
					v-model="chapter.text"
					:disabled="loading"
				/>
				<div class="form-check form-switch mb-2">
					<label for="public">{{ $t("author.new.chapter.public") }}</label>
					<input
						:disabled="loading"
						type="checkbox"
						class="form-check-input"
						v-model="chapter.public"
						id="public"
					/>
				</div>
				<b-button variant="primary" @click="saveChapter" :disabled="loading">{{
					$t("author.new.chapter.save.save")
				}}</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import ErrorMessage from "@/components/errorMessage.vue";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";
import { api } from "@/lib/api";

export default {
	name: "NewChapter",
	components: { WysiwygEditor, ErrorMessage },
	data() {
		return {
			error: null,
			loading: false,
			chapter: {
				name: null,
				summary: null,
				text: "",
				public: true,
			},
		};
	},

	methods: {
		saveChapter() {
			this.loading = true;

			api(
				`author/books/${this.$route.params.id}/chapters`,
				"POST",
				true,
				this.chapter
			)
				.then(() => {
					this.$router.push({
						name: "EditBook",
						params: { id: this.$route.params.id },
					});
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped></style>

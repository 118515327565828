<template>
	<b-form-group :invalid-feedback="invalidFeedback" :state="passwordState">
		<b-form-input
			:state="passwordState"
			:placeholder="placeholder"
			:disabled="disabled"
			v-model="password"
			type="password"
			@input="handleInput"
		/>
	</b-form-group>
</template>

<script>
export default {
	name: "strongPassword",
	props: {
		placeholder: {
			default: "Password",
		},
		value: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		state: {
			type: Boolean,
			default: null,
		},
		feedback: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			password: this.value,
		};
	},

	methods: {
		handleInput() {
			this.$emit("input", this.password);
		},
	},

	computed: {
		invalidFeedback() {
			if (!this.password) return this.$t("strongPassword.length");

			if (this.password.length < 12) return this.$t("strongPassword.length");

			if (!this.$store.state.passwordRegex.lowerCase.test(this.password))
				return this.$t("strongPassword.lowerCase");

			if (!this.$store.state.passwordRegex.upperCase.test(this.password))
				return this.$t("strongPassword.upperCase");

			if (!this.$store.state.passwordRegex.numbers.test(this.password))
				return this.$t("strongPassword.number");

			if (!this.$store.state.passwordRegex.specialChars.test(this.password))
				return this.$t("strongPassword.specialChars");

			if (this.feedback && this.state !== null && this.state !== undefined)
				return this.feedback;

			return this.$t("strongPassword.unknownConstraint");
		},

		passwordState() {
			if (this.state !== null && this.state !== undefined)
				return (
					this.$store.state.passwordRegex.overall.test(this.password) &&
					this.state
				);

			return this.$store.state.passwordRegex.overall.test(this.password);
		},
	},
	watch: {
		passwordState() {
			this.$emit("spStateChange", this.passwordState);
		},
	},
};
</script>

<style scoped lang="scss"></style>

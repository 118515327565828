<template>
	<div
		class="chapter-preview border rounded p-3 bg-white cursor-pointer"
		@click="click()"
	>
		<h5 class="mb-0">
			{{ $t("chapterPreview.chapterPrepend", { chapterNo: i + 1 }) }}:
			{{ chapter.name }}
		</h5>
		<p class="mb-0 mt-3 text-muted" v-if="chapter.summary">
			{{ chapter.summary }}
		</p>
	</div>
</template>

<script>
export default {
	name: "chapterPreview",
	props: {
		chapter: {
			type: Object,
			required: true,
		},
		i: {
			type: Number,
			required: true,
		},
	},
	methods: {
		click: function () {
			if (this.chapter.public) {
				this.$emit("click");
			}
		},
	},
};
</script>

<style scoped></style>

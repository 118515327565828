<template>
	<div class="user-loading">
		<div class="d-flex">
			<b-skeleton type="avatar" class="me-3" />
			<div class="w-100">
				<b-skeleton width="75%" />
				<b-skeleton width="55%" />
			</div>
		</div>
		<div>
			<b-skeleton type="button" class="mb-1" />
			<b-skeleton type="button" />
		</div>
	</div>
</template>

<script>
export default {
	name: "listUserSkeleton",
};
</script>

<style scoped lang="scss">
.user-loading {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div:nth-child(1) {
		min-width: 400px;
	}
}
</style>

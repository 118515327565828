<template>
	<div class="users">
		<Card v-if="!initialLoad">
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.users.overview.title") }}</h1>
				<b-button
					size="sm"
					variant="outline-secondary"
					@click="showNewUserModal(true)"
					:disabled="loading"
				>
					<b-icon icon="plus-circle" />
					{{ $t("admin.users.overview.newUser") }}
				</b-button>
			</div>
			<list-user
				v-for="(user, i) of users"
				:user="user"
				:key="user.id"
				@details="toUser"
				@delete="deleteUser"
				:loading="loading"
				:class="users.length > 1 && i !== users.length - 1 ? 'mb-3' : ''"
			/>
			<p v-if="users.length <= 0" class="text-muted fst-italic text-center m-0">
				{{ $t("admin.users.overview.noUser") }}
			</p>
		</Card>

		<Card v-else>
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.users.overview.title") }}</h1>
				<b-skeleton type="button" />
			</div>
			<list-user-skeleton
				v-for="i in range(1, 10)"
				:key="'user-skeleton-' + i"
				class="mb-3"
			/>
		</Card>

		<b-modal v-model="showNewModal">
			<template #modal-header="{ close }">
				<h5 class="modal-title">{{ $t("admin.users.overview.new.title") }}</h5>
				<b-button variant="close" @click="close"></b-button>
			</template>

			<error-message :error="error" v-if="showNewModal" />

			<b-form-input
				v-model="newUser.name"
				class="mb-3"
				:placeholder="$t('admin.users.overview.new.name')"
				:disabled="loading"
			/>
			<b-form-input
				v-model="newUser.username"
				class="mb-3"
				:placeholder="$t('admin.users.overview.new.username')"
				:disabled="loading"
				:formatter="usernameFormatter"
				:state="usernameTaken && usernameValid"
				@change="checkUsername"
			/>
			<b-form-input
				v-model="newUser.email"
				class="mb-3"
				:placeholder="$t('admin.users.overview.new.email')"
				:disabled="loading"
				:formatter="emailFormatter"
				:state="emailTaken && emailValid"
				@change="checkMail"
			/>

			<vue-select
				:options="roleOptions"
				v-model="userRole"
				multiple
				:disabled="loading"
				class="mb-3"
			/>

			<vue-select
				:options="languageOptions"
				v-model="userLanguage"
				:disabled="loading"
				class="mb-3"
			/>

			<div class="form-check">
				<label for="newUser-sendWelcomeMail-checkbox">{{
					$t("admin.users.overview.new.sendWelcomeMail")
				}}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="newUser.sendWelcomeMail"
					id="newUser-sendWelcomeMail-checkbox"
					:disabled="loading"
				/>
			</div>

			<template #modal-footer>
				<b-button
					variant="secondary"
					@click="showNewUserModal(false)"
					:disabled="loading"
					>{{ $t("admin.users.overview.new.abort") }}
				</b-button>
				<b-button
					variant="primary"
					@click="saveNewUser"
					:disabled="loading || !buttonDisabled"
					>{{ $t("admin.users.overview.new.save") }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import ListUser from "@/components/listUser.vue";
import ListUserSkeleton from "@/components/listUserSkeleton.vue";

import { api } from "@/lib/api";
import { range } from "@/lib/range";
import { UserRole } from "@/enums/user.roles";
import { Language } from "@/enums/language.enum";
import VueSelect from "vue-select";
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "Users",
	components: {
		Card,
		ErrorMessage,
		ListUser,
		ListUserSkeleton,
		VueSelect,
	},
	data() {
		return {
			range,
			UserRole,
			Language,
			initialLoad: true,
			loading: false,
			error: null,

			users: [],

			showNewModal: false,
			newUser: {
				name: null,
				username: null,
				email: null,
				roles: [UserRole.USER],
				language: Language.GERMAN,
				sendWelcomeMail: true,
			},
			roleOptions: [
				{
					label: this.$t("admin.users.overview.new.role.0"),
					value: UserRole.USER,
				},
				{
					label: this.$t("admin.users.overview.new.role.1"),
					value: UserRole.NEWSLETTER,
				},
				{
					label: this.$t("admin.users.overview.new.role.2"),
					value: UserRole.AUTHOR,
				},
				{
					label: this.$t("admin.users.overview.new.role.3"),
					value: UserRole.DEVELOPER,
				},
				{
					label: this.$t("admin.users.overview.new.role.4"),
					value: UserRole.ADMIN,
				},
			],
			languageOptions: [
				{ label: "Deutsch", value: Language.GERMAN },
				{ label: "English", value: Language.ENGLISH },
				{ label: "Français", value: Language.FRENCH },
				{ label: "日本語", value: Language.JAPANESE },
			],
			userLanguage: { label: "Deutsch", value: Language.GERMAN },
			userRole: [
				{
					label: this.$t("admin.users.overview.new.role.0"),
					value: UserRole.USER,
				},
			],

			usernameTaken: false,
			emailTaken: false,
		};
	},

	methods: {
		getUsers: function () {
			api("admin/users", "GET")
				.then(data => {
					this.users = data;
					this.initialLoad = false;
				})
				.catch(() => {});
		},

		toUser: function (id) {
			this.$router.push({ name: "AdminUsersDetail", params: { id: id } });
		},

		showNewUserModal(show) {
			this.showNewModal = show;
			this.error = null;

			if (!show) {
				this.newUser = {
					name: null,
					username: null,
					email: null,
					roles: [
						{
							label: this.$t("admin.users.overview.new.role.0"),
							value: UserRole.USER,
						},
					],
					language: { label: "Deutsch", value: Language.GERMAN },
					sendWelcomeMail: true,
				};

				this.userLanguage = { label: "Deutsch", value: Language.GERMAN };
				this.userRole = [
					{
						label: this.$t("admin.users.overview.new.role.0"),
						value: UserRole.USER,
					},
				];
			}
		},

		saveNewUser() {
			if (!this.usernameValid && this.usernameValid !== null) return;
			if (!this.emailValid && this.emailValid !== null) return;

			if (this.usernameTaken !== null && !this.usernameTaken) return;
			if (this.emailTaken !== null && !this.emailTaken) return;

			this.loading = true;

			api("admin/users", "POST", true, { ...this.newUser })
				.then(() => {
					this.loading = false;
					this.showNewUserModal(false);
					this.getUsers();
				})
				.catch(err => {
					this.loading = false;
					this.error = err;
				});
		},

		deleteUser(id) {
			this.loading = true;

			api("admin/users/" + id, "DELETE", true)
				.then(data => {
					this.loading = false;
					this.users = data;
					this.$log(data);
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
				});
		},

		usernameFormatter: function (value) {
			value = value.replaceAll(" ", "_");
			value = value.replaceAll(this.$store.state.usernameRegex, "");
			return value.toLowerCase();
		},

		emailFormatter: function (value) {
			value = value.replace(" ", "");
			return value.toLowerCase();
		},

		checkUsername: function () {
			api("users/check/" + this.newUser.username, "GET")
				.then(data => {
					this.usernameTaken = !data.usernameExists;
				})
				.catch(() => {});
		},

		checkMail: function () {
			api("users/check/" + this.newUser.email, "GET")
				.then(data => {
					this.emailTaken = !data.usernameExists;
				})
				.catch(() => {});
		},
	},

	computed: {
		usernameValid() {
			if (!this.newUser.username) return false;
			else if (
				this.newUser.username.length < this.$store.state.usernameMinLength
			)
				return false;
			else return true;
		},

		emailValid() {
			if (!this.newUser.email) return false;
			else if (!this.newUser.email.match(this.$store.state.emailRegex))
				return false;
			else if (this.newUser.email.length <= 0) return false;
			else return true;
		},

		buttonDisabled() {
			return this.usernameValid && this.emailValid;
		},
	},

	watch: {
		userRole() {
			this.newUser.roles = this.userRole.map(role => role.value);
			this.$log(this.newUser.roles);
		},
		userLanguage() {
			this.newUser.language = this.userLanguage.value;
			this.$log(this.newUser.language);
		},
	},

	created() {
		this.getUsers();
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="reactivations">
		<Card v-if="loading">
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.reactivations.title") }}</h1>
			</div>

			<div>
				<b-row class="fw-bold mb-3">
					<b-col cols="4">{{ $t("admin.reactivations.reactivationId") }}</b-col>
					<b-col cols="4">{{ $t("admin.reactivations.user") }}</b-col>
					<b-col cols="4">{{ $t("admin.reactivations.date") }}</b-col>
				</b-row>
				<b-row
					v-for="i of range(0, 10)"
					class="mb-2"
					:key="'reactivation-skeleton-container-' + i"
				>
					<b-col
						cols="4"
						v-for="i of range(0, 3)"
						:key="'reactivation-skeleton-' + i"
					>
						<b-skeleton />
					</b-col>
				</b-row>
			</div>
		</Card>

		<Card v-else>
			<div class="d-flex justify-content-between align-items-center">
				<h1 class="mb-3">{{ $t("admin.reactivations.title") }}</h1>
			</div>

			<b-form>
				<b-form-input
					type="text"
					v-model="filter"
					:placeholder="$t('admin.reactivations.filter')"
					class="mb-2"
				/>

				<div>
					<label>{{ $t("admin.reactivations.filterValues") }}</label>
					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							id="reactivation"
							v-model="filterType"
							value="reactivation"
						/>
						<label class="form-check-label" for="reactivation">{{
							$t("admin.reactivations.reactivationId")
						}}</label>
					</div>
					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							id="user"
							v-model="filterType"
							value="user"
						/>
						<label class="form-check-label" for="user">{{
							$t("admin.reactivations.user")
						}}</label>
					</div>
					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							id="both"
							v-model="filterType"
							value="both"
						/>
						<label class="form-check-label" for="both">{{
							$t("admin.reactivations.both")
						}}</label>
					</div>
				</div>
			</b-form>

			<b-table
				hover
				striped
				responsive
				:items="filtered"
				:fields="fields"
				:empty-text="$t('admin.reactivations.empty')"
				show-empty
			>
				<template #cell(user.id)="data">
					<b-button
						variant="link"
						class="p-0 m-0 text-start"
						@click="userNav(data.item.user.id)"
					>
						@{{ data.item.user.username }}&nbsp;({{ data.item.user.id }})
					</b-button>
				</template>
				<template #cell(createdAt)="data"
					>{{ getFormattedDate(data.item.createdAt) }}
				</template>
				<template #cell(actions)="data">
					<b-button
						variant="outline-danger"
						@click="deleteReactivation(data.item.id)"
						:disabled="busy"
					>
						<b-icon icon="trash" />
					</b-button>
				</template>
			</b-table>
		</Card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import { range } from "@/lib/range";
import { api } from "@/lib/api";

export default {
	name: "AdminReactivations",
	components: { Card },
	data() {
		return {
			range,
			loading: true,
			busy: false,
			reactivations: [],
			filter: null,
			filterType: "both",
			fields: [
				{
					key: "id",
					label: this.$t("admin.reactivations.reactivationId"),
				},
				{
					key: "user.id",
					label: this.$t("admin.reactivations.user"),
				},
				{
					key: "createdAt",
					label: this.$t("admin.reactivations.date"),
				},
				{
					key: "actions",
					label: "",
				},
			],
			error: null,
		};
	},

	methods: {
		getReactivations() {
			api("admin/reactivations", "GET", true)
				.then(data => {
					this.reactivations = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		userNav(id) {
			this.$router.push({ name: "AdminUsersDetail", params: { id } });
		},

		getFormattedDate(date) {
			return new Date(date).toLocaleString(undefined, {
				day: "2-digit",
				month: "2-digit",
				year: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			});
		},

		deleteReactivation(id) {
			this.busy = true;
			api("admin/reactivations/" + id, "DELETE", true)
				.then(data => {
					this.reactivations = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.busy = false;
				});
		},
	},

	computed: {
		filtered() {
			if (this.filter === null) return this.reactivations;
			const lowerFilter = this.filter.toLowerCase();

			switch (this.filterType) {
				case "reactivation":
					return this.reactivations.filter(rea =>
						String(rea.id).toLowerCase().includes(lowerFilter)
					);

				case "user":
					return this.reactivations.filter(
						rea =>
							String(rea.user.id).toLowerCase().includes(lowerFilter) ||
							String(rea.user.username).toLowerCase().includes(lowerFilter)
					);

				case "both":
				default:
					return this.reactivations.filter(
						rea =>
							String(rea.id).toLowerCase().includes(lowerFilter) ||
							String(rea.user.id).toLowerCase().includes(lowerFilter) ||
							String(rea.user.username).toLowerCase().includes(lowerFilter)
					);
			}
		},
	},

	created() {
		this.getReactivations();
	},
};
</script>

<style scoped></style>

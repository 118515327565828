<template>
	<div class="change-password">
		<b-container class="change-password-wrapper p-3 rounded border">
			<b-alert :show="showOldPasswordIncorrect" variant="danger">
				{{ $t("changePassword.errors.oldPassword") }}
			</b-alert>
			<b-alert :show="showPasswordMismatch" variant="danger">
				{{ $t("changePassword.errors.mismatch") }}
			</b-alert>
			<b-alert :show="showSamePassword" variant="danger">
				{{ $t("changePassword.errors.same") }}
			</b-alert>
			<b-alert :show="showUnknown" variant="danger">
				{{ $t("changePassword.errors.unknown") }}
			</b-alert>

			<h1 class="mb-3">{{ $t("changePassword.title") }}</h1>
			<p>{{ $t("changePassword.description") }}</p>
			<b-form v-on:submit.prevent="changePassword">
				<b-input
					class="mb-3"
					:placeholder="$t('changePassword.oldPassword')"
					v-model="oldPassword"
					type="password"
				/>

				<strong-password
					class="mb-3"
					:placeholder="$t('changePassword.newPassword')"
					v-model="password"
					@spStateChange="passwordState = $event"
					:state="customState"
					:feedback="customFeedback"
				/>

				<strong-password
					class="mb-3"
					:placeholder="$t('changePassword.newPasswordRepeat')"
					v-model="passwordRepeat"
					@spStateChange="passwordRepeatState = $event"
					:state="customStateRepeat"
					:feedback="customFeedbackRepeat"
				/>

				<b-button
					variant="primary"
					class="w-100 shadow"
					type="submit"
					:disabled="!isValid || loading"
				>
					{{ $t("changePassword.button") }}
				</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import localStorage from "@/lib/localStorage";
import StrongPassword from "@/components/strongPassword.vue";

export default {
	name: "ChangePassword",
	components: { StrongPassword },
	data() {
		return {
			showOldPasswordIncorrect: false,
			showPasswordMismatch: false,
			showSamePassword: false,
			showUnknown: false,
			oldPassword: null,
			password: null,
			passwordState: false,
			passwordRepeat: null,
			passwordRepeatState: false,
			returnPath: this.$route.query.to,
			loading: false,
			success: false,
		};
	},

	methods: {
		changePassword() {
			this.loading = true;
			this.showPasswordMismatch = false;
			this.showSamePassword = false;
			this.showOldPasswordIncorrect = false;
			this.showUnknown = false;
			const body = {
				oldPassword: this.oldPassword,
				newPassword: this.password,
				newPasswordCheck: this.passwordRepeat,
			};

			api("users/me/change-password", "PATCH", true, body)
				.then(data => {
					if (!data.success) return (this.showUnknown = true);
					this.success = true;
					if (this.returnPath)
						return this.$router.push({ path: this.returnPath });
					this.$router.push({
						name: "UserProfile",
						params: { id: `@${this.$store.getters.username}` },
					});
				})
				.catch(err => {
					switch (err.message) {
						case "old password incorrect":
							this.showOldPasswordIncorrect = true;
							break;
						case "passwords don't match":
							this.showPasswordMismatch = true;
							break;
						case "new password cannot be the old one":
							this.showSamePassword = true;
							break;
						default:
							this.showUnknown = true;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		isValid() {
			return (
				this.password === this.passwordRepeat &&
				this.password !== null &&
				this.oldPassword !== null &&
				this.oldPassword !== this.password &&
				this.passwordState &&
				this.passwordRepeatState
			);
		},

		customState() {
			return this.password !== this.oldPassword;
		},

		customFeedback() {
			return this.$t("changePassword.feedback.password");
		},

		customStateRepeat() {
			return this.password === this.passwordRepeat && this.customState;
		},

		customFeedbackRepeat() {
			if (!this.customState) return this.$t("changePassword.feedback.password");
			return this.$t("changePassword.feedback.passwordRepeat");
		},
	},

	beforeDestroy() {
		if (this.success) return;
		localStorage.removeItem("accessToken");
		this.$store.dispatch("clearUser");
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.change-password {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}

.change-password-wrapper {
	max-width: 400px;
}
</style>

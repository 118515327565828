<template>
	<div class="my-books">
		<b-container>
			<div class="d-flex justify-content-between align-items-center mt-2 mb-3">
				<h1 class="mb-0">{{ $t("author.myBooks.title") }}</h1>
				<b-button
					size="sm"
					variant="outline-secondary"
					:disabled="loading"
					@click="newBook"
				>
					<b-icon icon="plus-circle" />
					{{ $t("author.myBooks.newBook") }}
				</b-button>
			</div>

			<div class="books" v-if="!loading">
				<list-book
					v-for="book of this.books"
					:book="book"
					:key="book.id"
					class="mb-3"
					@firstButton="editBook(book.id)"
					@secondButton="deleteBook(book.id)"
				/>
			</div>

			<div class="books-loading" v-else>
				<list-book-skeleton
					v-for="i of range(0, 10)"
					:key="'list-book-skeleton-' + i"
					class="mb-3"
				/>
			</div>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ListBook from "@/components/listBook.vue";
import ListBookSkeleton from "@/components/listBookSkeleton.vue";
import { range } from "@/lib/range";

export default {
	name: "MyBooks",
	components: { ListBookSkeleton, ListBook },
	data() {
		return {
			range,
			books: [],
			loading: true,
		};
	},

	methods: {
		getMyBooks() {
			api("author/books", "GET", true)
				.then(data => {
					this.books = data;
					this.loading = false;
				})
				.catch(() => {});
		},

		newBook() {
			this.$router.push({ name: "NewBook" });
		},

		deleteBook(id) {
			api("author/books/" + id, "DELETE", true)
				.then(data => {
					this.books = data;
				})
				.catch(() => {});
		},

		editBook(id) {
			this.$router.push({ name: "EditBook", params: { id: id } });
		},
	},

	created() {
		this.getMyBooks();
	},
};
</script>

<style scoped></style>

import localStorage from "@/lib/localStorage";

const SERVER_HOST = process.env.VUE_APP_API_HOST;

export function api(
	route,
	method = "GET",
	useAuth = false,
	body = null,
	version = "v1",
	fetchOptions
) {
	return new Promise((resolve, reject) => {
		let options = {
			...fetchOptions,
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
		};

		if (body) {
			options.body = JSON.stringify(body);
		}

		const accessToken = localStorage.getItem("accessToken");
		if (accessToken) options.headers["Authorization"] = "Bearer " + accessToken;

		if (useAuth && !accessToken) {
			reject({
				message: "cannot get access token from local storage",
			});
		}

		fetch(`${SERVER_HOST}/${version}/${route}`, options)
			.then(async response => {
				if (response.status === 200 || response.status === 201) {
					try {
						const data = await response.json();
						console.log(`${method.toUpperCase()} ${version}/${route}: `, data);
						resolve(data);
					} catch (e) {
						console.error(`${method.toUpperCase()} /${route}: `, e);
						reject(e);
					}
				} else {
					const data = await response.json();
					console.log(`${method.toUpperCase()} ${version}/${route}: `, data);
					console.error(`${method.toUpperCase()} /${route}: `, data);
					reject(data);
				}
			})
			.catch(error => {
				if (error.name === "AbortError") return reject("Aborted");
				console.error(`${method.toUpperCase()} ${version}/${route}: `, error);
				reject(error);
			});
	});
}

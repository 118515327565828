import { render, staticRenderFns } from "./AdminGenres.vue?vue&type=template&id=aa32629e&scoped=true&"
import script from "./AdminGenres.vue?vue&type=script&lang=js&"
export * from "./AdminGenres.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa32629e",
  null
  
)

export default component.exports
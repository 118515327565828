<template>
	<div class="user d-flex border p-3 rounded gap-3" @click="$emit('click')">
		<profile-picture
			:profile-picture="user.profilePicture"
			:name="user.name"
			style="width: 50px"
		/>
		<div>
			<p class="mb-0">{{ user.name }}</p>
			<p class="mb-0 text-muted fst-italic">@{{ user.username }}</p>
		</div>
	</div>
</template>

<script>
import ProfilePicture from "@/components/ProfilePicture.vue";

export default {
	name: "SearchUser",
	components: { ProfilePicture },
	props: {
		user: {
			type: Object,
			required: true,
			default: () => ({
				name: "",
				profilePicture: "",
				username: "",
			}),
		},
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<section>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: "card",
};
</script>

<style scoped lang="scss">
@import "../styles/variables";

section {
	background: #fff;
	border-radius: 15px;
	padding: 10px;
	//position: sticky;
	top: 60px;
	box-shadow: 0 2px 20px 0 darken($background-color, 10);
}
</style>

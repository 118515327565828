<template>
	<div class="genre">
		<card>
			<div v-if="!loading">
				<h1 class="mb-3">
					{{ $t("admin.genres.details.title", { name: title }) }}
				</h1>
				<b-input
					v-model="genre.name"
					class="mb-2"
					:placeholder="$t('admin.genres.details.placeholder')"
				/>
				<b-button
					variant="primary"
					@click="saveGenre"
					:disabled="actionLoading"
					class="me-2"
				>
					{{ $t("admin.genres.details.save") }}
				</b-button>
				<b-button
					variant="outline-danger"
					@click="deleteGenre"
					:disabled="actionLoading"
				>
					{{ $t("admin.genres.details.delete") }}
				</b-button>
			</div>
		</card>
	</div>
</template>

<script>
import Card from "@/components/card.vue";
import { api } from "@/lib/api";

export default {
	name: "AdminGenreDetails",
	components: { Card },
	data() {
		return {
			id: this.$route.params.id,
			loading: true,
			actionLoading: false,
			error: null,
			genre: null,
			title: "",
		};
	},

	methods: {
		getGenre() {
			this.loading = true;

			api("genres/" + this.id, "GET")
				.then(data => {
					this.genre = data;
					this.title = data.name;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		saveGenre() {
			this.actionLoading = true;

			const body = {
				name: this.genre.name,
			};

			api("genres/" + this.id, "PATCH", true, body)
				.then(data => {
					this.genre = data;
					this.title = data.name;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.actionLoading = false;
				});
		},

		deleteGenre() {
			this.actionLoading = true;

			api("genres/" + this.id, "DELETE", true)
				.then(() => {
					this.$router.push({ name: "AdminGenres" });
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.actionLoading = false;
				});
		},
	},

	watch: {
		id() {
			this.getGenre();
		},
	},

	created() {
		this.getGenre();
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="request-passwort-reset">
		<b-container class="request-passwort-reset-wrapper p-3 rounded border">
			<h1 class="mb-3">{{ $t("requestReset.title") }}</h1>
			<b-form v-on:submit.prevent="requestReset" v-if="!sent">
				<b-input
					v-model="email"
					class="mb-2"
					:placeholder="$t('requestReset.email')"
					type="email"
					required
					:state="emailValid"
				/>
				<b-button
					variant="primary"
					class="w-100 shadow"
					type="submit"
					:disabled="!emailValid || loading"
					>{{ $t("requestReset.button") }}
				</b-button>
			</b-form>
			<div v-if="sent">
				<p>{{ $t("requestReset.sent") }}</p>
			</div>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";

export default {
	name: "RequestReset",
	data() {
		return {
			email: this.$route.query.email || null,
			loading: false,
			sent: false,
		};
	},

	methods: {
		requestReset: function () {
			this.loading = true;
			api("auth/password-reset/request", "POST", false, {
				email: this.email,
			})
				.then(data => {
					this.loading = false;
					if (data.processed) {
						this.sent = true;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		emailValid() {
			if (typeof this.email !== "string") return null;
			return !!this.email.match(this.$store.state.emailRegex);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.request-passwort-reset {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}

.request-passwort-reset-wrapper {
	max-width: 400px;
}
</style>

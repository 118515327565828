<template>
	<div class="p-3 rounded-3 border">
		<div
			class="d-flex justify-content-between align-items-center cursor-pointer user-select-none"
			@click="handleClick"
		>
			<p class="mb-0 h4 collapse-header"><slot name="header" /></p>
			<b-icon
				icon="chevron-down"
				:class="iconClass"
				class="expand-icon h3 mb-0"
			/>
		</div>
		<Transition
			name="grow"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div v-if="show" class="mt-3 pt-3 border-top">
				<slot name="default" />
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	name: "collapseItem",
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			rotation: this.active ? 180 : 0,
			show: this.active,
		};
	},

	methods: {
		handleClick() {
			this.show = !this.show;
		},

		start(el) {
			el.style.height = el.scrollHeight + "px";
		},

		end(el) {
			el.style.height = "";
		},
	},

	computed: {
		iconClass() {
			return this.show ? "rotate" : "";
		},
	},

	watch: {
		show() {
			if (this.show) return (this.rotation = 180);
			else return (this.rotation = 0);
		},

		active() {
			this.show = this.active;
		},
	},
};
</script>

<style scoped lang="scss">
.grow-enter-active,
.grow-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.grow-enter,
.grow-leave-to {
	height: 0 !important;
	opacity: 0;
}

.expand-icon {
	transition: transform 0.3s ease-in-out;
}

.rotate {
	transform: rotateZ(179.99deg);
}

.collapse-header {
	overflow: hidden;
	text-wrap: none;
	text-overflow: ellipsis;
}
</style>

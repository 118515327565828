<template>
	<div class="reset-password">
		<b-container class="reset-password-wrapper p-3 rounded border">
			<h1 class="mb-3">{{ $t("resetPassword.title") }}</h1>
			<error-message :error="error" />
			<b-form v-on:submit.prevent="reset">
				<strong-password
					class="mb-2"
					v-model="password"
					:placeholder="$t('resetPassword.new')"
					@spStateChange="passwordState = $event"
				/>
				<strong-password
					class="mb-2"
					v-model="passwordCheck"
					:placeholder="$t('resetPassword.repeat')"
					@spStateChange="passwordCheckState = $event"
				/>
				<div class="form-check mb-2">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="previousLogout"
						id="previousLogout"
					/>
					<label class="form-check-label" for="previousLogout">
						{{ $t("resetPassword.logout") }}
					</label>
				</div>
				<b-button
					variant="primary"
					class="w-100 shadow"
					type="submit"
					:disabled="!passwordState || !passwordCheckState || loading"
					>{{ $t("resetPassword.button") }}
				</b-button>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import StrongPassword from "@/components/strongPassword.vue";
import ErrorMessage from "@/components/errorMessage.vue";

export default {
	name: "ResetPassword",
	components: { ErrorMessage, StrongPassword },
	data() {
		return {
			token: this.$route.params.token,
			password: null,
			passwordState: false,
			passwordCheck: null,
			passwordCheckState: false,
			previousLogout: true,
			error: null,
			loading: false,
		};
	},

	methods: {
		reset: function () {
			this.loading = true;
			api("auth/password-reset/" + this.token, "POST", false, {
				password: this.password,
				passwordCheck: this.passwordCheck,
				previousLogout: this.previousLogout,
			})
				.then(data => {
					this.loading = false;
					if (data.passwordReset) {
						this.$router.push({ name: "Login" });
					}
				})
				.catch(err => {
					this.loading = false;
					this.error = err;
					this.$log(err);
				});
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.reset-password {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}

.reset-password-wrapper {
	max-width: 400px;
}
</style>

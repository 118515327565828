<template>
	<div class="m-3">
		<collapse-item
			v-for="faq of faqs"
			:key="faq.hash"
			class="mb-2"
			v-model="faq.active"
			:active="$route.hash === faq.hash"
			:id="faq.hash.substring(1)"
		>
			<template #header>{{ faq.title }}</template>

			<p
				v-for="(text, i) of faq.text"
				:key="`faq-${faq.hash}-text-${i}`"
				v-html="text"
			/>
			<div v-if="faq.related && faq.related.length > 0" class="mb-2">
				<p class="mb-0">Related topics:</p>
				<div class="d-flex gap-2">
					<b-link
						v-for="link of faq.related"
						:href="link"
						:key="`${faq.hash}-related-${link}`"
					>
						{{ link }}
					</b-link>
				</div>
			</div>
			<p class="text-muted fst-italic">
				Last updated: {{ $d(new Date(faq.lastUpdate), "faqUpdate") }}
			</p>
		</collapse-item>
	</div>
</template>

<script>
import CollapseItem from "@/components/collapseItem.vue";

export default {
	name: "FAQ",
	components: { CollapseItem },
	data() {
		return {
			faqs: [
				{
					title: "Do I transfer my copyright by publishing a book?",
					text: [
						"In order to provide our service, we need the appropriate permissions. By uploading your book or story, you grant us a non-exclusive, worldwide license. This license allows us to host, use, publicly display and distribute your book. The granted license is valid until you delete the book or your account.",
					],
					hash: "#license",
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "Do you charge anything for the service?",
					text: [
						"To put it briefly, No! We do not charge anything for the service and we want to keep it like this.",
					],
					hash: "#service-fees",
					related: ["#stored-data"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: 'How are "featured books" picked?',
					text: [
						"Featured books are handpicked by the admins. It's important for us that everyone has equal chances to get featured, therefor the only criteria is a good story.",
						"Please report admins abusing this power immediately!",
					],
					hash: "#featured-books",
					related: ["#service-fees"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "How can I publish my books?",
					text: [
						'At first you need to become an author (see related topics for more details). When you are an author, a dropdown menu labeled "Author" should on the right side of the navigation bar. Clicking on it reveals the option "New Book", click it and fill in all the required details about your book and click the save button. After doing so, your book is saved and may be published anytime.',
						"If you just became an author and can't see the \"Author\" dropdown in the navigation, please try logging out and in again. In case you still can't see the dropdown, please send us feedback via our feedback form.",
					],
					hash: "#publish-books",
					related: ["#service-fees", "#becoming-an-author"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "How can I become an author?",
					text: [
						'After signing up, the default role for everyone is "User". We do this to ensure quality and save as much storage as possible. When you want to publish your stories on this platform, you have to send us an excerpt of one of your books. We\'ll approve your application if the excerpt meets our quality requirements.',
					],
					hash: "#becoming-an-author",
					related: ["#service-fees", "#publish-books"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "Which data do you store?",
					text: [
						"We set ourselves a strict policy demanding us to store as little personal data as possible, that's why we only store data being relevant and necessary for our services. The stored data is strictly limited to the purpose of keeping your account save, publishing books, moderating the plattform and providing a good user experience.",
					],
					hash: "#stored-data",
					related: ["#service-fees"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "Why does my book keep switching to private?",
					text: [
						"As part of the content moderation, our admins might switch your books or chapters to private, because they violate our terms of service. You can choose to modify the story to align with our terms of service or remove the story from the page. In case you keep publishing your book without modification, your account might be suspended.",
						"If you think it's an mistake, you are free to contact the admins and/or submit feedback using our feedback form.",
					],
					hash: "#book-switches-to-private",
					related: ["#account-disabled"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
				{
					title: "Why is my account disabled?",
					text: [
						"There are three reasons for a blocked account",
						"You disabled it yourself. If you still have an active session, you can use the security center in the account-dropdown to re-enable your account. Otherwise please contact an admin.",
						'The system disabled your account as a security measure. To protect your account, the system will automatically disable your account if an incorrect password is entered too often. If that\'s the case, you should have received an email sent by "books@dealcraft.de", please check your spam folder as well',
						"One of our admins disabled your account, either because they discovered suspicious activity associated with your account, or because of a violation of our terms of service",
					],
					hash: "#account-disabled",
					related: ["#book-switches-to-private"],
					lastUpdate: "2023-10-23T22:21:41.156Z",
				},
			],
		};
	},
};
</script>

<style scoped lang="scss"></style>

<template>
	<div class="feedback-feature">
		<error-message :error="error" />
		<div v-if="feature.id">
			<h3>{{ feature.name }}</h3>

			<div class="feedbacks">
				<div
					class="feedback border rounded-2 p-3 cursor-pointer d-flex align-items-center gap-2 mb-3"
					:class="fb.assigned === null ? 'border-danger' : ''"
					v-for="fb of feature.feedbacks"
					:key="fb.id"
					@click="feedbackDetail(fb.id)"
				>
					<div class="flex-grow-1">
						<h4>{{ fb.title }}</h4>
						<p class="text-muted fst-italic mb-0">
							{{
								$t("admin.feedback.feedback.overview.version", {
									version: fb.version,
								})
							}}
						</p>
						<p class="text-muted fst-italic mb-0" v-if="fb.resolvedVersion">
							<span class="fw-bold">{{
								$t("admin.feedback.feedback.overview.resolvedIn")
							}}</span>
							{{
								$t("admin.feedback.feedback.overview.version", {
									version: fb.resolvedVersion,
								})
							}}
						</p>
					</div>
					<div class="d-flex flex-column gap-2">
						<feedback-type-badge :type="fb.type" />
						<feedback-priority-badge :priority="fb.priority" />
						<feedback-status-badge :status="fb.status" />
					</div>
					<b-icon icon="chevron-right" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ErrorMessage from "@/components/errorMessage.vue";
import { api } from "@/lib/api";
import FeedbackPriorityBadge from "@/components/feedbackPriorityBadge.vue";
import FeedbackTypeBadge from "@/components/feedbackTypeBadge.vue";
import FeedbackStatusBadge from "@/components/feedbackStatusBadge.vue";

export default {
	name: "AdminFeedbackFeatureDetail",
	components: {
		FeedbackStatusBadge,
		FeedbackTypeBadge,
		FeedbackPriorityBadge,
		ErrorMessage,
	},
	data() {
		return {
			id: this.$route.params.id,
			feature: {},
			error: null,
		};
	},

	methods: {
		getFeature() {
			api("feedback/features/" + this.id, "GET", true)
				.then(data => {
					this.feature = data;
				})
				.catch(err => {
					this.error = err;
				});
		},
		version(fbv) {
			return fbv.match(/^[0-9]+\.[0-9]+\.[0-9]+/)[0];
		},
		feedbackDetail(id) {
			this.$router.push({ name: "AdminFeedbackDetail", params: { id: id } });
		},
	},

	created() {
		this.getFeature();
	},
};
</script>

<style scoped lang="scss"></style>

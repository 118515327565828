<template>
	<b-nav-item-dropdown
		:text="$t('navbar.loggedOut.dropdown')"
		menu-class="dropdown-menu-end right-0"
	>
		<b-dropdown-item @click="navigator('Login')">
			{{ $t("navbar.loggedOut.login") }}
		</b-dropdown-item>
		<b-dropdown-item
			v-if="$store.getters.signupEnabled"
			@click="navigator('Signup')"
		>
			{{ $t("navbar.loggedOut.signup") }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
export default {
	name: "loggedOutDropdown",
	methods: {
		navigator: function (page) {
			this.$router.push({ name: page }).catch(() => {});
		},
	},
};
</script>

<style scoped></style>

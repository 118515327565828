<template>
	<div class="feedbacks py-3">
		<error-message :error="error" />
		<p v-if="feedbacks.length <= 0 && !loading" class="text-muted fst-italic">
			{{ $t("admin.feedback.feedback.overview.noFeedback") }}
		</p>
		<div
			class="feedback border rounded-2 p-3 cursor-pointer d-flex align-items-center gap-2 mb-3"
			:class="fb.assigned === null ? 'border-danger' : ''"
			v-for="fb of feedbacks"
			:key="fb.id"
			@click="feedbackDetail(fb.id)"
		>
			<div class="flex-grow-1">
				<h4>
					{{ fb.title
					}}<span class="text-muted" v-if="fb.feature && fb.feature.name">
						&nbsp;-&nbsp;{{ fb.feature.name }}
					</span>
				</h4>
				<p class="text-muted fst-italic mb-0">
					{{
						$t("admin.feedback.feedback.overview.version", {
							version: fb.version,
						})
					}}
				</p>
				<p class="text-muted fst-italic mb-0" v-if="fb.resolvedVersion">
					<span class="fw-bold">{{
						$t("admin.feedback.feedback.overview.resolvedIn")
					}}</span>
					{{
						$t("admin.feedback.feedback.overview.version", {
							version: fb.resolvedVersion,
						})
					}}
				</p>
			</div>
			<div class="d-flex flex-column gap-2">
				<feedback-type-badge :type="fb.type" />
				<feedback-priority-badge :priority="fb.priority" />
				<feedback-status-badge :status="fb.status" />
			</div>
			<b-icon icon="chevron-right" />
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ErrorMessage from "@/components/errorMessage.vue";
import FeedbackTypeBadge from "@/components/feedbackTypeBadge.vue";
import FeedbackStatusBadge from "@/components/feedbackStatusBadge.vue";
import FeedbackPriorityBadge from "@/components/feedbackPriorityBadge.vue";

export default {
	name: "AdminFeedbackOverview",
	components: {
		FeedbackPriorityBadge,
		FeedbackStatusBadge,
		FeedbackTypeBadge,
		ErrorMessage,
	},
	data() {
		return {
			error: null,
			loading: true,
			feedbacks: [],
		};
	},

	methods: {
		getFeedbacks() {
			api("feedback/", "GET", true)
				.then(data => {
					this.feedbacks = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		version(fbv) {
			return fbv.match(/^[0-9]+\.[0-9]+\.[0-9]+/)[0];
		},
		feedbackDetail(id) {
			this.$router.push({ name: "AdminFeedbackDetail", params: { id: id } });
		},
	},

	created() {
		this.getFeedbacks();
	},
};
</script>

<style scoped lang="scss"></style>

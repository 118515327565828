<template>
	<div class="chapter">
		<b-container class="mt-3">
			<b-row>
				<b-col cols="12" md="3" class="mb-5 mb-md-0">
					<book-cover :src="chapter.book.coverPath" />
					<h3 class="cursor-pointer mt-3" @click="toBook()">
						{{ chapter.book.title }}
					</h3>
					<p class="cursor-pointer" @click="toAuthor()">
						{{ chapter.book.author.name }}
						<span class="text-muted fst-italic"
							>@{{ chapter.book.author.username }}</span
						>
					</p>
				</b-col>
				<b-col cols="0" md="1"></b-col>
				<b-col cols="12" md="8">
					<h2 class="text-center mb-4">{{ chapter.name }}</h2>
					<markdown-output :markdown="chapter.text" class="mb-5" />

					<div v-if="continuous">
						<b-button
							variant="outline-primary"
							class="d-block mx-auto"
							@click="nextChapterNav"
							:disabled="!nextChapter"
						>
							{{ $t("books.read.nextChapter") }}
						</b-button>
						<p v-if="!nextChapter" class="text-center mt-2">
							{{ $t("books.read.notAvailable") }}
						</p>
					</div>

					<div v-else-if="!continuous">
						<b-button
							variant="outline-primary"
							class="d-block mx-auto"
							@click="nextChapterNav"
							v-if="nextChapter"
						>
							{{ $t("books.read.nextChapter") }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";

export default {
	name: "BookRead",
	components: { MarkdownOutput, BookCover },
	data() {
		return {
			bookId: this.$route.params.bookId,
			chapterId: this.$route.params.chapterId,
			chapter: {},
			head: {},
		};
	},

	head() {
		return this.head;
	},

	methods: {
		getChapter() {
			api(`books/${this.bookId}/chapters/${this.chapterId}`, "GET")
				.then(data => {
					this.chapter = data;
					this.generateHead();
					this.$log(data);
				})
				.catch(err => {
					this.$error(err);
				});
		},

		toAuthor() {
			this.$router.push({
				name: "UserProfile",
				params: { id: this.chapter.book.author.id },
			});
		},

		toBook() {
			this.$router.push({
				name: "BookDetails",
				params: { id: this.chapter.book.id },
			});
		},

		nextChapterNav() {
			this.$router.push({
				name: "BookRead",
				params: { bookId: this.bookId, chapterId: this.nextChapter },
			});
		},

		generateHead() {
			const chapter = this.chapter;
			const book = chapter.book;

			this.head.title.inner = `${chapter.name} - ${book.title}`;
			this.head.meta = [];
			this.head.link = [];

			// General metadata
			this.head.meta.push({
				name: "description",
				content: chapter.summary,
				id: "meta-description",
			});
			this.head.meta.push({
				name: "format-detection",
				content: "telephone=no",
				id: "meta-format-detection",
			});
			this.head.meta.push({
				name: "google",
				content: "notranslate",
				id: "meta-google-translate",
			});

			// Author links
			this.head.link.push({
				rel: "author",
				href: `/user/@${book.author.username}`,
				id: "link-author",
			});
			if (book.author.communityEmail)
				this.head.link.push({
					rel: "me",
					href: `mailto:${book.author.communityEmail}`,
					id: "link-email",
				});
			if (book.author.website)
				this.head.link.push({
					rel: "me",
					href: `${book.author.website}`,
					type: "text/html",
					id: "link-email",
				});

			// Open Graph
			this.head.meta.push({
				property: "og:url",
				content: location.href,
				id: "og-url",
			});
			this.head.meta.push({
				property: "og:type",
				content: this.$store.getters.metaOgType,
				id: "og-type",
			});
			this.head.meta.push({
				property: "og:title",
				content: chapter.name,
				id: "og-title",
			});
			if (book.coverPath)
				this.head.meta.push({
					property: "og:image",
					content: book.coverPath,
					id: "og-image",
				});
			this.head.meta.push({
				property: "og:description",
				content:
					chapter.summary.substring(
						0,
						this.$store.getters.metaDescriptionLength
					) + "...\nInteressiert? Lies jetzt das ganze Kapitel kostenlos!",
				id: "og-description",
			});
			this.head.meta.push({
				property: "og:site_name",
				content: "Books-Dealcraft",
				id: "og-site-name",
			});
			this.head.meta.push({
				property: "og:locale",
				content: book.language,
				id: "og-locale",
			});

			// Twitter Card
			this.head.meta.push({
				property: "twitter:card",
				content: this.$store.getters.metaTwitterType,
				id: "twitter-type",
			});
			this.head.meta.push({
				property: "twitter:url",
				content: location.href,
				id: "twitter-url",
			});
			this.head.meta.push({
				property: "twitter:title",
				content: chapter.name,
				id: "twitter-title",
			});
			this.head.meta.push({
				property: "twitter:description",
				content:
					chapter.summary.substring(
						0,
						this.$store.getters.metaDescriptionLength
					) + "...\nInteressiert? Lies jetzt das ganze Kapitel kostenlos!",
				id: "twitter-description",
			});
			if (book.coverPath)
				this.head.meta.push({
					property: "twitter:image",
					content: book.coverPath,
					id: "twitter-image",
				});
			this.head.meta.push({
				property: "twitter:dnt",
				content: "on",
				id: "twitter-dnt",
			});

			this.$emit("updateHead");
		},
	},

	computed: {
		nextChapter() {
			return this.chapter.nextChapterId;
		},

		continuous() {
			return this.chapter.book.continuous;
		},
	},

	watch: {
		bookId() {
			this.getChapter();
		},
		chapterId() {
			this.getChapter();
		},
	},

	created() {
		this.getChapter();
		this.$watch(
			() => this.$route.params,
			toParams => {
				this.bookId = toParams.bookId;
				this.chapterId = toParams.chapterId;
			}
		);
		this.head = {
			title: {
				inner: "Kapitel",
			},
			meta: [{ name: "description", content: "Lädt", id: "meta-description" }],
			link: [{ rel: "author", href: "/", id: "link-author" }],
		};
	},
};
</script>

<style scoped></style>

<template>
	<b-container class="mt-3" v-if="!loading">
		<b-row>
			<b-col cols="12" md="3" class="position-md-sticky top-md-0 mb-5">
				<div class="book-cover mx-auto mb-3">
					<book-cover
						:src="book.coverPath"
						class="shadow mb-3 d-inline-block"
					/>
					<div class="ribbon ribbon-top-right" v-if="book.featured">
						<span class="user-select-none">{{
							$t("books.details.featured")
						}}</span>
					</div>
				</div>
				<div class="author">
					<p @click="navToAuthor(book.author.username)" class="cursor-pointer">
						{{ book.author.name }}
						<span class="text-muted username">@{{ book.author.username }}</span>
					</p>
				</div>
				<b-badge
					v-if="book.genre"
					class="cursor-pointer rounded-pill bg-secondary text-light"
					variant="secondary"
					@click="genreDetails(book.genre)"
				>
					{{ this.book.genre.name }}
				</b-badge>
				<div class="mt-1 mb-3">
					<b-badge
						class="cursor-pointer rounded-pill bg-secondary text-light me-1"
						variant="secondary"
						v-on:click="tagDetails(tag)"
						v-for="tag in this.book.tags"
						:key="tag.id"
					>
						{{ tag.name }}
					</b-badge>
				</div>

				<p>
					{{ $t("books.details.language") }}
					<country-flag :country="book.language" />
				</p>

				<div class="continuous-badge mb-3" v-if="book.continuous">
					{{ $t("books.details.continuous.0") }}
				</div>
				<div class="completed-badge mb-3" v-else>
					{{ $t("books.details.continuous.1") }}
				</div>

				<b-button
					variant="primary"
					class="w-100 mb-2"
					pill
					@click="readChapter(book.chapters[0].id)"
				>
					<b-icon-book class="me-1" />
					{{ $t("books.details.read") }}
				</b-button>
				<b-button
					variant="outline-primary"
					v-on:click="share"
					class="w-100"
					pill
				>
					<b-icon-share class="me-1"></b-icon-share>
					<Transition mode="out-in" name="fade-in">
						<p class="d-inline-block mb-0" :key="this.copied.text">
							{{ this.copied.text }}
						</p>
					</Transition>
				</b-button>
			</b-col>
			<b-col cols="12" md="9">
				<div class="book-details mb-5">
					<h2>{{ this.book.title }}</h2>
					<markdown-output :markdown="book.description" />
				</div>

				<div class="chapters">
					<h3>{{ $t("books.details.chapter") }}</h3>
					<chapter-preview
						v-for="(chapter, i) of book.chapters"
						:key="chapter.id"
						:i="i"
						:chapter="chapter"
						class="my-3"
						@click="readChapter(chapter.id)"
					/>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import router from "@/router";
import ChapterPreview from "@/components/chapterPreview.vue";
import CountryFlag from "@/components/countryFlag.vue";
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";

export default {
	name: "BookDetails",
	components: {
		MarkdownOutput,
		BookCover,
		ChapterPreview,
		CountryFlag,
	},

	data() {
		return {
			id: this.$route.params.id,
			head: {},
			loading: true,
			book: {},
			copied: {
				value: false,
				timeout: null,
				text: this.$t("books.details.share.0"),
			},
		};
	},

	head() {
		return this.head;
	},

	methods: {
		getBook: function () {
			api(`books/${this.id}`, "GET")
				.then(data => {
					this.book = data;
					this.generateHead();
				})
				.finally(() => {
					this.loading = false;
				});
		},

		tagDetails: function (tag) {
			router.push({ name: "TagDetails", params: { id: tag.id } });
		},

		genreDetails(genre) {
			router.push({ name: "GenreDetails", params: { id: genre.id } });
		},

		share: async function () {
			navigator.clipboard.writeText(location.toString()).then(() => {
				clearTimeout(this.copied.timeout);
				this.copied.value = true;
				this.copied.text = this.$t("books.details.share.1");
				this.copied.timeout = setTimeout(() => {
					this.copied.value = false;
					this.copied.text = this.$t("books.details.share.0");
				}, 2500);
			});
		},

		readChapter: function (chapterId) {
			this.$router
				.push({
					name: "BookRead",
					params: { bookId: this.id, chapterId: chapterId },
				})
				.catch(() => {});
		},

		navToAuthor: function (username) {
			this.$router
				.push({ name: "UserProfile", params: { id: `@${username}` } })
				.catch(() => {});
		},

		generateHead() {
			const book = this.book;

			this.head.title.inner = book.title;
			this.head.meta = [];
			this.head.link = [];

			// General metadata
			this.head.meta.push({
				name: "description",
				content: book.description,
				id: "meta-description",
			});
			this.head.meta.push({
				name: "format-detection",
				content: "telephone=no",
				id: "meta-format-detection",
			});
			this.head.meta.push({
				name: "google",
				content: "notranslate",
				id: "meta-google-translate",
			});

			// Author links
			this.head.link.push({
				rel: "author",
				href: `/user/@${book.author.username}`,
				id: "link-author",
			});
			if (book.author.communityEmail)
				this.head.link.push({
					rel: "me",
					href: `mailto:${book.author.communityEmail}`,
					id: "link-email",
				});
			if (book.author.website)
				this.head.link.push({
					rel: "me",
					href: `${book.author.website}`,
					type: "text/html",
					id: "link-email",
				});

			// Open Graph
			this.head.meta.push({
				property: "og:url",
				content: location.href,
				id: "og-url",
			});
			this.head.meta.push({
				property: "og:type",
				content: this.$store.getters.metaOgType,
				id: "og-type",
			});
			this.head.meta.push({
				property: "og:title",
				content: book.title,
				id: "og-title",
			});
			if (book.coverPath)
				this.head.meta.push({
					property: "og:image",
					content: book.coverPath,
					id: "og-image",
				});
			this.head.meta.push({
				property: "og:description",
				content:
					book.description.substring(
						0,
						this.$store.getters.metaDescriptionLength
					) + "...\nInteressiert? Lies jetzt das ganze Buch kostenlos!",
				id: "og-description",
			});
			this.head.meta.push({
				property: "og:site_name",
				content: "Books-Dealcraft",
				id: "og-site-name",
			});
			this.head.meta.push({
				property: "og:locale",
				content: book.language,
				id: "og-locale",
			});

			// Twitter Card
			this.head.meta.push({
				property: "twitter:card",
				content: this.$store.getters.metaTwitterType,
				id: "twitter-type",
			});
			this.head.meta.push({
				property: "twitter:url",
				content: location.href,
				id: "twitter-url",
			});
			this.head.meta.push({
				property: "twitter:title",
				content: book.title,
				id: "twitter-title",
			});
			this.head.meta.push({
				property: "twitter:description",
				content:
					book.description.substring(
						0,
						this.$store.getters.metaDescriptionLength
					) + "...\nInteressiert? Lies jetzt das ganze Buch kostenlos!",
				id: "twitter-description",
			});
			if (book.coverPath)
				this.head.meta.push({
					property: "twitter:image",
					content: book.coverPath,
					id: "twitter-image",
				});
			this.head.meta.push({
				property: "twitter:dnt",
				content: "on",
				id: "twitter-dnt",
			});

			this.$emit("updateHead");
		},
	},

	created() {
		this.$watch(
			() => this.$route.params,
			toParams => {
				this.id = toParams.id;
				this.getBook();
			}
		);
		this.getBook();
		this.head = {
			title: {
				inner: "Buch",
			},
			meta: [{ name: "description", content: "Lädt", id: "meta-description" }],
			link: [{ rel: "author", href: "/", id: "link-author" }],
		};
	},
};
</script>

<style scoped lang="scss">
.fade-in-enter-active {
	transition: opacity 300ms ease-in-out;
}

.fade-in-leave-active {
	transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
}

.book-cover {
	position: relative;
}

/* common */
.ribbon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
}

.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: "";
	display: block;
	border: 5px solid #2980b9;
}

.ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: #3498db;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	font: 700 18px/1 "Lato", sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
}

.ribbon-top-right {
	top: -10px;
	right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}

.ribbon-top-right::before {
	top: 0;
	left: 0;
}

.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}

.ribbon-top-right span {
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
}

.book-cover {
	aspect-ratio: 16 / 25;
	width: auto;
	max-height: 300px;

	.cover {
		--height: 300px;
	}
}

.username {
	cursor: pointer;
	transition: all 0.5s;
}

.username:hover {
	color: #43494f !important;
}

.continuous-badge,
.completed-badge {
	border-radius: 99999999999px;
	padding: 5px;
	width: fit-content;
	height: fit-content;
	font-size: 14px;
}

.continuous-badge {
	$color: #bd5400;
	border: 1px solid $color;
	color: $color;
}

.completed-badge {
	$color: #009abd;
	border: 1px solid $color;
	color: $color;
}

@media (width >= 768px) {
	.position-md-sticky {
		position: sticky;
	}

	.top-md-0 {
		top: 0;
	}
}
</style>

<template>
	<div class="security-center" v-if="!loading">
		<div class="banner d-flex align-items-center mb-5 text-center">
			<b-container>
				<p class="h2">
					{{ $t("profile.secCenter.greeting.name", { name: user.name }) }}
				</p>
				<h6>
					{{
						$t("profile.secCenter.greeting.lastLogin", {
							login: $d(new Date(user.lastLogin), "login"),
						})
					}}
				</h6>
			</b-container>
		</div>
		<b-container>
			<div class="mb-4">
				<h4 class="mb-1">
					{{ $t("profile.secCenter.failedLogins.lable") }}
				</h4>
				<p class="mb-0">
					{{
						$tc("profile.secCenter.failedLogins.value", user.failedLogins, {
							count: user.failedLogins,
						})
					}}
				</p>
				<p class="text-muted mb-2">
					{{ $t("profile.secCenter.failedLogins.notice") }}
				</p>
				<b-button
					variant="outline-danger"
					size="sm"
					@click="confirmResetLogins"
					:disabled="buttonsDisabled"
					>{{ $t("profile.secCenter.failedLogins.reset") }}</b-button
				>
			</div>
			<div class="mb-4">
				<h4 class="mb-1">
					{{ $t("profile.secCenter.lastLogin.lable") }}
				</h4>
				<p class="mb-0">
					{{
						$t("profile.secCenter.lastLogin.value", {
							date: $d(new Date(user.lastLogin), "loginLong"),
						})
					}}
				</p>
				<p class="text-muted mb-2">
					{{ $t("profile.secCenter.lastLogin.notice") }}
				</p>
				<b-button
					class="mb-3"
					variant="outline-danger"
					size="sm"
					:disabled="buttonsDisabled"
					@click="showPasswordModal = !showPasswordModal"
				>
					{{ $t("profile.secCenter.lastLogin.reset") }}
				</b-button>

				<div class="sessions">
					<h5>{{ $t("profile.secCenter.lastLogin.activeSessions.label") }}</h5>
					<div class="sessions-container border border-1 rounded">
						<p
							v-if="sessionsLoading"
							class="text-muted fst-italic mb-0 text-center p-2"
						>
							{{ $t("profile.secCenter.lastLogin.activeSessions.loading") }}
						</p>
						<div v-else>
							<div
								class="session d-flex align-items-center p-3 gap-3"
								:class="i >= activeSessions.length - 1 ? '' : 'border-bottom'"
								v-for="(session, i) of activeSessions"
								:key="session.id"
							>
								<div class="text-muted d-none d-md-block">
									<b-icon icon="display" font-scale="3" />
								</div>
								<div class="flex-grow-1">
									<p class="mb-0">
										{{
											$t("profile.secCenter.lastLogin.activeSessions.date", {
												date: $d(new Date(session.createdAt), "session"),
											})
										}}
										<span
											class="current-session-marker"
											v-if="session.isCurrentSecret"
											v-b-tooltip="
												$t(
													'profile.secCenter.lastLogin.activeSessions.currentSessionMarker'
												)
											"
										/>
									</p>
									<p class="mb-0 text-muted">
										{{
											$t("profile.secCenter.lastLogin.activeSessions.id", {
												id: session.id,
											})
										}}
									</p>
								</div>
								<div>
									<b-button
										variant="outline-danger"
										@click="deleteSession(session.id)"
										:disabled="sessionsLoading"
									>
										{{
											$t("profile.secCenter.lastLogin.activeSessions.revoke")
										}}
									</b-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-4">
				<h4 class="mb-1">
					{{ $t("profile.secCenter.isActive.lable") }}
				</h4>
				<p class="mb-0">
					{{
						user.isActive
							? $t("profile.secCenter.isActive.active")
							: $t("profile.secCenter.isActive.disabled")
					}}
				</p>
				<p class="mb-0" v-if="!user.isActive">
					{{
						$t("profile.secCenter.isActive.disabledBy.value", {
							by: $t(
								`profile.secCenter.isActive.disabledBy.${user.disabledBy}`
							),
						})
					}}
				</p>
				<p class="text-muted mb-2">
					{{ $t("profile.secCenter.isActive.notice") }}
				</p>
				<b-button
					variant="outline-danger"
					size="sm"
					:disabled="
						(!user.isActive && user.disabledBy !== DisabledBy.USER) ||
						buttonsDisabled
					"
					@click="confirmActiveAccount"
					>{{
						user.isActive
							? $t("profile.secCenter.isActive.disable")
							: $t("profile.secCenter.isActive.enable")
					}}</b-button
				>
			</div>
			<div class="mb-4">
				<h4 class="mb-1">
					{{ $t("profile.secCenter.deleteAccount.lable") }}
				</h4>
				<p class="mb-2">
					{{ $t("profile.secCenter.deleteAccount.value") }}
				</p>
				<b-button
					variant="outline-danger"
					size="sm"
					:disabled="buttonsDisabled"
					@click="confirmDeleteAccount"
					>{{ $t("profile.secCenter.deleteAccount.delete") }}</b-button
				>
			</div>
			<div class="mb-4">
				<h4 class="mb-1">
					{{ $t("profile.secCenter.dataRequest.lable") }}
				</h4>
				<p class="mb-2">
					{{ $t("profile.secCenter.dataRequest.value") }}
				</p>
				<b-button
					variant="outline-danger"
					size="sm"
					:disabled="buttonsDisabled"
					@click="requestUserData"
					>{{ $t("profile.secCenter.dataRequest.button") }}</b-button
				>
			</div>
		</b-container>

		<b-modal v-model="showConfirmModal" no-close-on-backdrop no-close-on-esc>
			<template #modal-header>
				<h5 class="modal-title">
					{{ $t("profile.secCenter.confirmModal.title") }}
				</h5>
			</template>

			<b-form v-on:submit.prevent="handleConfirm">
				<p v-html="this.modalText"></p>
				<b-form-group
					:label="$t('profile.secCenter.confirmModal.passwordLable')"
				>
					<b-form-input
						v-model="password"
						type="password"
						:state="passwordState"
						@input="passwordState = null"
					/>
				</b-form-group>
			</b-form>

			<template #modal-footer>
				<b-button
					variant="secondary"
					@click="handleAbort"
					:disabled="buttonsDisabled"
					>{{ $t("profile.secCenter.confirmModal.abort") }}</b-button
				>
				<b-button
					variant="danger"
					@click="handleConfirm"
					:disabled="confirmDisabled || buttonsDisabled"
					>{{ $t("profile.secCenter.confirmModal.confirm") }}</b-button
				>
			</template>
		</b-modal>

		<b-modal v-model="showPasswordModal" no-close-on-backdrop no-close-on-esc>
			<template #modal-header>
				<h5 class="modal-title">
					{{ $t("profile.secCenter.passwordModal.title") }}
				</h5>
			</template>

			<b-form v-on:submit.prevent="handlePasswordChange">
				<b-form-group
					:label="$t('profile.secCenter.passwordModal.oldPassword')"
					class="mb-4"
				>
					<b-form-input
						v-model="password"
						type="password"
						:state="passwordState"
						@input="passwordState = null"
					/>
				</b-form-group>
				<b-row>
					<b-col cols="12" sm="6" class="mb-2 mb-sm-0">
						<b-form-group
							:label="$t('profile.secCenter.passwordModal.newPassword')"
						>
							<strong-password v-model="newPassword" />
						</b-form-group>
					</b-col>
					<b-col cols="12" sm="6">
						<b-form-group
							:label="$t('profile.secCenter.passwordModal.newPasswordCheck')"
						>
							<strong-password v-model="newPasswordCheck" />
						</b-form-group>
					</b-col>
				</b-row>
				<div class="form-check mb-2">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="previousLogout"
						id="previousLogout"
					/>
					<label class="form-check-label" for="previousLogout">
						{{ $t("profile.secCenter.lastLogin.previousLogout") }}
					</label>
				</div>
			</b-form>

			<template #modal-footer>
				<b-button
					variant="secondary"
					@click="abortPasswordChange"
					:disabled="buttonsDisabled"
					>{{ $t("profile.secCenter.passwordModal.abort") }}</b-button
				>
				<b-button
					variant="danger"
					@click="handlePasswordChange"
					:disabled="confirmDisabled || buttonsDisabled"
					>{{ $t("profile.secCenter.passwordModal.confirm") }}</b-button
				>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import { DisabledBy } from "@/enums/disabledBy.enum";
import localStorage from "@/lib/localStorage";
import StrongPassword from "@/components/strongPassword.vue";

export default {
	name: "SecurityCenter",
	components: { StrongPassword },
	data() {
		return {
			DisabledBy,
			user: {},
			activeSessions: [],
			sessionsLoading: true,
			loading: true,
			password: null,
			passwordState: null,
			newPassword: null,
			newPasswordCheck: null,
			previousLogout: true,
			showConfirmModal: false,
			showPasswordModal: false,
			modalAction: "",
			modalText: "",
			buttonsDisabled: false,
		};
	},

	methods: {
		getUser() {
			api("users/me", "GET", true)
				.then(data => {
					this.user = data;
				})
				.catch(err => {
					this.$error(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		getSessions() {
			this.sessionsLoading = true;

			api("users/me/active-sessions", "GET", true)
				.then(data => {
					this.activeSessions = data;
					this.$log(data);
				})
				.catch(this.$error)
				.finally(() => {
					this.sessionsLoading = false;
				});
		},

		deleteSession(id) {
			this.sessionsLoading = true;

			api("users/me/active-sessions/" + id, "DELETE", true)
				.then(data => {
					this.activeSessions = data;
					this.$log(data);
				})
				.catch(this.$error)
				.finally(() => {
					this.sessionsLoading = false;
				});
		},

		handleConfirm() {
			if (this.confirmDisabled) return;

			switch (this.modalAction) {
				case "ResetLogins":
					this.handleResetLogins();
					break;
				case "ActiveAccount":
					this.handleActiveAccount();
					break;
				case "DeleteAccount":
					this.handleDeleteAccount();
					break;
				default:
					this.handleAbort();
					break;
			}
		},

		handleResetLogins() {
			if (this.modalAction !== "ResetLogins") return;

			this.buttonsDisabled = true;

			api("users/me/reset-logins", "PATCH", true, {
				password: this.password,
			})
				.then(data => {
					this.user = data;
					this.modalAction = null;
					this.showConfirmModal = false;
					this.modalText = "";
				})
				.catch(err => {
					if (err.message && err.message === "password doesn't match")
						this.passwordState = false;
				})
				.finally(() => {
					this.buttonsDisabled = false;
					this.password = null;
				});
		},

		handleActiveAccount() {
			if (this.modalAction !== "ActiveAccount") return;

			this.buttonsDisabled = true;

			api("users/me/active", "PATCH", true, {
				password: this.password,
				active: !this.user.isActive,
			})
				.then(data => {
					this.user = data;
					this.modalAction = null;
					this.showConfirmModal = false;
					this.modalText = "";
				})
				.catch(err => {
					if (err.message && err.message === "password doesn't match")
						this.passwordState = false;
				})
				.finally(() => {
					this.password = null;
					this.buttonsDisabled = false;
				});
		},

		handleDeleteAccount() {
			if (this.modalAction !== "DeleteAccount") return;

			this.buttonsDisabled = true;

			api("users/me", "DELETE", true, {
				password: this.password,
			})
				.then(() => {
					this.$router.push({ name: "Home" });
					this.password = null;
					this.modalAction = null;
					this.showConfirmModal = false;
					this.$store.dispatch("clearUser");
					localStorage.removeItem("accessToken");
				})
				.catch(err => {
					if (err.message && err.message === "password doesn't match")
						this.passwordState = false;
				})
				.finally(() => {
					this.buttonsDisabled = false;
					this.modalText = "";
				});
		},

		handlePasswordChange() {
			this.buttonsDisabled = true;
			const body = {
				oldPassword: this.password,
				newPassword: this.newPassword,
				newPasswordCheck: this.newPasswordCheck,
				previousLogout: this.previousLogout,
			};

			api("users/me/change-password", "PATCH", true, body)
				.then(data => {
					if (!data.success) throw new Error("change not successful");

					this.password = null;
					this.newPassword = null;
					this.previousLogout = true;
					this.newPasswordCheck = null;
					this.showPasswordModal = false;
				})
				.catch(this.$error)
				.finally(() => {
					this.buttonsDisabled = false;
				});
		},

		handleAbort() {
			this.password = null;
			this.modalAction = "";
			this.showConfirmModal = false;
			this.modalText = "";
		},

		abortPasswordChange() {
			this.password = null;
			this.newPassword = null;
			this.newPasswordCheck = null;
			this.showPasswordModal = false;
		},

		confirmResetLogins() {
			this.showConfirmModal = true;
			this.modalAction = "ResetLogins";
			this.modalText = this.$t("profile.secCenter.failedLogins.modalText");
		},

		confirmActiveAccount() {
			this.showConfirmModal = true;
			this.modalAction = "ActiveAccount";
			this.modalText = this.$t("profile.secCenter.isActive.modalText");
		},

		confirmDeleteAccount() {
			this.showConfirmModal = true;
			this.modalAction = "DeleteAccount";
			this.modalText = this.$t("profile.secCenter.deleteAccount.modalText");
		},

		requestUserData() {
			this.buttonsDisabled = true;
			api("users/me/data", "GET", true)
				.then(data => {
					if (!data.emailSent) return;
					this.$toast.success(this.$t("profile.secCenter.dataRequest.success"));
				})
				.catch(() => {
					this.$toast.error(this.$t("profile.secCenter.dataRequest.failure"));
				})
				.finally(() => {
					this.buttonsDisabled = false;
				});
		},
	},

	computed: {
		confirmDisabled() {
			return !(this.password && this.password.length > 0);
		},
	},

	created() {
		this.getUser();
		this.getSessions();
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.banner {
	transition: all 0.3s ease-in-out;
	min-height: 30vh;
	background: lighten($gray, 6.5%);
}

.current-session-marker {
	display: inline-block;
	background: #53b017;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
}
</style>

const prefix = process.env.VUE_APP_STORAGE_PREFIX;

function getItem(key) {
	return localStorage.getItem(prefix + key);
}

function setItem(key, value) {
	return localStorage.setItem(prefix + key, value);
}

function removeItem(key) {
	return localStorage.removeItem(prefix + key);
}

export default {
	...localStorage,
	length: localStorage.length,
	getItem,
	setItem,
	removeItem,
};

export default {
	install: Vue => {
		Vue.prototype.$log = (...args) => {
			if (process.env.NODE_ENV === "production") return;

			console.log(...args);
		};

		Vue.prototype.$error = (...args) => {
			if (process.env.NODE_ENV === "production") return;

			console.error(...args);
		};
	},
};

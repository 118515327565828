import { render, staticRenderFns } from "./RequestReset.vue?vue&type=template&id=44dc3586&scoped=true&"
import script from "./RequestReset.vue?vue&type=script&lang=js&"
export * from "./RequestReset.vue?vue&type=script&lang=js&"
import style0 from "./RequestReset.vue?vue&type=style&index=0&id=44dc3586&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44dc3586",
  null
  
)

export default component.exports
<template>
	<div class="tag-details container mt-3" v-if="!loading">
		<h2>{{ tag.name }}</h2>
		<h6 class="text-muted">
			{{ $t("tagDetails.subtitle", { name: tag.name }) }}
		</h6>

		<div v-if="tag.books.length > 0" class="books mt-5">
			<div
				class="d-flex px-4 gap-3 flex-wrap justify-content-center justify-content-md-start"
			>
				<book-preview
					v-for="book in tag.books"
					:key="book.id"
					:book="book"
					v-on:click="bookDetails(book.id)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import bookPreview from "@/components/bookPreview.vue";

export default {
	name: "TagDetails",
	components: { bookPreview },
	data() {
		return {
			id: this.$route.params.id,
			loading: true,
			error: null,
			tag: null,
		};
	},

	methods: {
		getTag() {
			this.loading = true;

			api("tags/" + this.id, "GET")
				.then(data => {
					this.tag = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		bookDetails(id) {
			this.$router.push({ name: "BookDetails", params: { id: id } });
		},
	},

	watch: {
		id() {
			this.getTag();
		},
	},

	created() {
		this.getTag();
	},
};
</script>

<style scoped></style>

<template>
	<div class="book-details">
		<error-message :error="error" />
		<b-form v-on:submit.prevent="save">
			<b-form-input
				class="mb-2"
				v-model="book.title"
				:placeholder="$t('author.edit.details.bookTitle')"
			/>
			<b-form-select
				class="form-select mb-2"
				v-model="book.language"
				:options="languageOptions"
			/>
			<b-form-group
				class="mb-2"
				:description="$t('author.edit.details.coverpath.1')"
			>
				<b-form-input
					type="text"
					v-model="book.coverPath"
					:placeholder="$t('author.edit.details.coverpath.0')"
				/>
			</b-form-group>
			<wysiwyg-editor
				v-model="book.description"
				class="mb-2"
				:placeholder="$t('author.edit.details.description.placeholder')"
			/>
			<div class="mb-2">
				<vue-select
					:disabled="loadingGenres"
					:options="genres"
					:getOptionKey="option => option.name"
					label="name"
					taggable
					v-model="book.genre"
					v-on:option:selecting="saveNewGenre"
					placeholder="Genre"
				/>
			</div>
			<div class="mb-2">
				<vue-select
					:disabled="loadingTags"
					multiple
					:options="tags"
					:getOptionKey="option => option.name"
					label="name"
					taggable
					v-model="book.tags"
					v-on:option:selecting="saveNewTag"
					placeholder="Tags"
				/>
			</div>
			<div class="form-check form-switch mb-2">
				<label for="continuous">{{
					$t("author.edit.details.continuous")
				}}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="book.continuous"
					id="continuous"
				/>
			</div>
			<div class="form-check form-switch mb-2">
				<label for="public">{{ $t("author.edit.details.public") }}</label>
				<input
					type="checkbox"
					class="form-check-input"
					v-model="book.public"
					id="public"
				/>
			</div>
			<b-button :disabled="loading" variant="primary" type="submit"
				>{{ $t("author.edit.details.save") }}
			</b-button>
			<b-button
				variant="outline-primary"
				class="ms-2"
				:disabled="loading || !book.public"
				@click="toBook"
				>{{ $t("author.edit.details.toBook") }}
			</b-button>
		</b-form>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";
import ErrorMessage from "@/components/errorMessage.vue";
import { Language } from "@/enums/language.enum";

export default {
	name: "BookDetails",
	components: { ErrorMessage, WysiwygEditor },
	props: ["bookProp"],
	data() {
		return {
			Language,
			id: this.$route.params.id,
			error: null,
			loading: false,
			initialLoad: true,
			loadingTags: true,
			loadingGenres: true,
			tags: [],
			genres: [],
			book: this.$props["bookProp"],
			languageOptions: [
				{ value: Language.GERMAN, text: "Deutsch" },
				{ value: Language.ENGLISH, text: "English" },
				{ value: Language.FRENCH, text: "Français" },
				{ value: Language.JAPANESE, text: "日本語" },
			],
		};
	},

	methods: {
		save() {
			if (this.loadingTags) return;
			if (this.loadingGenres) return;
			this.loading = true;

			const body = {
				title: this.book.title,
				language: this.book.language,
				coverPath: this.book.coverPath,
				description: this.book.description,
				continuous: this.book.continuous,
				public: this.book.public,
				tags: this.book.tags.map(t => ({ id: t.id })),
				genre:
					this.book.genre && this.book.genre.id
						? { id: this.book.genre.id }
						: null,
			};

			api("author/books/" + this.id, "PATCH", true, body)
				.then(data => {
					this.book = data;
					this.loading = false;
				})
				.catch(err => {
					this.error = err;
					this.loading = false;
					this.$error(err);
				});
		},

		toBook() {
			this.$router.push({ name: "BookDetails", params: { id: this.id } });
		},

		getTags() {
			this.loadingTags = true;
			api("tags", "GET")
				.then(data => {
					this.tags = data;
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},

		getGenres() {
			this.loadingGenres = true;
			api("genres", "GET")
				.then(data => {
					this.genres = data;
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewGenre(genre) {
			this.$log(genre);
			if (genre.id) return;

			const genreIndex = this.genres.findIndex(t => t.name === genre.name);
			if (genreIndex > -1) {
				this.$set(this.book, "genre", this.genres[genreIndex]);
				return this.$log(this.book.genre);
			}

			this.loadingGenres = true;
			api("genres", "POST", true, {
				name: typeof genre === "object" && genre.name ? genre.name : genre,
			})
				.then(data => {
					this.$set(this.book, "genre", data);
					this.genres.push(data);
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewTag(tag) {
			if (tag.id) return;

			const tagIndex = this.tags.findIndex(t => t.name === tag.name);
			if (tagIndex > -1) {
				this.$set(
					this.book.tags,
					this.book.tags.findIndex(t => t.name === tag.name),
					this.tags[tagIndex]
				);
				return this.$log(this.book.tags);
			}

			this.$log("posting");
			this.$log(tag);

			this.loadingTags = true;
			api("tags", "POST", true, {
				name: typeof tag === "object" && tag.name ? tag.name : tag,
			})
				.then(data => {
					this.$set(
						this.book.tags,
						this.book.tags.findIndex(t => t.name === tag.name),
						data
					);
					this.tags.push(data);
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},
	},

	created() {
		this.getTags();
		this.getGenres();
	},
};
</script>

<style scoped></style>

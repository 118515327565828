<template>
	<div
		class="book d-flex justify-content-center align-items-center flex-column flex-sm-row gap-1"
	>
		<div class="d-flex align-items-center w-100 flex-grow-1">
			<book-cover :src="book.coverPath" class="me-3 shadow cover" />
			<div class="flex-grow-1">
				<p class="m-0">
					{{ book.title }}
					<country-flag :country="book.language" class="d-inline-block" />
				</p>
				<p
					class="m-0 text-muted cursor-pointer"
					v-if="book.author"
					@click="toAuthor"
				>
					{{ book.author.name }}
					<span class="fst-italic"
						>@{{ book.author.username.toLowerCase() }}</span
					>
				</p>
			</div>
		</div>
		<div class="w-100 w-sm-auto">
			<b-button
				variant="primary"
				class="mb-1 d-block w-100 w-sm-auto"
				@click="$emit('firstButton')"
			>
				<b-icon icon="pencil-square" />&nbsp;
				{{ $t("admin.overview.books.card.0") }}
			</b-button>
			<b-button
				variant="outline-danger"
				class="d-block w-100 w-sm-auto"
				@click="$emit('secondButton')"
			>
				<b-icon icon="trash" />&nbsp;
				{{ $t("admin.overview.books.card.1") }}
			</b-button>
		</div>
	</div>
</template>

<script>
import CountryFlag from "@/components/countryFlag";
import BookCover from "@/components/bookCover.vue";

export default {
	name: "listBook",
	components: {
		BookCover,
		CountryFlag,
	},

	props: {
		book: {
			type: Object,
			required: true,
			default: () => {},
		},
	},

	methods: {
		toAuthor() {
			this.$router.push({
				name: "UserProfile",
				params: { id: `@${this.book.author.username}` },
			});
		},
	},
};
</script>

<style scoped lang="scss">
.book .cover {
	height: 100px;
}

@media (min-width: 576px) {
	.w-sm-auto {
		width: max-content !important;
	}
}
</style>

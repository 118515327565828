<template>
	<div class="home">
		<div
			class="d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 bottom-0 end-0"
			style="background: rgba(82, 82, 82, 0.75)"
			v-if="this.loading"
		>
			<b-spinner style="width: 3rem; height: 3rem"></b-spinner>
			<p class="mt-3">{{ $t("home.loading") }}...</p>
		</div>
		<b-container class="mt-3" v-else>
			<div class="book-hero mb-4" v-if="front.hero && front.hero.id">
				<b-row class="px-4 gap-4">
					<b-col cols="12" sm="3">
						<book-cover :src="front.hero.coverPath" />
					</b-col>
					<b-col cols="12" sm="8" class="d-flex align-items-center">
						<div>
							<h2
								class="mb-2 d-flex flex-column flex-sm-row align-items-center gap-3"
							>
								{{ front.hero.title }}
								<span class="continuous-badge" v-if="front.hero.continuous">
									{{ $t("books.details.continuous.0") }}
								</span>
								<span class="completed-badge" v-else>
									{{ $t("books.details.continuous.1") }}
								</span>
							</h2>
							<markdown-output
								:markdown="front.hero.description"
								class="mb-4"
							/>
							<div
								class="hero-author d-flex align-items-center mb-4 cursor-pointer"
								@click="toAuthor(front.hero.author.username)"
							>
								<profile-picture
									class="profile-picture me-3"
									:name="front.hero.author.name"
									:profile-picture="front.hero.author.profilePicture"
								/>
								<p class="mb-0">
									{{ front.hero.author.name }}&nbsp;
									<span class="text-muted fst-italic"
										>@{{ front.hero.author.username }}</span
									>
								</p>
							</div>
							<b-button
								variant="outline-primary"
								@click="bookDetails(front.hero.id)"
								>{{ $t("home.hero.toBook") }}</b-button
							>
						</div>
					</b-col>
				</b-row>
			</div>

			<div v-if="front.featured.length > 0" class="featured">
				<h1>{{ $t("home.featuredBooks") }}</h1>
				<div
					class="d-flex px-4 gap-3 flex-wrap justify-content-center justify-content-md-start"
				>
					<book-preview
						v-for="book in front.featured"
						:key="book.id"
						:book="book"
						v-on:click="bookDetails(book.id)"
					/>
				</div>
			</div>
			<div v-if="front.books.length > 0" class="newest">
				<h1>{{ $t("home.newestBooks") }}</h1>
				<div
					class="d-flex px-4 gap-3 flex-wrap justify-content-center justify-content-md-start"
				>
					<book-preview
						v-for="book in front.books"
						:key="book.id"
						:book="book"
						v-on:click="bookDetails(book.id)"
					/>
				</div>
			</div>
		</b-container>
	</div>
</template>

<script>
import bookPreview from "@/components/bookPreview";
import router from "@/router";
import { api } from "@/lib/api";
import BookCover from "@/components/bookCover.vue";
import MarkdownOutput from "@/components/wysiwyg/markdownOutput.vue";
import ProfilePicture from "@/components/ProfilePicture.vue";

export default {
	name: "Home",
	components: {
		ProfilePicture,
		MarkdownOutput,
		BookCover,
		bookPreview,
	},

	data() {
		return {
			front: [],
			loading: true,
		};
	},

	methods: {
		getFront: function () {
			api("front/", "GET").then(async data => {
				this.front = data;
				this.loading = false;
			});
		},

		bookDetails: function (id) {
			router.push({ name: "BookDetails", params: { id } });
		},

		toAuthor(username) {
			this.$router.push({
				name: "UserProfile",
				params: { id: `@${username}` },
			});
		},
	},

	async created() {
		this.getFront();
	},
};
</script>

<style scoped lang="scss">
.profile-picture {
	height: 60px;
	border: 3px #eaeaea solid;
}

.continuous-badge,
.completed-badge {
	border-radius: 99999999999px;
	padding: 5px;
	width: fit-content;
	height: fit-content;
	font-size: 14px;
}

.continuous-badge {
	$color: #bd5400;
	border: 1px solid $color;
	color: $color;
}

.completed-badge {
	$color: #009abd;
	border: 1px solid $color;
	color: $color;
}

.hero-author {
	width: fit-content;
}
</style>

<template>
	<footer class="bg-light mt-5">
		<b-row class="mx-auto p-3 w-75">
			<b-col>
				<h6>Help</h6>
				<nav>
					<ul>
						<li>
							<b-link :to="{ name: 'FeedbackForm' }">Feedback</b-link>
						</li>
						<li>
							<b-link :to="{ name: 'MyFeedback' }">My feedback</b-link>
						</li>
						<li>
							<b-link :to="{ name: 'Faq' }">FAQ</b-link>
						</li>
					</ul>
				</nav>
			</b-col>
			<b-col v-if="$store.getters.userId">
				<h6>Account</h6>
				<nav>
					<ul>
						<li>
							<b-link :to="{ name: 'SecurityCenter' }">Security Center</b-link>
						</li>
						<li>
							<b-link
								:to="{
									name: 'UserProfile',
									params: { id: $store.getters.userId },
								}"
							>
								My Profile
							</b-link>
						</li>
						<li>
							<b-link :to="{ name: 'EditProfile' }">Edit Profile</b-link>
						</li>
					</ul>
				</nav>
			</b-col>
		</b-row>
		<div class="text-center text-muted">
			<p class="mb-0">
				&copy; {{ new Date().getFullYear() }} David - Version
				{{ $store.state.currentVersion }} - Server
				{{ $store.getters.serverVersion }}
			</p>
			<p class="mb-0"></p>
		</div>
	</footer>
</template>

<script>
export default {
	name: "booksFooter",
};
</script>

<style scoped lang="scss">
nav ul,
nav ul li {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}
</style>

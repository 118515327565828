<template>
	<editor
		:options="editorOptions"
		:initialValue="value"
		initialEditType="wysiwyg"
		@change="handleInput"
		ref="wysiwyg-editor"
	/>
</template>

<script>
import { Editor } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/i18n/de-de";
import TurndownService from "turndown";
import { marked } from "marked";
import hljs from "highlight.js";
import DOMPurify from "dompurify";

export default {
	name: "wysiwygEditor",
	components: { Editor },
	props: {
		value: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			initialValue: DOMPurify.sanitize(
				marked.parse(this.value, this.markedOptions),
				this.purifyOptions
			),
			markedOptions: {
				highlight: (code, lang, cb) => {
					cb(null, hljs.highlight(code, { language: lang }).value);
				},
			},
			turndown: new TurndownService({
				emDelimiter: "_",
				linkStyle: "inlined",
				headingStyle: "atx",
			}),
			purifyOptions: {
				USE_PROFILES: {
					svg: true,
					svgFilters: true,
					mathMl: true,
					html: false,
				},
				FORBID_TAGS: ["script"],
			},
			editorOptions: {
				language: this.$store.state.user.language || "de",
				useCommandShortcut: true,
				usageStatistics: false,
				hideModeSwitch: true,
				toolbarItems: [
					["heading", "bold", "italic"],
					["hr", "quote"],
					["ul", "ol", "indent", "outdent"],
					["link"],
					["scrollSync"],
				],
			},
		};
	},

	methods: {
		handleInput() {
			const html = this.$refs["wysiwyg-editor"].invoke("getHTML");
			this.$emit("input", this.turndown.turndown(html));
		},
	},
};
</script>

<style scoped></style>

<template>
	<div id="app">
		<navbar />
		<router-view class="min-vh-100" />
		<books-footer />
	</div>
</template>

<script>
import navbar from "@/components/navbar/navbar.vue";
import BooksFooter from "@/components/booksFooter.vue";

export default {
	name: "App",
	components: {
		BooksFooter,
		navbar,
	},
	created() {
		this.$store.dispatch("updateSettings");
	},
	metaInfo: {
		title: "Dealcraft Books",
	},
};
</script>

<style lang="scss">
.cursor-pointer {
	cursor: pointer;
}

.right-0 {
	right: 0;
}
</style>

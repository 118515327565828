<template>
	<div class="genres container" v-if="!loading">
		<list-genre
			v-for="genre of this.genres"
			:key="genre.id"
			:genre="genre"
			@click="navigate(genre.id)"
			class="mt-3 cursor-pointer"
		/>
	</div>
</template>

<script>
import { api } from "@/lib/api";
import ListGenre from "@/components/listGenre.vue";

export default {
	name: "GenreList",
	components: { ListGenre },
	data() {
		return {
			loading: true,
			error: null,
			genres: [],
		};
	},

	methods: {
		getGenres() {
			this.loading = true;

			api("genres", "GET")
				.then(data => {
					this.genres = data;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		navigate(id) {
			this.$router.push({ name: "GenreDetails", params: { id: id } });
		},
	},

	created() {
		this.getGenres();
	},
};
</script>

<style scoped></style>

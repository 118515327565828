<template>
	<Card v-if="!initialLoading">
		<error-message :error="error" />
		<h1 class="mb-4">{{ title }}</h1>
		<div>
			<b-form v-on:submit.prevent="saveBook">
				<div class="input-group mb-2">
					<b-form-input
						type="text"
						v-model="book.id"
						:placeholder="$t('admin.books.details.id')"
						disabled
					/>
					<b-button type="button" variant="outline-secondary" @click="copyId"
						>{{ $t("admin.books.details.copyId") }}
					</b-button>
				</div>
				<b-form-input
					class="mb-2"
					type="text"
					v-model="book.title"
					:placeholder="$t('admin.books.details.title')"
					:disabled="loading"
				/>
				<b-form-input
					class="mb-2"
					type="text"
					v-model="book.coverPath"
					:placeholder="$t('admin.books.details.coverPath')"
					:disabled="loading"
				/>
				<vue-select
					class="mb-2"
					:options="languageOptions"
					v-model="bookLanguage"
					:disabled="loading"
				/>

				<wysiwyg-editor v-model="book.description" class="mb-4" />

				<div class="mb-2">
					<vue-select
						:disabled="loadingGenres"
						:options="genres"
						:getOptionKey="option => option.name"
						label="name"
						taggable
						v-model="book.genre"
						v-on:option:selecting="saveNewGenre"
						placeholder="Genre"
					/>
				</div>

				<div class="mb-4">
					<vue-select
						:disabled="loadingTags"
						multiple
						:options="tags"
						:getOptionKey="option => option.name"
						label="name"
						taggable
						v-model="book.tags"
						v-on:option:selecting="saveNewTag"
						placeholder="Tags"
					/>
				</div>

				<div class="form-check mb-2">
					<input
						class="form-check-input"
						type="checkbox"
						id="featured"
						v-model="book.featured"
						:disabled="loading"
					/>
					<label class="form-check-label" for="featured">
						{{ $t("admin.books.details.featured") }}
					</label>
				</div>
				<div class="form-check mb-2">
					<input
						class="form-check-input"
						type="checkbox"
						id="continuous"
						v-model="book.continuous"
						:disabled="loading"
					/>
					<label class="form-check-label" for="continuous">
						{{ $t("admin.books.details.continuous") }}
					</label>
				</div>
				<div class="form-check mb-4">
					<input
						class="form-check-input"
						type="checkbox"
						id="public"
						v-model="book.public"
						:disabled="loading"
					/>
					<label class="form-check-label" for="public">
						{{ $t("admin.books.details.public") }}
					</label>
				</div>
				<b-button
					type="submit"
					variant="primary"
					:disabled="loading || loadingTags"
				>
					{{ $t("admin.books.details.save") }}
				</b-button>
			</b-form>
		</div>

		<hr />

		<div>
			<h2>{{ $t("admin.books.details.author") }}</h2>
			<div class="user">
				<div class="d-flex">
					<profile-picture
						class="profile-picture me-3"
						:name="book.author.name"
						:profile-picture="book.author.profilePicture"
					/>
					<div>
						<p class="m-0">{{ book.author.name }}</p>
						<p class="m-0 text-muted fst-italic">
							@{{ book.author.username.toLowerCase() }}
						</p>
					</div>
				</div>
				<div>
					<b-button
						variant="primary"
						class="mb-1 d-block w-100"
						@click="toAuthor()"
					>
						<b-icon icon="pencil-square" />
						{{ $t("listUser.details") }}
					</b-button>
				</div>
			</div>
		</div>

		<hr v-if="this.book.chapters.length > 0" />

		<div class="chapters" v-if="this.book.chapters.length > 0">
			<h2>{{ $t("admin.books.details.chapters.title") }}</h2>
			<div
				class="chapter mb-3 d-flex gap-3 border rounded p-3 bg-white justify-content-between flex-column flex-sm-row"
				v-for="(chapter, i) of book.chapters"
				:key="chapter.id"
			>
				<div>
					<h5>
						{{
							$t("admin.books.details.chapters.chapterPrepend", {
								no: i + 1,
							})
						}}:&nbsp;
						{{ chapter.name }}
					</h5>
					<p v-if="chapter.summary" class="mt-3 mb-0">{{ chapter.summary }}</p>
				</div>
				<div class="d-flex flex-column gap-3 flex-shrink-0">
					<b-button
						variant="outline-danger"
						@click="deleteChapter(chapter.id)"
						:disabled="loading"
					>
						<b-icon icon="trash" />&nbsp;
						{{ $t("admin.books.details.chapters.delete") }}
					</b-button>
				</div>
			</div>
		</div>
	</Card>
</template>

<script>
import { api } from "@/lib/api";
import Card from "@/components/card.vue";
import ErrorMessage from "@/components/errorMessage.vue";
import WysiwygEditor from "@/components/wysiwyg/wysiwygEditor.vue";
import VueSelect from "vue-select";
import { Language } from "@/enums/language.enum";
import ProfilePicture from "@/components/ProfilePicture.vue";

export default {
	name: "AdminBookDetail",
	components: {
		ProfilePicture,
		VueSelect,
		WysiwygEditor,
		ErrorMessage,
		Card,
	},
	data() {
		return {
			Language: Language,
			id: this.$route.params.id,
			initialLoading: true,
			loading: false,
			loadingTags: true,
			loadingGenres: true,
			genres: [],
			tags: [],
			error: null,
			title: "",
			book: {},
			bookLanguage: null,
			languageOptions: [
				{ label: "Deutsch", value: Language.GERMAN },
				{ label: "English", value: Language.ENGLISH },
				{ label: "Français", value: Language.FRENCH },
				{ label: "日本語", value: Language.JAPANESE },
			],
		};
	},

	methods: {
		getBook() {
			api("admin/books/" + this.id)
				.then(data => {
					this.book = data;
					this.title = data.title;
					this.bookLanguage = this.languageOptions.filter(
						option => data.language === option.value
					)[0];
				})
				.catch(err => {
					this.error = err;
					this.scrollToTop();
				})
				.finally(() => {
					this.initialLoading = false;
				});
		},

		saveBook() {
			if (this.loadingTags) return;
			if (this.loadingGenres) return;
			this.loading = true;
			this.error = null;

			const body = {
				...this.book,
				tags: this.book.tags.map(t => ({ id: t.id })),
				genre:
					this.book.genre && this.book.genre.id
						? { id: this.book.genre.id }
						: null,
			};

			delete body.chapters;
			delete body.author;
			delete body.id;

			api("admin/books/" + this.id, "PATCH", true, body)
				.then(data => {
					this.book = data;
					this.title = data.title;
					this.bookLanguage = this.languageOptions.filter(
						option => data.language === option.value
					)[0];
				})
				.catch(err => {
					this.error = err;
					this.scrollToTop();
				})
				.finally(() => {
					this.loading = false;
				});
		},

		deleteChapter(id) {
			api(`admin/books/${this.id}/chapters/${id}`, "DELETE", true)
				.then(data => {
					this.book.chapters = data;
				})
				.catch(err => {
					console.error(err);
				});
		},

		copyId() {
			window.navigator.clipboard.writeText(this.book.id).catch(err => {
				console.log("Error writing to clipboard:", err);
			});
		},

		scrollToTop() {
			window.scroll(window.scrollX, 0);
		},

		toAuthor() {
			this.$router
				.push({
					name: "AdminUsersDetail",
					params: { id: this.book.author.id },
				})
				.catch(() => {});
		},

		getTags() {
			this.loadingTags = true;
			api("tags", "GET")
				.then(data => {
					this.tags = data;
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},

		getGenres() {
			this.loadingGenres = true;
			api("genres", "GET")
				.then(data => {
					this.genres = data;
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewGenre(genre) {
			this.$log(genre);
			if (genre.id) return;

			const genreIndex = this.genres.findIndex(t => t.name === genre.name);
			if (genreIndex > -1) {
				this.$set(this.book, "genre", this.genres[genreIndex]);
				return this.$log(this.book.genre);
			}

			this.loadingGenres = true;
			api("genres", "POST", true, {
				name: typeof genre === "object" && genre.name ? genre.name : genre,
			})
				.then(data => {
					this.$set(this.book, "genre", data);
					this.genres.push(data);
				})
				.finally(() => {
					this.loadingGenres = false;
				});
		},

		saveNewTag(tag) {
			if (tag.id) return;

			const tagIndex = this.tags.findIndex(t => t.name === tag.name);
			if (tagIndex > -1) {
				this.$set(
					this.book.tags,
					this.book.tags.findIndex(t => t.name === tag.name),
					this.tags[tagIndex]
				);
				return this.$log(this.book.tags);
			}

			this.$log("posting");
			this.$log(tag);

			this.loadingTags = true;
			api("tags", "POST", true, {
				name: typeof tag === "object" && tag.name ? tag.name : tag,
			})
				.then(data => {
					this.$set(
						this.book.tags,
						this.book.tags.findIndex(t => t.name === tag.name),
						data
					);
					this.tags.push(data);
				})
				.finally(() => {
					this.loadingTags = false;
				});
		},
	},

	watch: {
		bookLanguage() {
			this.book.language = this.bookLanguage.value;
		},
	},

	created() {
		this.$watch(
			() => this.$route.params,
			toParams => {
				this.id = toParams.id;
				this.getBook();
			}
		);
		this.getBook();
		this.getTags();
		this.getGenres();
	},
};
</script>

<style scoped>
.profile-picture {
	height: 60px;
	border: 3px #eaeaea solid;
}

.user {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>

<template>
	<div class="book-chapters">
		<div class="d-flex justify-content-end mb-3">
			<b-button variant="outline-secondary" size="sm" @click="newChapter()">
				<b-icon icon="plus-circle" />
				{{ $t("author.edit.chapter.newChapter") }}
			</b-button>
		</div>
		<div class="chapters" v-if="value.length > 0">
			<div
				class="chapter mb-3 d-flex gap-3 border rounded p-3 bg-white justify-content-between flex-column flex-sm-row"
				v-for="(chapter, i) of value"
				:key="chapter.id"
			>
				<div>
					<h5>
						{{
							$t("author.edit.chapter.chapterPrepend", { chapterNo: i + 1 })
						}}:
						{{ chapter.name }}
					</h5>
					<p v-if="chapter.summary" class="mt-3 mb-0">{{ chapter.summary }}</p>
				</div>
				<div class="d-flex flex-column gap-3 flex-shrink-0">
					<b-button
						variant="primary"
						@click="editChapter(chapter.id)"
						:disabled="loading"
					>
						<b-icon icon="pencil-square" />&nbsp;
						{{ $t("author.edit.chapter.edit") }}
					</b-button>
					<b-button
						variant="outline-danger"
						@click="deleteChapter(chapter.id)"
						:disabled="loading"
					>
						<b-icon icon="trash" />&nbsp;
						{{ $t("author.edit.chapter.delete") }}
					</b-button>
				</div>
			</div>
		</div>
		<div v-else>
			<p class="text-muted fst-italic text-center">
				{{ $t("author.edit.chapter.noChapters") }}
			</p>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";

export default {
	name: "BookChapters",
	props: ["value"],
	data() {
		return {
			loading: false,
		};
	},

	methods: {
		editChapter(id) {
			this.$router.push({
				name: "EditChapter",
				params: { bookId: this.$route.params.id, chapterId: id },
			});
		},

		deleteChapter(id) {
			api(
				`author/books/${this.$route.params.id}/chapters/${id}`,
				"DELETE",
				true
			)
				.then(data => {
					this.$emit("input", data);
				})
				.catch(this.$error);
		},

		newChapter() {
			this.$router.push({ name: "NewChapter" });
		},
	},
};
</script>

<style scoped></style>

<template>
	<div class="signup">
		<b-container class="signup-wrapper p-3 my-3 rounded border">
			<h1 class="mb-3">{{ $t("signup.title") }}</h1>
			<error-message :error="error" />
			<b-form v-on:submit.prevent="signup" v-if="step === 1">
				<b-row>
					<b-col cols="12" sm="6" class="mb-3">
						<b-input v-model="name" :placeholder="$t('signup.name')" />
					</b-col>
					<b-col cols="12" sm="6" class="mb-3">
						<b-input v-model="username" :placeholder="$t('signup.username')" />
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" sm="6" class="mb-3">
						<b-input
							v-model="email"
							type="email"
							:placeholder="$t('signup.email')"
						/>
					</b-col>
					<b-col cols="12" sm="6" class="mb-3">
						<b-input
							v-model="emailCheck"
							type="email"
							:placeholder="$t('signup.repeatEmail')"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" sm="6" class="mb-3">
						<strong-password
							v-model="password"
							:placeholder="$t('signup.password')"
						/>
					</b-col>
					<b-col cols="12" sm="6" class="mb-3">
						<strong-password
							v-model="passwordCheck"
							:placeholder="$t('signup.repeatPassword')"
						/>
					</b-col>
				</b-row>
				<vue-select
					:options="languageOptions"
					v-model="language"
					:disabled="loading"
					class="mb-3"
				/>
				<b-button
					type="submit"
					class="w-100 shadow"
					variant="primary"
					:disabled="loading || buttonDisabled"
				>
					{{ $t("signup.button") }}
				</b-button>
			</b-form>
			<div v-else-if="step === 2">
				<p>
					{{ $t("signup.signedUp") }}
				</p>
			</div>
			<hr v-if="step === 1" />
			<p class="text-center" v-if="step === 1">
				{{ $t("signup.login.text") }}
				<b-link @click="login">{{ $t("signup.login.link") }}</b-link>
			</p>
		</b-container>
	</div>
</template>

<script>
import { Language } from "@/enums/language.enum";
import { api } from "@/lib/api";
import ErrorMessage from "@/components/errorMessage.vue";
import VueSelect from "vue-select";
import StrongPassword from "@/components/strongPassword.vue";

export default {
	name: "Signup",
	components: { StrongPassword, ErrorMessage, VueSelect },
	data() {
		return {
			loading: false,
			error: null,
			step: 1,

			name: null,
			username: null,
			email: null,
			emailCheck: null,
			password: null,
			passwordCheck: null,
			language: { label: "Deutsch", value: Language.GERMAN },

			returnPath: this.$route.query.to,

			languageOptions: [
				{ label: "Deutsch", value: Language.GERMAN },
				{ label: "English", value: Language.ENGLISH },
				{ label: "Français", value: Language.FRENCH },
				{ label: "日本語", value: Language.JAPANESE },
			],
		};
	},

	methods: {
		signup() {
			this.loading = true;
			this.error = null;

			const body = {
				name: this.name,
				username: this.username,
				email: this.email,
				password: this.password,
				passwordCheck: this.passwordCheck,
				language: this.language.value,
			};

			api("auth/signup", "POST", false, body)
				.then(data => {
					if (data.signedUp) this.step = 2;
				})
				.catch(err => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		login() {
			this.$router.push({ name: "Login" });
		},
	},

	computed: {
		buttonDisabled() {
			return (
				this.email !== this.emailCheck || this.password !== this.passwordCheck
			);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/styles";

.signup {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - $header-height);
	width: 100%;
}
</style>

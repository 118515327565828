<template>
	<div class="reactivate d-flex justify-content-center align-items-center">
		<div
			v-if="reactivating"
			class="d-flex flex-column justify-content-center align-items-center"
		>
			<b-spinner class="mb-3" />
			<p class="position-relative" v-html="$t('reactivate.reactivating')"></p>
		</div>

		<div
			v-else-if="!error"
			class="d-flex justify-content-center align-items-center flex-column"
		>
			<sweetalert-icon icon="success" />
			<p class="mb-1">{{ $t("reactivate.success.text") }}</p>
			<b-button variant="primary" @click="login"
				>{{ $t("reactivate.success.button") }}
			</b-button>
		</div>

		<div
			v-if="error"
			class="d-flex justify-content-center align-items-center flex-column"
		>
			<sweetalert-icon icon="error" />
			<p>{{ error }}</p>
			<b-button variant="primary" @click="contact"
				>{{ $t("reactivate.error.contact") }}
			</b-button>
		</div>
	</div>
</template>

<script>
import { api } from "@/lib/api";

export default {
	name: "Reactivate",
	data() {
		return {
			reactivationToken: this.$route.params.token,
			reactivating: true,
			error: null,
			errorMap: {
				"reactivation not found": this.$t("reactivate.error.rnf"),
				"reactivation expired": this.$t("reactivate.error.re"),
				"user not found": this.$t("reactivate.error.unf"),
			},
		};
	},

	methods: {
		login: function () {
			this.$router.push({ name: "Login" });
		},

		reactivate: function () {
			api("auth/reactivate/" + this.reactivationToken, "POST")
				.then(data => {
					if (data.reactivated) return (this.reactivating = false);
					this.error = this.$t("reactivate.error.unknown");
				})
				.catch(err => {
					this.reactivating = false;
					this.error = this.errorMap[err.message];
				});
		},

		contact: function () {
			this.$log("Nav to contact page");
		},
	},

	created() {
		this.reactivate();
	},
};
</script>

<style scoped lang="scss">
$animation-length: 1.5s;

.reactivate {
	min-height: calc(100vh - 56px);
}

.button-jump {
	position: relative;
	animation: jump $animation-length ease-in-out infinite;

	&:nth-child(1) {
		animation-delay: ($animation-length / 4) * 1;
	}

	&:nth-child(2) {
		animation-delay: ($animation-length / 4) * 2;
	}

	&:nth-child(3) {
		animation-delay: ($animation-length / 4) * 3;
	}
}

@keyframes jump {
	0%,
	50%,
	100% {
		bottom: 0;
	}
	25% {
		bottom: 5px;
	}
}
</style>

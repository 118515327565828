export const FeedbackType = {
	BUG: "BUG",
	SECURITY: "SECURITY",
	IMPROVEMENT: "IMPROVEMENT",
};

export const FeedbackPriority = {
	LOWEST: "LOWEST",
	LOW: "LOW",
	MID: "MID",
	HIGH: "HIGH",
	HIGHEST: "HIGHEST",
	CRITICAL: "CRITICAL",
};

export const FeedbackStatus = {
	RECEIVED: "RECEIVED",
	REVIEWED: "REVIEWED",
	PENDING: "PENDING",
	IN_WORK: "IN_WORK",
	RESOLVED: "RESOLVED",
	REJECTED: "REJECTED",
};
